import { createSlice } from '@reduxjs/toolkit';
import { TMachineType } from '../../types';
import { ApiError } from '../../services/apiClient/apiError';

/*-----------------TYPES-----------------*/
type TPayloadError = {
  error: ApiError;
};

type TFetchDictionaries = {
  machineTypes: TMachineType[];
  internetStatuses: object[];
  applicationCategories: object[];
};

export interface IDictionaries {
  machineTypes: TMachineType[];
  internetStatuses: object[];
  applicationCategories: object[];
  countryList: { name: string }[];
  error: ApiError | null;
}

/*-----------------INITIAL STATE-----------------*/
export const initialState: IDictionaries = {
  machineTypes: [],
  internetStatuses: [],
  applicationCategories: [],
  countryList: [],
  error: null,
};

/*-----------------SLICE-----------------*/
const dictionariesSlice = createSlice({
  name: 'gateways',
  initialState,
  reducers: {
    /*-----------------Pending Actions/Reducers-----------------*/
    fetchDictionariesPending: (state) => state,
    fetchCountryListPending: (state) => state,

    /*-----------------Success Actions/Reducers-----------------*/
    fetchDictionariesSuccess: (state, { payload }: { payload: TFetchDictionaries }) => {
      return {
        ...state,
        machineTypes: payload.machineTypes,
        internetStatuses: payload.internetStatuses,
        applicationCategories: payload.applicationCategories,
      };
    },
    fetchCountryListSuccess: (state, { payload }: { payload: IDictionaries['countryList'] }) => {
      return {
        ...state,
        countryList: payload,
      };
    },
    resetDictionariesReducer: (_state) => {
      return {
        ...initialState,
      };
    },

    /*-----------------Failed Actions/Reducers-----------------*/
    fetchFailed: (state, { payload }: { payload: TPayloadError }) => {
      return {
        ...state,
        error: payload.error,
      };
    },
  },
});

export const {
  fetchDictionariesPending,
  fetchCountryListPending,
  fetchDictionariesSuccess,
  fetchCountryListSuccess,
  resetDictionariesReducer,
  fetchFailed,
} = dictionariesSlice.actions;

export default dictionariesSlice.reducer;
