import { combineReducers } from 'redux';

import userReducer from './slices/user';
import companiesReducer from './slices/companies';
import gatewaysReducer from './slices/gateways';
import dictionariesReducer from './slices/dictionaries';

export default combineReducers({
  user: userReducer,
  companies: companiesReducer,
  gateways: gatewaysReducer,
  dictionaries: dictionariesReducer,
});
