export enum SORT_ORDER {
  ASCENDING = 'asc',
  DESCENDING = 'desc',
}

export enum FILTER_OPERATOR {
  EQUALS = 'eq',
  NOT_EQUALS = 'ne',
  GREATER_THEN = 'gt',
  LESS_THEN = 'lt',
  GREATER_OR_EQUAL = 'ge',
  LESS_OR_EQUAL = 'le',
}

export type ArrayElement<T> = T extends (infer U)[] ? U : never;

/*-----------------User Types-----------------*/
export type TApiUser = {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  displayName: string;
  phone?: string;
};

export type TAuthUser = {
  tenantId: string;
  organizationId: string;
  organizationPermissions: string[];
  localAccountId: string;
  name: string;
  email: string;
};

export type TTenant = {
  id: string;
  name: string;
  isSystemOrg: boolean;
};

/*-----------------Company Types-----------------*/
export enum COMPANIES_SORT_KEYS {
  COMPANY_MANE = 'CompanyName',
  COUNTRY = 'Country',
  COMPANY_ADMIN = 'CompanyAdmin',
}

export type TCompany = {
  organizationId: string;
  name: string;
  companyAdminId: string;
  companyAdminIdFirstName: string;
  companyAdminIdLastName: string;
  city: string;
  country: string;
  customerContactFirstName: string;
  customerContactLastName: string;
};

export type TCompaniesSortKey =
  | COMPANIES_SORT_KEYS.COMPANY_ADMIN
  | COMPANIES_SORT_KEYS.COMPANY_MANE
  | COMPANIES_SORT_KEYS.COUNTRY;

export type TCompaniesSortOrder = SORT_ORDER.ASCENDING | SORT_ORDER.DESCENDING;

export type TCompanyDetails = {
  companyId: string;
  name: string;
  city: string;
  country: string;
  email?: string | null;
  address1: string;
  address2?: string | null;
  postalCode: string;
  companyAdminId: string;
  customerContactFirstName?: string | null;
  customerContactLastName?: string | null;
  customerContactEmail?: string | null;
  customerContactPhone?: string | null;
};

export type TInstallationArea = {
  installationAreaId: string;
  name: string;
  gateways?: number;
};

/*-----------------Gateway Types-----------------*/
export enum GATEWAYS_SORT_KEYS {
  COMPANY_MANE = 'CompanyName',
  SERIAL_NO = 'SerialNumber',
  INTERNET_STATUS = 'InternetStatus',
  CUSTOMER_CONTACT = 'CustomerContact',
  MACHINE_STATUS = 'MachineStatus',
  INSTALLATION_AREA = 'InstallationArea',
  APPLICATION_CATEGORY = 'ApplicationCategory',
  DATE_CREATED = 'DateCreated',
}

export enum GATEWAYS_FILTER_KEYS {
  COMPANY_MANE = 'CompanyName',
  INTERNET_STATUS = 'InternetStatus',
  MACHINE_STATUS = 'MachineStatus',
}

export enum GATEWAY_APPLICATION_CATEGORY {
  ONLINE = 'Online',
  ON_PREMISE = 'OnPremise',
}

export enum MACHINE_INTERNAL_STATE {
  NEW = 'new',
  EXISTING_NO_AUTH = 'existing_no_auth',
  EXISTING_AUTH = 'existing_auth',
}

export type TGatewaysSortKey =
  | GATEWAYS_SORT_KEYS.COMPANY_MANE
  | GATEWAYS_SORT_KEYS.SERIAL_NO
  | GATEWAYS_SORT_KEYS.INTERNET_STATUS
  | GATEWAYS_SORT_KEYS.CUSTOMER_CONTACT
  | GATEWAYS_SORT_KEYS.MACHINE_STATUS
  | GATEWAYS_SORT_KEYS.INSTALLATION_AREA
  | GATEWAYS_SORT_KEYS.APPLICATION_CATEGORY;

export type TGatewaysSortOrder = SORT_ORDER.ASCENDING | SORT_ORDER.DESCENDING;

export type TGateway = {
  id: string;
  serialNumber: string;
  companyName: string;
  installationArea: string;
  applicationCategory: string;
  internetStatus: string;
  machineStatus: string;
  dateCreated: string;
};

export type TGatewayDetails = {
  gatewayId?: string;
  companyId: string;
  serviceTechnicianId: string;
  installationAreaId: string;
  serialNumber: string;
  applicationCategory: GATEWAY_APPLICATION_CATEGORY.ONLINE | GATEWAY_APPLICATION_CATEGORY.ON_PREMISE;
  softwarePackageId: string;
};

export type TGatewayResponseDetails = {
  id: string;
  companyName: string;
  serialNumber: string;
  applicationCategory: GATEWAY_APPLICATION_CATEGORY.ONLINE | GATEWAY_APPLICATION_CATEGORY.ON_PREMISE;
  installationArea: string;
  serviceTechnicianName: string;
  serviceTechnicianEmail: string;
  serviceTechnicianPhone: string;
  softwarePackageId: string;
};

export type TNetworkConfiguration = {
  internetNetworkConfiguration: {
    isDhcpConfiguration: boolean;
    ipAddress: string | null;
    netmask: string | null;
    gatewayAddress: string | null;
    dns: string | null;
  };
  localNetworkConfiguration: {
    isDhcpConfiguration: boolean;
    ipAddress: string | null;
    netmask: string | null;
    gatewayAddress: string | null;
    dns: string | null;
  };
};

export type TNetworkManualConfig = {
  address: string | null;
  netmask: string | null;
  gateway: string | null;
  dns: string | null;
};

export type TNetworkConfigurationRequest = {
  gatewayId: string;
  internetConfiguration: {
    isDhcpConfiguration: boolean;
    manualConfiguration: TNetworkManualConfig | null;
  };
  localNetworkConfiguration: {
    isDhcpConfiguration: boolean;
    manualConfiguration: TNetworkManualConfig | null;
  } | null;
};

export type TMachine = {
  id: string;
  name: string;
  type: string;
  status: string;
  ipAddress: string;
  port: number;
  opcUaSchema: string;
};

export type TMachineType = {
  id: string;
  name: string;
  version: string;
  configuration: object | null;
  description: string;
  isOpcUaAuthentication: boolean;
  opcUaSchema: string;
  value: string;
  displayName: string;
};

export type TMachineConfiguration = {
  machineId: string;
  machineTypeId: string;
  name: string;
  serialNumber: string;
  ipAddress: string;
  port: number;
  schema: string | null;
  overrideAuthentication: boolean;
  userName: string | null;
  password?: string;
  credentialsChanged: boolean;
  internalState?:
    | MACHINE_INTERNAL_STATE.NEW
    | MACHINE_INTERNAL_STATE.EXISTING_AUTH
    | MACHINE_INTERNAL_STATE.EXISTING_NO_AUTH;
}[];

export type TSoftwarePackage = {
  id: string;
  name: string;
  version: string;
  isDefault: boolean;
  moduleIds: string[];
};
