import React from 'react';
import { Button } from '@mui/material';
import { styled } from '@mui/material/styles';

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

const FileUpload = ({ onUpload, label }) => {
  const readJsonFile = (file: Blob) =>
    new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsText(file);
      fileReader.onload = (e) => {
        if (e.target) {
          resolve(JSON.parse(e.target.result as string));
        }
      };
      fileReader.onerror = (error) => reject(error);
    });

  const handleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      const data = await readJsonFile(event.target.files[0]);
      onUpload(data);
    }
  };

  // Reset file upload input on each click to fire onChange on same files
  const handleClick = (event) => {
    event.target.value = '';
  };

  return (
    <>
      <Button component='label' role={undefined} variant='contained' tabIndex={-1}>
        {label}
        <VisuallyHiddenInput type='file' accept='.json' onChange={handleChange} onClick={handleClick} />
      </Button>
    </>
  );
};

export default FileUpload;
