import React from 'react';
import { styled } from '@mui/material/styles';
import { Button } from '@mui/material';

/* ------- Styles ------- */
const ConnectionStatusContainer = styled('div')({
  border: '1px solid #BDBDBD',
  borderRadius: '4px',
  width: '480px',
  height: '150px',
  marginTop: '20px',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'baseline',
  padding: '12px',
});

/* ------- Components ------- */
const ConnectionStatus: React.FC = () => {
  return (
    <ConnectionStatusContainer>
      <div>Connection Status</div>
      <Button variant='outlined' sx={{ fontSize: '15px', height: '42px', minWidth: '163px' }} onClick={() => {}}>
        TEST CONNECTION
      </Button>
    </ConnectionStatusContainer>
  );
};

export default ConnectionStatus;
