import { takeLatest, call, put, select } from 'redux-saga/effects';
import {
  fetchUserDataSuccess,
  fetchUserDataPending,
  fetchUsersPending,
  fetchUsersSuccess,
  fetchFailed,
  fetchAvailableTenantsSuccess,
  fetchAvailableTenantsPending,
  setActiveTenant,
  setBusinessUnit,
} from '../store/slices/user';
import apiClient from '../services/apiClient';
import { selectAuthUser } from '../store/selectors/user';
import { availableTenantsURI } from '../constants';
import { resetDictionariesReducer } from '../store/slices/dictionaries';
import { resetCompanyReducer } from '../store/slices/companies';
import { resetGatewaysReducer } from '../store/slices/gateways';

function* fetchUserDataSaga() {
  try {
    const authUser = yield select(selectAuthUser);

    const result = yield call(
      apiClient.get,
      `/organizations/${authUser.organizationId}/users/${authUser.localAccountId}`,
    );

    yield put(
      fetchUserDataSuccess({
        user: result,
      }),
    );
  } catch (error) {
    yield put(
      fetchFailed({
        error,
      }),
    );
  }
}

function* fetchUsersSaga() {
  try {
    const result = yield call(apiClient.get, `/Users`);

    yield put(
      fetchUsersSuccess({
        users: result.users,
      }),
    );
  } catch (error) {
    yield put(
      fetchFailed({
        error,
      }),
    );
  }
}

function* setupUserTenantSaga() {
  try {
    const result = yield call(apiClient.get, availableTenantsURI);

    yield put(fetchAvailableTenantsSuccess(result));
    yield put(setActiveTenant({ tenantId: result[0].id }));
    yield put(fetchUsersPending());
  } catch (error) {
    yield put(
      fetchFailed({
        error,
      }),
    );
  }
}

function* setBusinessUnitSaga({ payload }) {
  try {
    yield put(setActiveTenant(payload));
    yield put(fetchUsersPending());
    yield put(resetDictionariesReducer());
    yield put(resetCompanyReducer());
    yield put(resetGatewaysReducer());
  } catch (error) {
    yield put(
      fetchFailed({
        error,
      }),
    );
  }
}

export default function* watch() {
  yield takeLatest(fetchUserDataPending, fetchUserDataSaga);
  yield takeLatest(fetchUsersPending, fetchUsersSaga);
  yield takeLatest(fetchAvailableTenantsPending, setupUserTenantSaga);
  yield takeLatest(setBusinessUnit, setBusinessUnitSaga);
}
