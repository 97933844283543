import React from 'react';
import { styled } from '@mui/material/styles';
import { Box, CssBaseline, Divider, List, ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useMsal } from '@azure/msal-react';
import CustomTooltip from '../CustomTooltip';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import ViewSelector from '../ViewSelector';

/* ------- Styles ------- */

const SidebarHeader = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  margin: '0px 36px',
});

/* ------- Types ------- */
export type TItem = {
  id: string;
  name: string;
  permitted?: boolean;
  icon: JSX.Element;
  onClick: () => void;
  disabled?: boolean;
  routes?: string[];
};

interface ISidebarProps {
  activeItemId: string | undefined;
  groupedItems: TItem[][];
}
/* ------- Components ------- */
const Sidebar: React.FC<ISidebarProps> = ({ activeItemId, groupedItems }) => {
  const { t } = useTranslation();
  const { height } = useWindowDimensions();
  const { instance } = useMsal();

  const bottomBlockHeight = height - 606; // height of blocks above the BU selector

  const handleLogout = () => {
    instance.logoutRedirect();
  };

  const renderListItem = (item: TItem) => {
    const isItemSelected = item.routes && activeItemId ? item.routes.includes(activeItemId) : activeItemId === item.id;
    const Item = (
      <ListItem key={item.id} sx={{ padding: '0' }}>
        <ListItemButton
          onClick={item.onClick}
          disabled={item.disabled}
          sx={{
            minHeight: '54px',
            justifyContent: 'center',
            px: '24px',
            backgroundColor: (theme) => (isItemSelected ? theme.palette.green[100] : theme.palette.white.main),
            ':hover': {
              backgroundColor: (theme) => (isItemSelected ? theme.palette.green[100] : theme.palette.green[20]),
            },
          }}
        >
          <ListItemIcon
            sx={{
              minWidth: 0,
              mr: 3,
              justifyContent: 'center',
              marginRight: '16px',
              filter: isItemSelected
                ? 'brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(7500%) hue-rotate(296deg) brightness(105%) contrast(103%);'
                : 'none',
            }}
          >
            {item.icon}
          </ListItemIcon>

          <ListItemText
            primary={item.name}
            primaryTypographyProps={{ fontWeight: 500 }}
            sx={{
              color: (theme) => (isItemSelected ? theme.palette.white.main : theme.palette.black[100]),
            }}
          />
        </ListItemButton>
      </ListItem>
    );

    if (item.disabled) {
      return <CustomTooltip message={t('common:comingSoon')}>{Item}</CustomTooltip>;
    }

    return Item;
  };

  return (
    <Box sx={{ display: 'inline-flex', height: '100%', zIndex: 100 }}>
      <CssBaseline />
      <Box
        data-testid='sidebar'
        sx={{
          width: '320px',
          height: height,
          boxShadow: '5px 6px 6px 0px rgba(0, 0, 0, 0.1);',
          position: 'sticky',
          top: 0,
        }}
      >
        <SidebarHeader sx={{ justifyContent: 'center', marginBottom: '60px' }}>
          <img
            src='/schunk-logo.svg'
            width='235'
            height='128'
            alt={t('common:logo:alt')}
            style={{ alignSelf: 'center' }}
          />
        </SidebarHeader>

        {groupedItems.map((group, index) => (
          <React.Fragment key={`itemGroup-${index}`}>
            <List disablePadding>{group.filter((item) => item.permitted).map((item) => renderListItem(item))}</List>
            {index !== groupedItems.length - 1 && <Divider sx={{ margin: '44px 0 24px 0' }} data-testid='divider' />}
          </React.Fragment>
        ))}

        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            height: bottomBlockHeight,
          }}
        >
          <ViewSelector />

          <div>
            {renderListItem({
              id: 'logout',
              name: t('common:sidebar:views:logout'),
              icon: <img src='/icons/logout.svg' alt={t('common:sidebar:views:logout')} />,
              onClick: handleLogout,
            })}
          </div>
        </div>
      </Box>
    </Box>
  );
};

export default Sidebar;
