import { useState, useEffect } from 'react';
import { User } from '../helpers';
import { useSelector } from './redux';
import { selectAuthUser } from '../store/selectors/user';
import { useNavigate } from 'react-router-dom';
import { routes } from '../constants';

const useAccessControl = () => {
  const authUser = useSelector(selectAuthUser);
  const navigate = useNavigate();
  const [userAccess, setUserAccess] = useState(new User(authUser?.organizationPermissions));

  const accessControlRedirect = (accessKeys: string[]) => {
    if (accessKeys.some((key) => userAccess[key])) {
      return;
    } else {
      navigate(routes.UNAUTHORIZED);
    }
  };

  useEffect(() => {
    setUserAccess(new User(authUser?.organizationPermissions));
  }, [authUser]);

  return { userAccess, accessControlRedirect };
};

export default useAccessControl;
