import React, { useState } from 'react';
import { Box, Button, styled } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';
import GatewaysList from './GatewaysList';
import GatewayDetails from './GatewayDetails';
import CustomSnackbar from '../../common/Snackbar';
import { decommissionGatewayPending, resetGatewayFlags } from '../../store/slices/gateways';
import { useDispatch, useSelector } from '../../hooks/redux';
import { selectGatewayDetails, selectGatewayFlags } from '../../store/selectors/gateways';
import { GATEWAY_APPLICATION_CATEGORY, TGateway } from '../../types';
import { useNavigate } from 'react-router-dom';
import { accessKeys, routes } from '../../constants';
import { setGateway } from '../../store/slices/companies';
import { selectGateway } from '../../store/selectors/companies';
import DeleteDialog from '../../common/DeleteDialog';
import useAccessControl from '../../hooks/useAccessControl';

/* ------- Styles ------- */
const GatewaysContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  padding: '64px 48px 0 48px',
  backgroundColor: '#FBFBFB',
  width: '100%',
});

/* ------- Components ------- */
const Gateways = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { userAccess, accessControlRedirect } = useAccessControl();

  const [showGatewayDetails, setShowGatewayDetails] = useState<boolean>(false);
  const [gatewayId, setGatewayId] = useState<string | undefined>(undefined);
  const [itemForDecommissioning, setItemForDecommissioning] = useState<string | undefined>(undefined);
  const [decommissionMessage, setDecommissionMessage] = useState<string | undefined>(undefined);

  const details = useSelector(selectGatewayDetails);
  const gateway = useSelector(selectGateway);
  const { created, updated, deleted, network_configured, decommissioned } = useSelector(selectGatewayFlags);

  const showNotification = created || updated || deleted || network_configured || decommissioned;
  const getEventType = () => {
    switch (true) {
      case created:
        return 'add';
      case updated:
        return 'edit';
      case deleted:
        return 'delete';
      case decommissioned:
        return 'decommissioned';
      case network_configured:
        return 'network_configured';
      default:
        return 'default';
    }
  };

  const getNotification = () => {
    const eventType = getEventType();
    const serialNumber = details?.serialNumber || gateway?.serialNumber;
    const prefix = eventType === 'network_configured' ? 'Network Configuration for ' : '';
    const message = t(`gateways:dialog:${eventType}:notificationSuccess`);
    return (
      <>
        {prefix}Gateway <b>{serialNumber}</b> {message}
      </>
    );
  };

  const handleOpenAddNew = () => {
    setGatewayId(undefined);
    setShowGatewayDetails(true);
  };

  const handleOpenDetails = (id: string) => {
    setGatewayId(id);
    setShowGatewayDetails(true);
  };

  const handleCloseDetails = () => {
    setGatewayId(undefined);
    setShowGatewayDetails(false);
  };

  const handleNotificationClose = () => {
    dispatch(resetGatewayFlags());
  };

  const handleNetworkConfigurationOpen = (gateway: TGateway) => {
    dispatch(setGateway({ id: gateway.id, serialNumber: gateway.serialNumber }));
    navigate(routes.NETWORK_CONFIGURATION);
  };

  const handleMachineConfigurationOpen = (gateway: TGateway) => {
    dispatch(setGateway({ id: gateway.id, serialNumber: gateway.serialNumber }));
    navigate(routes.MACHINE_CONFIGURATION);
  };

  const handleMachineListOpen = (gateway: TGateway) => {
    dispatch(setGateway({ id: gateway.id, serialNumber: gateway.serialNumber }));
    navigate(routes.MACHINE_LIST);
  };

  const handleDecommissionOpen = (gateway: TGateway) => {
    setItemForDecommissioning(gateway.id);
    if (gateway.applicationCategory === GATEWAY_APPLICATION_CATEGORY.ONLINE) setDecommissionMessage('Online');
    else setDecommissionMessage('Offline');
  };

  const handleDecommission = () => {
    setItemForDecommissioning(undefined);
    setDecommissionMessage(undefined);
    if (itemForDecommissioning) {
      dispatch(decommissionGatewayPending({ gatewayId: itemForDecommissioning }));
    }
  };

  accessControlRedirect([accessKeys.VIEW_GATEWAYS_ALLOWED]);

  return (
    <GatewaysContainer>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <h3 style={{ fontSize: '24px', margin: 0 }}>{t('gateways:title')}</h3>
        {userAccess.create_gateway_allowed ? (
          <Button
            variant='contained'
            sx={{ color: '#fff', fontSize: '15px', maxHeight: '42px', minWidth: '192px' }}
            onClick={handleOpenAddNew}
          >
            {t('gateways:addNew')}
          </Button>
        ) : null}
      </div>

      <GatewaysList
        onOpenDetails={handleOpenDetails}
        onNetworkConfigurationOpen={handleNetworkConfigurationOpen}
        onMachineConfigurationOpen={handleMachineConfigurationOpen}
        onMachineListOpen={handleMachineListOpen}
        onDecommissionOpen={handleDecommissionOpen}
      />

      <GatewayDetails gatewayId={gatewayId} open={showGatewayDetails} onClose={handleCloseDetails} />

      <CustomSnackbar
        severity='success'
        open={showNotification}
        onClose={handleNotificationClose}
        message={getNotification()}
      />

      <DeleteDialog
        open={!!itemForDecommissioning}
        onConfirm={handleDecommission}
        onClose={() => setItemForDecommissioning(undefined)}
        dialogTitle={t('gateways:decommission:title')}
        confirmationText={t('gateways:decommission:confirmation')}
        dialogText={
          <Trans
            i18nKey={`gateways:decommission:text${decommissionMessage}`}
            values={{
              lineBreak: '<br />',
            }}
          />
        }
      />
    </GatewaysContainer>
  );
};

export default Gateways;
