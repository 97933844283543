export const routes: Record<string, string> = Object.freeze({
  LANDING: '/',
  GATEWAYS: '/gateways',
  MACHINE_LIST: '/gateways/machine-list',
  MACHINE_CONFIGURATION: '/gateways/machine-configuration',
  COMPANIES: '/companies',
  INSTALLATION_AREAS: '/companies/installation-areas',
  COMPANY_GATEWAYS: '/companies/gateways',
  NETWORK_CONFIGURATION: '/companies/gateways/network-configuration',
  PROFILE: '/profile',
  SETTINGS: '/settings',
  NOTIFICATIONS: '/notifications',
  UNAUTHORIZED: '/403',
});

export const machineStatus = Object.freeze({
  ERROR: 'Error',
  WARNING: 'Warning',
  RUNNING: 'Running',
  WAITING: 'Waiting',
  OFF: 'Off',
});

export const internetStatus = Object.freeze({
  CONNECTED: 'Connected',
  NOT_CONNECTED: 'NotConnected',
});

export const SNACKBAR_TIMEOUT = 7000;

export const listPageSize = 13;

export const DEFAULT_INSTALLATION_AREA_NAME = 'Unassigned';

export const NEW_INSTALLATION_AREA_NAME = 'New Installation Area';

export const availableTenantsURI = '/AvailableTenants';
export const serialNumberCheckURI = '/SerialNumber/check';
export const staticCountriesURI = '/Dictionary/staticCountries';

export const DEVICE_MANAGEMENT_PERMISSION_PREFIX = 'DevMgmt';
export const USER_MANAGEMENT_PERMISSION_PREFIX = 'UsrMgmt';

export const accessKeys = Object.freeze({
  VIEW_COMPANIES_ALLOWED: 'view_companies_allowed',
  // Can view all companies and their installation areas

  CREATE_COMPANY_ALLOWED: 'create_company_allowed',
  // Can create companies

  CREATE_INSTALLATION_AREA_ALLOWED: 'create_installation_area_allowed',
  // Can create installation areas

  VIEW_GATEWAYS_ALLOWED: 'view_gateways_allowed',
  // Contains view companies
  // Can see gateways

  CREATE_GATEWAY_ALLOWED: 'create_gateway_allowed',
  // Can do the commissioning process for gateways
  // Can configure network
  // Can configure machines
  // Can edit gateway information

  RESET_GATEWAY_ALLOWED: 'reset_gateway_allowed',
  // Can trigger factory resets on gateways
  // Can decommission gateways

  USER_VIEW_ALLOWED: 'user_view_allowed',
  // Can see user management data but not write

  USER_ADMINISTRATION_ALLOWED: 'user_administration_allowed',
  // Can invite new users, delete users and change roles of the users
});
