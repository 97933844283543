import { ApiError } from './apiError';
import axios, { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import { getErrorMessage, setAuthHeader } from './helpers';
import { REACT_APP_DEVICE_MANAGEMENT_API_URL } from '../../config';
import { availableTenantsURI, serialNumberCheckURI, staticCountriesURI } from '../../constants';

let store;

const tenantSpecificEndpointExceptions = [availableTenantsURI, serialNumberCheckURI, staticCountriesURI];

export const injectStore = (_store) => {
  store = _store;
};

const exceptions = (instance: AxiosInstance) => {
  instance.interceptors.response.use(
    (response: AxiosResponse) => response,
    (error: AxiosError) => {
      if (error.response) {
        return Promise.reject(new ApiError(getErrorMessage(error), error.response.status, error.response.data));
      }
    },
  );
};

const tenantAwareness = (instance: AxiosInstance) => {
  instance.interceptors.request.use((config) => {
    const tenantId = store.getState().user.activeTenantId;

    if (tenantId && config.url && !tenantSpecificEndpointExceptions.includes(config.url)) {
      config.url = `/tenant/${tenantId}${config.url}`;
    }

    return config;
  });
};

const makeInstance = () => {
  const config: AxiosRequestConfig = {
    baseURL: REACT_APP_DEVICE_MANAGEMENT_API_URL,
    headers: {
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest',
    },
  };
  const instance = axios.create(config);

  tenantAwareness(instance);
  exceptions(instance);
  setAuthHeader(instance);

  return instance;
};

export default makeInstance();
