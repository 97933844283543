import { createSelector } from 'reselect';
import { RootState } from '..';

const dictionaries = (state: RootState) => state.dictionaries;

export const selectMachineTypes = createSelector(dictionaries, (dictionaries) => dictionaries.machineTypes);
export const selectInternetStatuses = createSelector(dictionaries, (dictionaries) => dictionaries.internetStatuses);
export const selectApplicationCategories = createSelector(
  dictionaries,
  (dictionaries) => dictionaries.applicationCategories,
);
export const selectCountryList = createSelector(dictionaries, (dictionaries) => dictionaries.countryList);
