import { takeLatest, call, put } from 'redux-saga/effects';
import apiClient from '../services/apiClient';
import {
  fetchCountryListPending,
  fetchCountryListSuccess,
  fetchDictionariesPending,
  fetchDictionariesSuccess,
  fetchFailed,
} from '../store/slices/dictionaries';
import { staticCountriesURI } from '../constants';

function* fetchDictionariesSaga() {
  try {
    const { machineTypes } = yield call(apiClient.get, '/Dictionary/machineTypes');
    const applicationCategories = yield call(apiClient.get, '/Dictionary/applicationCategories');
    const internetStatuses = yield call(apiClient.get, '/Dictionary/internetStatus');

    yield put(
      fetchDictionariesSuccess({
        machineTypes,
        applicationCategories,
        internetStatuses,
      }),
    );
  } catch (error) {
    yield put(
      fetchFailed({
        error,
      }),
    );
  }
}

function* fetchCountryListSaga() {
  try {
    const response = yield call(apiClient.get, staticCountriesURI);

    if (response.countries) {
      yield put(fetchCountryListSuccess(response.countries));
    }
  } catch (error) {
    yield put(fetchFailed({ error }));
  }
}

export default function* watch() {
  yield takeLatest(fetchDictionariesPending, fetchDictionariesSaga);
  yield takeLatest(fetchCountryListPending, fetchCountryListSaga);
}
