import { accessKeys, DEVICE_MANAGEMENT_PERMISSION_PREFIX, USER_MANAGEMENT_PERMISSION_PREFIX } from './constants';

/* ------- Types ------- */
type TStringArrayEquals = (value1: string[], value2: string[]) => boolean;
type TGetFormattedDateTime = (dateString: string, options?: Intl.DateTimeFormatOptions) => string;

/* ------- Helpers ------- */
export const removeBlankAttributes = (obj: object) => {
  const result = {};
  for (const key in obj) {
    if (obj[key] !== null && obj[key] !== undefined && obj[key].length) {
      result[key] = obj[key];
    }
  }

  return result;
};

export const sortStrings = (arr, stringKey: string) => {
  return arr.sort((a, b) => {
    const stringA = a[stringKey]?.toUpperCase(); // ignore upper and lowercase
    const stringB = b[stringKey]?.toUpperCase(); // ignore upper and lowercase
    if (stringA < stringB) {
      return -1;
    }
    if (stringA > stringB) {
      return 1;
    }

    // names must be equal
    return 0;
  });
};

export const stringArrayEquals: TStringArrayEquals = (array1, array2) => {
  const sortedArray2 = array2.sort();

  return (
    Array.isArray(array1) &&
    Array.isArray(array2) &&
    array1.length === array2.length &&
    array1.sort().every((val, index) => val === sortedArray2[index])
  );
};

export const getStringWithIndex = (arr: string[] | undefined, baseString: string) => {
  let duplicateIndex = 0;
  let stringWithIndex = baseString;
  // Check if the baseString already exists in the array
  const checkIsIncludes = (stringArray: string[]) => {
    if (stringArray.includes(stringWithIndex)) {
      // increment index
      duplicateIndex++;
      // set string with incremented index
      stringWithIndex = `${baseString} (${duplicateIndex})`;
      // check again
      checkIsIncludes(stringArray);
    }
  };

  if (arr) {
    checkIsIncludes(arr);
  }

  return stringWithIndex;
};

export const getFormattedDate = (dateString: string) => new Date(dateString).toLocaleDateString();

export const getFormattedTime: TGetFormattedDateTime = (dateString, options = { hour12: true, timeStyle: 'short' }) =>
  new Date(dateString).toLocaleTimeString('en', options);

export const isValidIPItemValue = (val) => {
  val = parseInt(val);
  return !isNaN(val) && val >= 0 && val <= 255;
};

export const isValidIPString = (ipString: string, required = true) => {
  if (ipString === null || ipString === undefined) return false;
  if (required && !ipString.length) return false;
  if (!required && ipString === '') return true;

  const substrings = ipString.split('.');
  if (substrings.length !== 4) {
    return false;
  }

  let invalidStringsCount = 0;
  substrings.forEach((str) => {
    if (!isValidIPItemValue(str)) {
      invalidStringsCount++;
    }
  });

  return invalidStringsCount === 0;
};

export class User {
  [accessKeys.VIEW_COMPANIES_ALLOWED]: boolean;
  [accessKeys.CREATE_COMPANY_ALLOWED]: boolean;
  [accessKeys.CREATE_INSTALLATION_AREA_ALLOWED]: boolean;
  [accessKeys.VIEW_GATEWAYS_ALLOWED]: boolean;
  [accessKeys.CREATE_GATEWAY_ALLOWED]: boolean;
  [accessKeys.RESET_GATEWAY_ALLOWED]: boolean;
  [accessKeys.USER_VIEW_ALLOWED]: boolean;
  [accessKeys.USER_ADMINISTRATION_ALLOWED]: boolean;

  constructor(permissions) {
    this[accessKeys.VIEW_COMPANIES_ALLOWED] = permissions?.includes(
      `${DEVICE_MANAGEMENT_PERMISSION_PREFIX}.ViewCompanies`,
    );
    this[accessKeys.CREATE_COMPANY_ALLOWED] = permissions?.includes(
      `${DEVICE_MANAGEMENT_PERMISSION_PREFIX}.CreateCompany`,
    );
    this[accessKeys.CREATE_INSTALLATION_AREA_ALLOWED] = permissions?.includes(
      `${DEVICE_MANAGEMENT_PERMISSION_PREFIX}.CreateInstallationArea`,
    );
    this[accessKeys.VIEW_GATEWAYS_ALLOWED] = permissions?.includes(
      `${DEVICE_MANAGEMENT_PERMISSION_PREFIX}.ViewGateways`,
    );
    this[accessKeys.CREATE_GATEWAY_ALLOWED] = permissions?.includes(
      `${DEVICE_MANAGEMENT_PERMISSION_PREFIX}.CreateGateway`,
    );
    this[accessKeys.RESET_GATEWAY_ALLOWED] = permissions?.includes(
      `${DEVICE_MANAGEMENT_PERMISSION_PREFIX}.ResetGateway`,
    );
    this[accessKeys.USER_VIEW_ALLOWED] = permissions?.includes(`${USER_MANAGEMENT_PERMISSION_PREFIX}.Read`);
    this[accessKeys.USER_ADMINISTRATION_ALLOWED] = permissions?.includes(`${USER_MANAGEMENT_PERMISSION_PREFIX}.Admin`);
  }
}
