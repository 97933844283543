import React from 'react';
import { useTranslation } from 'react-i18next';
import GatewayDetails from '../../Gateways/GatewayDetails';
import CustomSnackbar from '../../../common/Snackbar';
import { resetGatewayFlags } from '../../../store/slices/gateways';
import { useDispatch, useSelector } from '../../../hooks/redux';
import { selectGatewayDetails, selectGatewayFlags } from '../../../store/selectors/gateways';
import { selectGateway } from '../../../store/selectors/companies';

/* ------- Types ------- */
interface ICompanyGatewayDetailsProps {
  gatewayId: string | undefined;
  companyId: string | undefined;
  show: boolean;
  onClose: () => void;
}

/* ------- Components ------- */
const CompanyGatewayDetails: React.FC<ICompanyGatewayDetailsProps> = ({ companyId, gatewayId, show, onClose }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const details = useSelector(selectGatewayDetails);
  const gateway = useSelector(selectGateway);
  const { created, updated, network_configured, decommissioned } = useSelector(selectGatewayFlags);

  const showNotification = created || updated || network_configured || decommissioned;
  const getEventType = () => {
    switch (true) {
      case created:
        return 'add';
      case updated:
        return 'edit';
      case network_configured:
        return 'network_configured';
      case decommissioned:
        return 'decommissioned';
      default:
        return 'default';
    }
  };

  const getNotification = () => {
    const eventType = getEventType();
    const serialNumber = details?.serialNumber || gateway?.serialNumber;
    const prefix = eventType === 'network_configured' ? 'Network Configuration for ' : '';
    const message = t(`gateways:dialog:${eventType}:notificationSuccess`);
    return (
      <>
        {prefix}Gateway <b>{serialNumber}</b> {message}
      </>
    );
  };

  const handleNotificationClose = () => {
    dispatch(resetGatewayFlags());
  };

  return (
    <>
      <GatewayDetails companyId={companyId} gatewayId={gatewayId} open={show} onClose={onClose} />
      <CustomSnackbar
        severity='success'
        open={showNotification}
        onClose={handleNotificationClose}
        message={getNotification()}
      />
    </>
  );
};

export default CompanyGatewayDetails;
