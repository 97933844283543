import { createSlice } from '@reduxjs/toolkit';
import { TApiUser, TAuthUser, TTenant } from '../../types';
import { ApiError } from '../../services/apiClient/apiError';

/*-----------------TYPES-----------------*/
type TPayloadError = {
  error: ApiError;
};

type TFetchUserData = {
  user: TApiUser;
};

type TFetchUsers = {
  users: TApiUser[];
};

export type TDeleteUser = {
  userId: string;
};

export type TUpdateUserRoles = {
  organizationId: string;
  userId: string;
  roleIds: string[];
};

export interface IUser {
  authUser: TAuthUser | null;
  user: TApiUser | null;
  users: TApiUser[] | null;
  tenants: TTenant[];
  activeTenantId: string | null;
  error: ApiError | null;
}

/*-----------------INITIAL STATE-----------------*/
export const initialState: IUser = {
  authUser: null,
  user: null,
  users: null,
  tenants: [],
  activeTenantId: null,
  error: null,
};

/*-----------------SLICE-----------------*/
const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    /*-----------------Pending Actions/Reducers-----------------*/
    fetchUserDataPending: (state) => state,
    fetchUsersPending: (state) => state,
    fetchAvailableTenantsPending: (state) => state,
    setBusinessUnit: (
      state,
      _action: {
        payload: { tenantId: string };
      },
    ) => state,

    /*-----------------Success Actions/Reducers-----------------*/
    fetchUserDataSuccess: (state, { payload }: { payload: TFetchUserData }) => {
      return {
        ...state,
        user: payload.user,
      };
    },
    fetchUsersSuccess: (state, { payload }: { payload: TFetchUsers }) => {
      return {
        ...state,
        users: payload.users,
      };
    },
    fetchAvailableTenantsSuccess: (state, { payload }: { payload: TTenant[] }) => {
      return {
        ...state,
        tenants: payload,
      };
    },
    setAuthUser: (state, { payload }: { payload: TAuthUser }) => {
      return {
        ...state,
        authUser: payload,
      };
    },

    setActiveTenant: (state, { payload }: { payload: { tenantId: string } }) => {
      return {
        ...state,
        activeTenantId: payload.tenantId,
        users: initialState.users,
      };
    },

    /*-----------------Failed Actions/Reducers-----------------*/
    fetchFailed: (state, { payload }: { payload: TPayloadError }) => {
      return {
        ...state,
        error: payload.error,
      };
    },
  },
});

export const {
  fetchUserDataPending,
  fetchUsersPending,
  fetchAvailableTenantsPending,
  setBusinessUnit,
  fetchUserDataSuccess,
  fetchUsersSuccess,
  fetchAvailableTenantsSuccess,
  setAuthUser,
  setActiveTenant,
  fetchFailed,
} = userSlice.actions;

export default userSlice.reducer;
