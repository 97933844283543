import { Autocomplete, TextField, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/material/styles';
import { useDispatch, useSelector } from '../../hooks/redux';
import { selectActiveTenantId, selectAvailableTenants } from '../../store/selectors/user';
import { setBusinessUnit } from '../../store/slices/user';
import { routes } from '../../constants';
import { useNavigate } from 'react-router-dom';

/* ------- Styles ------- */
const SelectorContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
  padding: '0 24px',
});

/* ------- Components ------- */
const ViewSelector: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const tenants = useSelector(selectAvailableTenants);
  const activeTenantId = useSelector(selectActiveTenantId);

  const handleBusinessUnitSelect = (_e, nextValue: string) => {
    dispatch(setBusinessUnit({ tenantId: nextValue }));
    navigate(routes.LANDING);
  };

  return (
    <SelectorContainer>
      <Typography sx={{ fontWeight: '600' }}>{t('common:sidebar:viewSelector:title')}</Typography>
      {activeTenantId ? (
        <Autocomplete
          value={activeTenantId}
          size='small'
          disableClearable
          onChange={handleBusinessUnitSelect}
          options={tenants.map((tenant) => tenant.id)}
          getOptionLabel={(option) => tenants.find((tenant) => tenant.id === option)?.name || 'n/a'}
          sx={{ width: 272 }}
          renderInput={(params) => <TextField {...params} label={t('common:sidebar:viewSelector:selectorTitle')} />}
        />
      ) : null}
    </SelectorContainer>
  );
};

export default ViewSelector;
