import React, { useState, useRef, ReactNode, useEffect } from 'react';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Toolbar,
  Paper,
  Link,
  TextField,
} from '@mui/material';
import CustomPagination from '../CustomPagination';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ContextMenu, { ACTION_TYPE, ContextButton, IContextMenu } from '../ContextMenu';
import { SORT_ORDER } from '../../types';
import { useTranslation } from 'react-i18next';

/* ------- Styles ------- */
const columnHeaderDividerStyles = {
  '*::before, *::after': {
    position: 'absolute',
    width: '2px',
    height: '14px',
    content: '""',
    left: '-16px',
    top: '5px',
    borderLeft: '2px solid #E0E0E0',
  },
};

/* ------- Types ------- */
export interface IHeadCell {
  disablePadding: boolean;
  id: string;
  label: string;
  numeric: boolean;
  sortable: boolean;
  sortKey?: string;
}

export enum CELL_TYPE {
  FILED = 'field',
  MENU = 'menu',
  ACTION = 'action',
}

export interface IRowData {
  id: string;
  cells: {
    key: string;
    value?: ReactNode;
    displayValue?: ReactNode;
    type: CELL_TYPE.FILED | CELL_TYPE.MENU | CELL_TYPE.ACTION;
    actions?: IContextMenu['contextActions'];
    width?: string;
    edit?: boolean;
  }[];
}

type TOrder = SORT_ORDER.ASCENDING | SORT_ORDER.DESCENDING;

interface IEnhancedTableToolbarProps {
  FilterComponent: ReactNode;
}

interface IEnhancedTableHeaderProps {
  onRequestSort: (sortKey: string, order: TOrder) => void;
  order: TOrder;
  orderBy: string;
  rowCount: number;
  headCells: IHeadCell[];
}

interface IEnhancedTable {
  rows: IRowData[];
  rowsPerPage?: number;
  total?: number;
  page?: number;
  headRow: IHeadCell[];
  sortOrder?: TOrder;
  orderBy?: string;
  setPage?: (_event: unknown, newPage: number) => void;
  onSort?: (sortKey: string, order: string) => void;
  filter?: ReactNode;
}

const invertOrder = (orderDirection) => {
  return orderDirection !== SORT_ORDER.ASCENDING ? SORT_ORDER.ASCENDING : SORT_ORDER.DESCENDING;
};

const descendingComparator = (a: IRowData, b: IRowData, orderBy: string) => {
  const aValue = a.cells.find((cell) => cell.key === orderBy)?.value;
  const bValue = b.cells.find((cell) => cell.key === orderBy)?.value;

  if (bValue && aValue) {
    if (bValue < aValue) {
      return -1;
    }
    if (bValue > aValue) {
      return 1;
    }
  }

  return 0;
};

const getComparator = (order: TOrder, orderBy: string): ((a: IRowData, b: IRowData) => number) => {
  return order === SORT_ORDER.DESCENDING
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
};

/* ------- Components ------- */
const EnhancedTableHead: React.FC<IEnhancedTableHeaderProps> = ({ order, orderBy, onRequestSort, headCells }) => {
  const handleSort = (sortKey: string) => {
    const nextOrder = invertOrder(order);
    onRequestSort(sortKey, nextOrder);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell, index) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.sortKey ? order : false}
            sx={index > 0 ? columnHeaderDividerStyles : {}}
          >
            {headCell.sortable && headCell.sortKey ? (
              <TableSortLabel
                active={orderBy === headCell.sortKey}
                direction={orderBy === headCell.sortKey ? order : SORT_ORDER.ASCENDING}
                // @ts-expect-error: some ts bug, headCell.sortKey can't be undefined because of condition
                onClick={() => handleSort(headCell.sortKey)}
              >
                {headCell.label}
              </TableSortLabel>
            ) : (
              <span style={{ position: 'relative', display: 'flex' }}>{headCell.label}</span>
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

const EnhancedTableToolbar: React.FC<IEnhancedTableToolbarProps> = ({ FilterComponent = null }) => (
  <Toolbar sx={{ p: { sm: 3 } }}>{FilterComponent}</Toolbar>
);

const EnhancedContextMenu = ({ actions }) => {
  const [contextOpen, setContextOpen] = useState<boolean>(false);
  const menuAnchorRef = useRef<HTMLButtonElement | null>(null);

  return (
    <>
      <ContextButton
        ref={menuAnchorRef}
        testId='issueMenu-button'
        menuOpen={contextOpen}
        handleOpenMenu={() => setContextOpen(true)}
      >
        <MoreVertIcon />
      </ContextButton>
      {contextOpen && (
        <ContextMenu
          menuOpen={true}
          anchorEl={menuAnchorRef.current}
          handleCloseMenu={() => setContextOpen(false)}
          contextActions={actions}
        />
      )}
    </>
  );
};

const EnhancedAction = ({ action, editValue, onEdit }) => {
  const isFieldValid = !!editValue?.length;
  const handler = () => {
    switch (action.type) {
      case ACTION_TYPE.SAVE:
        return () => {
          if (isFieldValid) {
            action.callback(editValue);
            onEdit(undefined);
          }
        };
      case ACTION_TYPE.CANCEL:
        return () => {
          action.callback();
          onEdit(undefined);
        };
      default:
        return () => action.callback();
    }
  };
  return (
    <Link
      sx={{ fontSize: '14px', fontWeight: 400, marginRight: '24px' }}
      component='button'
      underline='none'
      disabled={action.disabled || (action.type === ACTION_TYPE.SAVE && !isFieldValid)}
      onClick={handler()}
    >
      {action.name}
    </Link>
  );
};

const EnhancedField = ({ cell, editValue, onEdit }) => {
  const { t } = useTranslation();

  useEffect(() => {
    if (cell.edit && editValue === undefined) onEdit(cell.value);
  }, [cell, editValue, onEdit]);

  if (!cell.edit) return cell.displayValue || cell.value;

  return (
    <TextField
      sx={{ minWidth: '50%' }}
      id='name'
      type='text'
      size='small'
      value={editValue}
      onChange={(event) => onEdit(event.target.value)}
      autoFocus
      error={!editValue?.length}
      helperText={!editValue?.length && t('companies:installationArea:emptyName')}
    />
  );
};

const EnhancedTable: React.FC<IEnhancedTable> = ({
  rows,
  headRow,
  rowsPerPage,
  total,
  page,
  setPage,
  sortOrder,
  orderBy,
  onSort,
  filter,
}) => {
  const [editFieldValue, setEditFieldValue] = useState<string | undefined>(undefined);
  const withPagination = !!total && !!rowsPerPage;
  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = withPagination ? rowsPerPage - rows.length : 0;

  const firstColumnToSort = headRow.filter((row) => !!row.sortable)[0].sortKey;
  const [localSortOrder, setLocalSortOrder] = useState<TOrder>(SORT_ORDER.ASCENDING);
  const [localOrderBy, setLocalOrderBy] = useState<string>(firstColumnToSort || '');

  let sortedRows = rows.sort(getComparator(localSortOrder, localOrderBy));
  const localOnSort = (sortKey: string, nextOrder: TOrder) => {
    sortedRows = rows.sort(getComparator(nextOrder, sortKey));
    setLocalSortOrder(nextOrder);
    setLocalOrderBy(sortKey);
  };

  const ownSortOrder = sortOrder || localSortOrder;
  const ownOrderBy = orderBy || localOrderBy;
  const ownOnRequestSort = onSort || localOnSort;

  return (
    <Box sx={{ width: '100%' }}>
      <Paper sx={{ width: '100%', mb: 2 }}>
        {filter ? <EnhancedTableToolbar FilterComponent={filter} /> : null}
        <TableContainer>
          <Table sx={{ minWidth: 750 }} aria-labelledby='tableTitle' size={'medium'}>
            <EnhancedTableHead
              order={ownSortOrder}
              orderBy={ownOrderBy}
              onRequestSort={ownOnRequestSort}
              rowCount={rows.length}
              headCells={headRow}
            />
            <TableBody>
              {(onSort ? rows : sortedRows).map((row) => {
                return (
                  <TableRow hover tabIndex={-1} key={row.id} selected={false} sx={{ cursor: 'pointer' }}>
                    {row?.cells.map((cell) => (
                      <TableCell
                        key={cell.key}
                        component='th'
                        align='left'
                        scope='row'
                        sx={
                          cell?.width
                            ? {
                                maxWidth: cell.width,
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                              }
                            : {}
                        }
                      >
                        {cell.type === CELL_TYPE.FILED ? (
                          <EnhancedField cell={cell} editValue={editFieldValue} onEdit={setEditFieldValue} />
                        ) : null}
                        {cell.type === CELL_TYPE.MENU ? <EnhancedContextMenu actions={cell.actions} /> : null}
                        {cell.type === CELL_TYPE.ACTION
                          ? cell.actions?.map((action) => (
                              <EnhancedAction
                                key={action.name}
                                action={action}
                                editValue={editFieldValue}
                                onEdit={setEditFieldValue}
                              />
                            ))
                          : null}
                      </TableCell>
                    ))}
                  </TableRow>
                );
              })}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: 57.4 * emptyRows,
                  }}
                >
                  <TableCell colSpan={rows[0]?.cells.length || 6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
      {withPagination && total > rowsPerPage ? (
        <CustomPagination onPageChange={setPage} page={page} perPage={rowsPerPage} total={total} />
      ) : null}
    </Box>
  );
};

export default EnhancedTable;
