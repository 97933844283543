import React from 'react';
import { styled } from '@mui/material';
import EnhancedTable, { CELL_TYPE, IHeadCell } from '../../../../common/EnhancedTable';
import { useTranslation } from 'react-i18next';
import { TGateway, GATEWAYS_SORT_KEYS } from '../../../../types';
import { getFormattedDate, getFormattedTime } from '../../../../helpers';
import useAccessControl from '../../../../hooks/useAccessControl';

/* ------- Styles ------- */
const ListContainer = styled('div')({
  marginTop: '24px',
});

/* ------- Types ------- */
interface ICompanyGatewaysListProps {
  list: TGateway[] | null;
  onOpenDetails: (id: string) => void;
  onNetworkConfigurationOpen: (gateway: TGateway) => void;
  onMachineConfigurationOpen: (gateway: TGateway) => void;
  onMachineListOpen: (gateway: TGateway) => void;
  onDecommissionOpen: (gateway: TGateway) => void;
}

/* ------- Components ------- */
const CompanyGatewaysList: React.FC<ICompanyGatewaysListProps> = ({
  list,
  onOpenDetails,
  onNetworkConfigurationOpen,
  onMachineConfigurationOpen,
  onMachineListOpen,
  onDecommissionOpen,
}) => {
  const { t } = useTranslation();
  const { userAccess } = useAccessControl();

  const contextActions = (gateway: TGateway) => [
    {
      name: t('gateways:contextMenu:update'),
      callback: () => console.log('update'),
      disabled: true,
      permitted: userAccess.create_gateway_allowed,
    },
    {
      name: t('gateways:contextMenu:editGateway'),
      callback: () => onOpenDetails(gateway.id),
      permitted: userAccess.create_gateway_allowed,
    },
    {
      name: t('gateways:contextMenu:networkConf'),
      callback: () => onNetworkConfigurationOpen(gateway),
      permitted: userAccess.create_gateway_allowed,
    },
    {
      name: t('gateways:contextMenu:machineConf'),
      callback: () => onMachineConfigurationOpen(gateway),
      permitted: userAccess.create_gateway_allowed,
    },
    {
      name: t('gateways:contextMenu:machineList'),
      callback: () => onMachineListOpen(gateway),
      permitted: userAccess.view_gateways_allowed,
    },
    {
      name: t('gateways:contextMenu:decommission'),
      callback: () => onDecommissionOpen(gateway),
      permitted: userAccess.reset_gateway_allowed,
    },
    {
      name: t('gateways:contextMenu:factoryReset'),
      callback: () => console.log('factoryReset'),
      disabled: true,
      permitted: userAccess.reset_gateway_allowed,
    },
  ];

  const rows = list?.map((gateway) => {
    return {
      id: gateway.id,
      cells: [
        {
          key: GATEWAYS_SORT_KEYS.SERIAL_NO,
          value: gateway.serialNumber,
          type: CELL_TYPE.FILED,
        },
        {
          key: GATEWAYS_SORT_KEYS.INSTALLATION_AREA,
          value: gateway.installationArea,
          type: CELL_TYPE.FILED,
          width: '200px',
        },
        {
          key: GATEWAYS_SORT_KEYS.APPLICATION_CATEGORY,
          value: gateway.applicationCategory,
          type: CELL_TYPE.FILED,
          width: '200px',
        },
        {
          key: GATEWAYS_SORT_KEYS.DATE_CREATED,
          value: gateway.dateCreated,
          displayValue: `${getFormattedDate(gateway.dateCreated)} ${getFormattedTime(gateway.dateCreated)}`,
          type: CELL_TYPE.FILED,
          width: '200px',
        },
        {
          key: GATEWAYS_SORT_KEYS.INTERNET_STATUS,
          value: gateway.internetStatus || 'n/a',
          type: CELL_TYPE.FILED,
          width: '200px',
        },
        {
          key: GATEWAYS_SORT_KEYS.MACHINE_STATUS,
          value: gateway.machineStatus || 'n/a',
          type: CELL_TYPE.FILED,
          width: '196px',
        },
        {
          key: 'actions',
          type: CELL_TYPE.MENU,
          actions: contextActions(gateway),
        },
      ],
    };
  });

  const headCells: IHeadCell[] = [
    {
      id: 'serialNo',
      numeric: false,
      disablePadding: false,
      label: 'Serial no.',
      sortable: true,
      sortKey: GATEWAYS_SORT_KEYS.SERIAL_NO,
    },
    {
      id: 'installationArea',
      numeric: false,
      disablePadding: false,
      label: 'Installation Area',
      sortable: true,
      sortKey: GATEWAYS_SORT_KEYS.INSTALLATION_AREA,
    },
    {
      id: 'applicationCategory',
      numeric: false,
      disablePadding: false,
      label: 'Application Category',
      sortable: true,
      sortKey: GATEWAYS_SORT_KEYS.APPLICATION_CATEGORY,
    },
    {
      id: 'date',
      numeric: false,
      disablePadding: false,
      label: 'Date created',
      sortable: true,
      sortKey: GATEWAYS_SORT_KEYS.DATE_CREATED,
    },
    {
      id: 'internetStatus',
      numeric: false,
      disablePadding: false,
      label: 'Internet Status',
      sortable: true,
      sortKey: GATEWAYS_SORT_KEYS.INTERNET_STATUS,
    },
    {
      id: 'machineStatus',
      numeric: false,
      disablePadding: false,
      label: 'Machine Status',
      sortable: true,
      sortKey: GATEWAYS_SORT_KEYS.MACHINE_STATUS,
    },
    {
      id: 'actions',
      numeric: false,
      disablePadding: false,
      label: 'Actions',
      sortable: false,
    },
  ];

  return (
    <ListContainer>
      <EnhancedTable rows={rows || []} headRow={headCells} />
    </ListContainer>
  );
};

export default CompanyGatewaysList;
