import { takeLatest, call, put, select } from 'redux-saga/effects';
import apiClient from '../services/apiClient';
import {
  checkSerialNumberFailed,
  checkSerialNumberPending,
  checkSerialNumberSuccess,
  createGatewayFailed,
  createGatewayPending,
  createGatewaySuccess,
  decommissionGatewayPending,
  decommissionGatewaySuccess,
  fetchFailed,
  fetchGatewayCompaniesListPending,
  fetchGatewayCompaniesListSuccess,
  fetchGatewayDetailsPending,
  fetchGatewayDetailsSuccess,
  fetchGatewaysListPending,
  fetchGatewaysListSuccess,
  fetchMachineConfigurationPending,
  fetchMachineConfigurationSuccess,
  fetchMachineListPending,
  fetchMachineListSuccess,
  fetchNetworkConfigurationPending,
  fetchNetworkConfigurationSuccess,
  fetchPackageListPending,
  fetchPackageListSuccess,
  setGatewaysListFilterPending,
  setGatewaysListFilterSuccess,
  setGatewaysListPaginationPending,
  setGatewaysListPaginationSuccess,
  setGatewaysListSortingPending,
  setGatewaysListSortingSuccess,
  updateGatewayFailed,
  updateGatewayPending,
  updateGatewaySuccess,
  updateMachineConfigurationFailed,
  updateMachineConfigurationPending,
  updateMachineConfigurationSuccess,
  updateNetworkConfigurationFailed,
  updateNetworkConfigurationPending,
  updateNetworkConfigurationSuccess,
} from '../store/slices/gateways';
import { selectGatewaysFilter, selectGatewaysPagination, selectGatewaysSorting } from '../store/selectors/gateways';
import { TGateway, TMachineConfiguration } from '../types';
import { fetchCompanyGatewaysPending } from '../store/slices/companies';
import { TEMP_ID } from '../scenes/Gateways/MachineConfiguration';
import { serialNumberCheckURI } from '../constants';
import { selectCompany } from '../store/selectors/companies';

function* fetchGatewaysListSaga() {
  try {
    const pagination = yield select(selectGatewaysPagination);
    const sorting = yield select(selectGatewaysSorting);
    const { filters, isFiltered } = yield select(selectGatewaysFilter);

    const response = yield call(apiClient.post, `/Gateways/get`, {
      page: pagination,
      orderBy: sorting,
      ...(isFiltered && { filterGroups: Object.values(filters).filter((group) => !!group.filters[0]?.value.length) }),
    });

    yield put(
      fetchGatewaysListSuccess({
        list: response.data,
        pagination: {
          pageNumber: response.pageNumber,
          pageSize: response.pageSize,
          totalCount: response.totalCount,
        },
      }),
    );
  } catch (error) {
    yield put(
      fetchFailed({
        error,
      }),
    );
  }
}

function* setGatewaysListPaginationSaga({ payload }) {
  try {
    yield put(
      setGatewaysListPaginationSuccess({
        pageNumber: payload.pageNumber,
        pageSize: payload.pageSize,
        totalCount: payload.totalCount,
      }),
    );
    yield put(fetchGatewaysListPending());
  } catch (error) {
    yield put(
      fetchFailed({
        error,
      }),
    );
  }
}

function* setGatewaysListSortingSaga({ payload }) {
  try {
    yield put(setGatewaysListSortingSuccess(payload));
    yield put(fetchGatewaysListPending());
  } catch (error) {
    yield put(
      fetchFailed({
        error,
      }),
    );
  }
}

function* setGatewaysListFilterSaga({ payload }) {
  try {
    yield put(setGatewaysListFilterSuccess(payload));
    yield put(fetchGatewaysListPending());
  } catch (error) {
    yield put(
      fetchFailed({
        error,
      }),
    );
  }
}

function* fetchGatewayCompaniesListSaga() {
  try {
    const sorting = yield select(selectGatewaysSorting);
    const response = yield call(apiClient.post, `/Gateways/get`, {
      page: {
        pageNumber: 1,
        pageSize: 1000,
        totalCount: 0,
      },
      orderBy: sorting,
    });
    const uniqCompanyNames = [...new Set(response.data.map((gateway: TGateway) => gateway.companyName))];
    yield put(fetchGatewayCompaniesListSuccess(uniqCompanyNames as TGateway['companyName'][]));
  } catch (error) {
    yield put(
      fetchFailed({
        error,
      }),
    );
  }
}

function* createGatewaySaga({ payload }) {
  try {
    const response = yield call(apiClient.post, '/Gateways', payload);

    if (response) {
      yield put(fetchGatewayDetailsSuccess(response));
      yield put(createGatewaySuccess());
      yield put(fetchGatewaysListPending());
      yield put(fetchGatewayCompaniesListPending());
      yield put(fetchCompanyGatewaysPending({ companyId: payload.companyId }));
    }
  } catch (error) {
    yield put(
      createGatewayFailed({
        error,
      }),
    );
  }
}

function* updateGatewaySaga({ payload }) {
  try {
    const response = yield call(apiClient.put, `/Gateways/${payload.gatewayId}`, {
      installationAreaId: payload.installationAreaId,
      serviceTechnicianId: payload.serviceTechnicianId,
    });

    if (response) {
      yield put(updateGatewaySuccess());
      yield put(fetchGatewayDetailsSuccess(response));
      yield put(fetchGatewaysListPending());
      yield put(fetchCompanyGatewaysPending({ companyId: payload.companyId }));
    }
  } catch (error) {
    yield put(
      updateGatewayFailed({
        error,
      }),
    );
  }
}

function* fetchGatewayDetailsSaga({ payload }) {
  try {
    const response = yield call(apiClient.get, `/Gateways/${payload.gatewayId}`);

    if (response) {
      yield put(fetchGatewayDetailsSuccess(response));
    }
  } catch (error) {
    yield put(
      fetchFailed({
        error,
      }),
    );
  }
}

function* fetchNetworkConfigurationSaga({ payload }) {
  try {
    const response = yield call(apiClient.get, `/NetworkConfiguration/${payload.gatewayId}`);

    if (response) {
      yield put(fetchNetworkConfigurationSuccess(response));
    }
  } catch (error) {
    yield put(
      fetchFailed({
        error,
      }),
    );
  }
}

function* fetchMachineConfigurationSaga({ payload }) {
  try {
    const response = yield call(apiClient.get, `/Gateways/${payload.gatewayId}/machines/configuration`);
    if (response.machineConfigurations) {
      yield put(fetchMachineConfigurationSuccess(response.machineConfigurations));
    }
  } catch (error) {
    yield put(
      fetchFailed({
        error,
      }),
    );
  }
}

function* updateNetworkConfigurationSaga({ payload }) {
  try {
    const response = yield call(apiClient.post, '/NetworkConfiguration/', payload);

    if (response) {
      yield put(updateNetworkConfigurationSuccess(response.machines));
    }
  } catch (error) {
    yield put(
      updateNetworkConfigurationFailed({
        error,
      }),
    );
  }
}

function* updateMachineConfigurationSaga({
  payload,
}: {
  payload: { machines: TMachineConfiguration; gatewayId: string };
}) {
  try {
    const mappedMachines = payload.machines.map((machine) => ({
      machineTypeId: machine.machineTypeId,
      name: machine.name,
      serialNumber: machine.serialNumber,
      ipAddress: machine.ipAddress,
      port: machine.port,
      schema: machine.schema,
      credentialsChanged: machine.credentialsChanged,
      overrideAuthentication: machine.overrideAuthentication,
      ...(machine.machineId !== TEMP_ID ? { machineId: machine.machineId } : {}),
      ...(machine.overrideAuthentication ? { userName: machine.userName, password: machine.password } : {}),
    }));
    const response = yield call(apiClient.put, `/Gateways/${payload.gatewayId}/machines/configuration`, mappedMachines);

    if (response?.machineConfigurations) {
      yield put(updateMachineConfigurationSuccess(response.machineConfigurations));
    }
  } catch (error) {
    yield put(
      updateMachineConfigurationFailed({
        error,
      }),
    );
  }
}

function* fetchMachineListSaga({ payload }) {
  try {
    const response = yield call(apiClient.get, `/Gateways/${payload.gatewayId}/machines`);

    if (response) {
      yield put(fetchMachineListSuccess(response.machines));
    }
  } catch (error) {
    yield put(
      fetchFailed({
        error,
      }),
    );
  }
}

function* checkSerialNumberSaga({ payload }) {
  try {
    const response = yield call(apiClient.get, serialNumberCheckURI, { serialNumber: payload.serialNumber });

    if (response !== undefined) {
      yield put(checkSerialNumberSuccess(response));
    }
  } catch (error) {
    yield put(
      checkSerialNumberFailed({
        error,
      }),
    );
  }
}

function* decommissionGatewaySaga({ payload }) {
  try {
    const company = yield select(selectCompany);
    const response = yield call(apiClient.delete, '/Gateways', payload.gatewayId);

    if (response) {
      yield put(decommissionGatewaySuccess());
      yield put(fetchGatewaysListPending());
      if (company) yield put(fetchCompanyGatewaysPending({ companyId: company.id }));
    }
  } catch (error) {
    yield put(
      fetchFailed({
        error,
      }),
    );
  }
}

function* fetchPackageListSaga() {
  try {
    const response = yield call(apiClient.get, `/SoftwarePackages`);

    if (response) {
      yield put(fetchPackageListSuccess(response));
    }
  } catch (error) {
    yield put(
      fetchFailed({
        error,
      }),
    );
  }
}

export default function* watch() {
  yield takeLatest(fetchGatewaysListPending, fetchGatewaysListSaga);
  yield takeLatest(setGatewaysListPaginationPending, setGatewaysListPaginationSaga);
  yield takeLatest(setGatewaysListSortingPending, setGatewaysListSortingSaga);
  yield takeLatest(setGatewaysListFilterPending, setGatewaysListFilterSaga);
  yield takeLatest(fetchGatewayCompaniesListPending, fetchGatewayCompaniesListSaga);
  yield takeLatest(createGatewayPending, createGatewaySaga);
  yield takeLatest(updateGatewayPending, updateGatewaySaga);
  yield takeLatest(fetchGatewayDetailsPending, fetchGatewayDetailsSaga);
  yield takeLatest(fetchNetworkConfigurationPending, fetchNetworkConfigurationSaga);
  yield takeLatest(fetchMachineConfigurationPending, fetchMachineConfigurationSaga);
  yield takeLatest(updateNetworkConfigurationPending, updateNetworkConfigurationSaga);
  yield takeLatest(updateMachineConfigurationPending, updateMachineConfigurationSaga);
  yield takeLatest(fetchMachineListPending, fetchMachineListSaga);
  yield takeLatest(checkSerialNumberPending, checkSerialNumberSaga);
  yield takeLatest(decommissionGatewayPending, decommissionGatewaySaga);
  yield takeLatest(fetchPackageListPending, fetchPackageListSaga);
}
