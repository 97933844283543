import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';

interface IAlertDialogProps {
  open: boolean;
  onConfirm: () => void;
  dialogTitle: string | React.ReactNode;
  dialogText: string | React.ReactNode;
}

const AlertDialog: React.FC<IAlertDialogProps> = ({ open, onConfirm, dialogTitle, dialogText }) => {
  const { t } = useTranslation();

  return (
    <Dialog open={open}>
      <DialogTitle
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          fontSize: '24px',
          fontWeight: '700',
          color: (theme) => theme.palette.secondary.main,
        }}
      >
        {dialogTitle}
      </DialogTitle>
      <DialogContent>
        <DialogContentText sx={{ color: (theme) => theme.palette.black[100] }}>{dialogText}</DialogContentText>
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'center', gap: '24px', padding: '8px 24px 24px' }}>
        <Button variant='contained' onClick={onConfirm} sx={{ minWidth: '192px' }}>
          {t('common:ok')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AlertDialog;
