import { createSlice } from '@reduxjs/toolkit';
import {
  FILTER_OPERATOR,
  GATEWAYS_FILTER_KEYS,
  GATEWAYS_SORT_KEYS,
  SORT_ORDER,
  TGateway,
  TGatewayDetails,
  TGatewayResponseDetails,
  TGatewaysSortKey,
  TGatewaysSortOrder,
  TMachine,
  TMachineConfiguration,
  TNetworkConfiguration,
  TNetworkConfigurationRequest,
  TSoftwarePackage,
} from '../../types';
import { listPageSize } from '../../constants';
import { ApiError } from '../../services/apiClient/apiError';

/*-----------------TYPES-----------------*/
type TPayloadError = {
  error: ApiError;
};

type TGatewaysPagination = {
  pageNumber: number;
  pageSize: number;
  totalCount: number;
};

type TGatewaysSorting = {
  columnName: TGatewaysSortKey;
  direction: TGatewaysSortOrder;
};

type TGatewaysFilter = {
  isFiltered: boolean;
  filterKey: string;
  filterValue: TFilter[];
};

type TFetchGatewaysList = {
  list: TGateway[];
  pagination: TGatewaysPagination;
};

type TFilter = {
  operator: FILTER_OPERATOR;
  value: string;
};

export interface IGateways {
  list: TGateway[];
  gatewayDetails: TGatewayResponseDetails | null;
  pagination: TGatewaysPagination;
  sorting: {
    columnName: TGatewaysSortKey;
    direction: TGatewaysSortOrder;
  };
  filterGroups: {
    [GATEWAYS_FILTER_KEYS.COMPANY_MANE]: {
      columnName: string;
      filters: TFilter[];
    };
    [GATEWAYS_FILTER_KEYS.INTERNET_STATUS]: {
      columnName: string;
      filters: TFilter[];
    };
    [GATEWAYS_FILTER_KEYS.MACHINE_STATUS]: {
      columnName: string;
      filters: TFilter[];
    };
  };
  isFiltered: boolean;
  isSerialNumberValid: boolean | undefined;
  gatewayCompaniesList: TGateway['companyName'][];
  machineList: TMachine[];
  packageList: TSoftwarePackage[];
  networkConfiguration: TNetworkConfiguration | null;
  machineConfiguration: TMachineConfiguration | null;
  flags: {
    created: boolean;
    updated: boolean;
    deleted: boolean;
    network_configured: boolean;
    machine_configuration_updated: boolean;
    decommissioned: boolean;
  };
  error: ApiError | null;
}

/*-----------------INITIAL STATE-----------------*/
export const initialState: IGateways = {
  list: [],
  gatewayDetails: null,
  pagination: {
    pageNumber: 1,
    pageSize: listPageSize,
    totalCount: 0,
  },
  sorting: {
    columnName: GATEWAYS_SORT_KEYS.SERIAL_NO,
    direction: SORT_ORDER.ASCENDING,
  },
  filterGroups: {
    [GATEWAYS_FILTER_KEYS.COMPANY_MANE]: {
      columnName: GATEWAYS_FILTER_KEYS.COMPANY_MANE,
      filters: [
        {
          operator: FILTER_OPERATOR.EQUALS,
          value: '',
        },
      ],
    },
    [GATEWAYS_FILTER_KEYS.INTERNET_STATUS]: {
      columnName: GATEWAYS_FILTER_KEYS.INTERNET_STATUS,
      filters: [
        {
          operator: FILTER_OPERATOR.EQUALS,
          value: '',
        },
      ],
    },
    [GATEWAYS_FILTER_KEYS.MACHINE_STATUS]: {
      columnName: GATEWAYS_FILTER_KEYS.MACHINE_STATUS,
      filters: [
        {
          operator: FILTER_OPERATOR.EQUALS,
          value: '',
        },
      ],
    },
  },
  gatewayCompaniesList: [],
  machineList: [],
  packageList: [],
  networkConfiguration: null,
  machineConfiguration: null,
  isFiltered: false,
  isSerialNumberValid: undefined,
  flags: {
    created: false,
    updated: false,
    deleted: false,
    network_configured: false,
    machine_configuration_updated: false,
    decommissioned: false,
  },
  error: null,
};

/*-----------------SLICE-----------------*/
const gatewaysSlice = createSlice({
  name: 'gateways',
  initialState,
  reducers: {
    /*-----------------Pending Actions/Reducers-----------------*/
    fetchGatewaysListPending: (state) => state,
    setGatewaysListPaginationPending: (
      state,
      _action: {
        payload: TFetchGatewaysList['pagination'];
      },
    ) => state,
    setGatewaysListSortingPending: (
      state,
      _action: {
        payload: TGatewaysSorting;
      },
    ) => state,
    setGatewaysListFilterPending: (
      state,
      _action: {
        payload: TGatewaysFilter;
      },
    ) => state,
    fetchGatewayCompaniesListPending: (state) => state,
    createGatewayPending: (
      state,
      _action: {
        payload: TGatewayDetails;
      },
    ) => state,
    updateGatewayPending: (
      state,
      _action: {
        payload: TGatewayDetails;
      },
    ) => state,
    fetchGatewayDetailsPending: (
      state,
      _action: {
        payload: { gatewayId: string };
      },
    ) => state,
    fetchNetworkConfigurationPending: (
      state,
      _action: {
        payload: { gatewayId: string };
      },
    ) => state,
    fetchMachineConfigurationPending: (
      state,
      _action: {
        payload: { gatewayId: string };
      },
    ) => state,
    updateNetworkConfigurationPending: (
      state,
      _action: {
        payload: TNetworkConfigurationRequest;
      },
    ) => state,
    updateMachineConfigurationPending: (
      state,
      _action: {
        payload: { gatewayId: string; machines: TMachineConfiguration };
      },
    ) => state,
    fetchMachineListPending: (
      state,
      _action: {
        payload: { gatewayId: string };
      },
    ) => state,
    checkSerialNumberPending: (
      state,
      _action: {
        payload: { serialNumber: string };
      },
    ) => state,
    decommissionGatewayPending: (
      state,
      _action: {
        payload: { gatewayId: string };
      },
    ) => state,
    fetchPackageListPending: (state) => state,

    /*-----------------Success Actions/Reducers-----------------*/
    fetchGatewaysListSuccess: (state, { payload }: { payload: TFetchGatewaysList }) => {
      return {
        ...state,
        list: payload.list,
        pagination: payload.pagination,
      };
    },
    setGatewaysListPaginationSuccess: (state, { payload }: { payload: TFetchGatewaysList['pagination'] }) => {
      return {
        ...state,
        pagination: payload,
      };
    },
    setGatewaysListSortingSuccess: (state, { payload }: { payload: TGatewaysSorting }) => {
      return {
        ...state,
        sorting: payload,
      };
    },
    setGatewaysListFilterSuccess: (state, { payload }: { payload: TGatewaysFilter }) => {
      return {
        ...state,
        pagination: {
          ...state.pagination,
          pageNumber: 1,
        },
        filterGroups: {
          ...state.filterGroups,
          [payload.filterKey]: { ...state.filterGroups[payload.filterKey], filters: payload.filterValue },
        },
        isFiltered: payload.isFiltered,
      };
    },
    fetchGatewayCompaniesListSuccess: (state, { payload }: { payload: TGateway['companyName'][] }) => {
      return {
        ...state,
        gatewayCompaniesList: payload,
      };
    },
    fetchGatewayDetailsSuccess: (state, { payload }: { payload: TGatewayResponseDetails | null }) => {
      return {
        ...state,
        gatewayDetails: payload,
      };
    },
    createGatewaySuccess: (state) => {
      return {
        ...state,
        flags: {
          ...state.flags,
          created: true,
        },
      };
    },
    updateGatewaySuccess: (state) => {
      return {
        ...state,
        flags: {
          ...state.flags,
          updated: true,
        },
      };
    },
    fetchNetworkConfigurationSuccess: (state, { payload }: { payload: TNetworkConfiguration }) => {
      return {
        ...state,
        networkConfiguration: payload,
      };
    },
    fetchMachineConfigurationSuccess: (state, { payload }: { payload: TMachineConfiguration }) => {
      return {
        ...state,
        machineConfiguration: payload,
      };
    },
    updateNetworkConfigurationSuccess: (state) => {
      return {
        ...state,
        networkConfiguration: null,
        flags: {
          ...state.flags,
          network_configured: true,
        },
      };
    },
    updateMachineConfigurationSuccess: (state, { payload }: { payload: TMachineConfiguration }) => {
      return {
        ...state,
        machineConfiguration: payload,
        flags: {
          ...state.flags,
          machine_configuration_updated: true,
        },
      };
    },
    fetchMachineListSuccess: (state, { payload }: { payload: TMachine[] }) => {
      return {
        ...state,
        machineList: payload,
      };
    },
    checkSerialNumberSuccess: (state, { payload }: { payload: { canBeRegistered: boolean | undefined } }) => {
      return {
        ...state,
        isSerialNumberValid: payload.canBeRegistered,
      };
    },
    resetGatewayFlags: (state) => {
      return {
        ...state,
        flags: initialState.flags,
      };
    },
    resetGatewaysReducer: (_state) => {
      return {
        ...initialState,
      };
    },
    decommissionGatewaySuccess: (state) => {
      return {
        ...state,
        flags: {
          ...state.flags,
          decommissioned: true,
        },
      };
    },
    fetchPackageListSuccess: (state, { payload }: { payload: TSoftwarePackage[] }) => {
      return {
        ...state,
        packageList: payload,
      };
    },

    /*-----------------Failed Actions/Reducers-----------------*/
    fetchFailed: (state, { payload }: { payload: TPayloadError }) => {
      return {
        ...state,
        error: payload.error,
      };
    },
    createGatewayFailed: (state, { payload }: { payload: TPayloadError }) => {
      return {
        ...state,
        error: payload.error,
        flags: {
          ...state.flags,
          created: false,
        },
      };
    },
    updateGatewayFailed: (state, { payload }: { payload: TPayloadError }) => {
      return {
        ...state,
        error: payload.error,
        flags: {
          ...state.flags,
          updated: false,
        },
      };
    },
    updateNetworkConfigurationFailed: (state, { payload }: { payload: TPayloadError }) => {
      return {
        ...state,
        error: payload.error,
        flags: {
          ...state.flags,
          network_configured: false,
        },
      };
    },
    updateMachineConfigurationFailed: (state, { payload }: { payload: TPayloadError }) => {
      return {
        ...state,
        error: payload.error,
        flags: {
          ...state.flags,
          machine_configuration_updated: false,
        },
      };
    },
    checkSerialNumberFailed: (state, { payload }: { payload: TPayloadError }) => {
      return {
        ...state,
        error: payload.error,
        isSerialNumberValid: undefined,
      };
    },
  },
});

export const {
  fetchGatewaysListPending,
  setGatewaysListPaginationPending,
  setGatewaysListSortingPending,
  setGatewaysListFilterPending,
  fetchGatewayCompaniesListPending,
  createGatewayPending,
  updateGatewayPending,
  fetchGatewayDetailsPending,
  fetchNetworkConfigurationPending,
  fetchMachineConfigurationPending,
  updateNetworkConfigurationPending,
  updateMachineConfigurationPending,
  fetchMachineListPending,
  checkSerialNumberPending,
  decommissionGatewayPending,
  fetchPackageListPending,
  fetchGatewaysListSuccess,
  setGatewaysListPaginationSuccess,
  setGatewaysListSortingSuccess,
  setGatewaysListFilterSuccess,
  fetchGatewayCompaniesListSuccess,
  fetchGatewayDetailsSuccess,
  createGatewaySuccess,
  updateGatewaySuccess,
  fetchNetworkConfigurationSuccess,
  fetchMachineConfigurationSuccess,
  updateNetworkConfigurationSuccess,
  updateMachineConfigurationSuccess,
  fetchMachineListSuccess,
  checkSerialNumberSuccess,
  decommissionGatewaySuccess,
  fetchPackageListSuccess,
  resetGatewayFlags,
  resetGatewaysReducer,
  fetchFailed,
  createGatewayFailed,
  updateGatewayFailed,
  updateNetworkConfigurationFailed,
  updateMachineConfigurationFailed,
  checkSerialNumberFailed,
} = gatewaysSlice.actions;

export default gatewaysSlice.reducer;
