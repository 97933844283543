import common from './common.json';
import user from './user.json';
import companies from './companies.json';
import gateways from './gateways.json';

const localesEn = {
  common,
  user,
  companies,
  gateways,
};

export default localesEn;
