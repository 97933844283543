import React from 'react';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { useNavigate } from 'react-router-dom';

/* ------- Types ------- */
interface ISegment {
  href?: string;
  onClick?: () => void;
  text: string;
}
interface ICustomBreadcrumbsProps {
  segments: ISegment[];
}

/* ------- Components ------- */
const CustomBreadcrumbs: React.FC<ICustomBreadcrumbsProps> = ({ segments }) => {
  const navigate = useNavigate();

  const handleClick = (segment: ISegment) => {
    if (segment.onClick) return segment.onClick();
    if (segment.href) return navigate(segment.href);

    return;
  };

  const breadcrumbs = segments.map((segment) =>
    segment.href || segment.onClick ? (
      <Link
        sx={{ cursor: 'pointer' }}
        underline='hover'
        key={segment.text}
        color='text.primary'
        onClick={() => handleClick(segment)}
      >
        {segment.text}
      </Link>
    ) : (
      <Typography sx={{ fontWeight: 700 }} key={segment.text} color='primary'>
        {segment.text}
      </Typography>
    ),
  );

  return (
    <Stack spacing={2}>
      <Breadcrumbs separator={<NavigateNextIcon fontSize='small' />} aria-label='breadcrumb'>
        {breadcrumbs}
      </Breadcrumbs>
    </Stack>
  );
};

export default CustomBreadcrumbs;
