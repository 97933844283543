import React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { sortStrings } from '../../helpers';

/* ------- Types ------- */
export interface IAutocompleteFilter {
  title: string;
  id: string;
}
interface IFilterAutocompleteProps {
  value: IAutocompleteFilter[];
  onValueChange: (newValue: IAutocompleteFilter[]) => void;
  options: IAutocompleteFilter[];
  label: string;
  disabled?: boolean;
}
/* ------- Components ------- */
const FilterAutocomplete: React.FC<IFilterAutocompleteProps> = ({ value, onValueChange, options, label, disabled }) => {
  const mappedSelected = value.map((val) => val.id);
  const filterSelectedOptions = options.filter((opt) => !mappedSelected.includes(opt.id));

  return (
    <Autocomplete
      multiple
      value={value}
      onChange={(_, newValue) => {
        onValueChange(newValue);
      }}
      size='small'
      disabled={disabled}
      options={sortStrings(filterSelectedOptions, 'title')}
      getOptionLabel={(option) => option.title}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      renderOption={(props, option) => (
        <li key={option.id} {...props}>
          {option.title}
        </li>
      )}
      sx={{ minWidth: '220px' }}
      renderInput={(params) => {
        return <TextField {...params} label={label} fullWidth />;
      }}
    />
  );
};

export default FilterAutocomplete;
