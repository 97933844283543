import React, { useState } from 'react';
import { styled } from '@mui/material';
import EnhancedTable, { CELL_TYPE, IHeadCell } from '../../../../common/EnhancedTable';
import { useDispatch } from '../../../../hooks/redux';
import { deleteInstallationAreaPending, updateInstallationAreaPending } from '../../../../store/slices/companies';
import { Trans, useTranslation } from 'react-i18next';
import { TInstallationArea } from '../../../../types';
import { DEFAULT_INSTALLATION_AREA_NAME } from '../../../../constants';
import { ACTION_TYPE } from '../../../../common/ContextMenu';
import DeleteDialog from '../../../../common/DeleteDialog';
import useAccessControl from '../../../../hooks/useAccessControl';

/* ------- Styles ------- */
const ListContainer = styled('div')({
  marginTop: '24px',
});

/* ------- Types ------- */
interface IAreaListProps {
  list: TInstallationArea[] | null;
}

/* ------- Components ------- */
const AreasList: React.FC<IAreaListProps> = ({ list }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { userAccess } = useAccessControl();

  const [inEdit, setInEdit] = useState<string | undefined>(undefined);
  const [itemToDelete, setItemToDelete] = useState<string | undefined>(undefined);

  const handleEditArea = (areaId) => (areaName) => {
    dispatch(updateInstallationAreaPending({ installationAreaId: areaId, name: areaName }));
    setInEdit(undefined);
  };

  const handleDeleteArea = () => {
    if (itemToDelete) {
      dispatch(deleteInstallationAreaPending({ installationAreaId: itemToDelete }));
      setItemToDelete(undefined);
    }
  };

  const actions = (area) => [
    ...(area.installationAreaId === inEdit
      ? [
          {
            name: t('companies:installationArea:actions:save'),
            type: ACTION_TYPE.SAVE,
            callback: handleEditArea(area.installationAreaId),
            permitted: true,
          },
          {
            name: t('companies:installationArea:actions:cancel'),
            type: ACTION_TYPE.CANCEL,
            callback: () => setInEdit(undefined),
            permitted: true,
          },
        ]
      : [
          {
            name: t('companies:installationArea:actions:rename'),
            callback: () => setInEdit(area.installationAreaId),
            type: ACTION_TYPE.EDIT,
            disabled: !userAccess.create_installation_area_allowed || area.name === DEFAULT_INSTALLATION_AREA_NAME,
            permitted: true,
          },
        ]),
    {
      name: t('companies:installationArea:actions:delete'),
      callback: () => setItemToDelete(area.installationAreaId),
      disabled: !userAccess.create_installation_area_allowed || area.name === DEFAULT_INSTALLATION_AREA_NAME,
      permitted: true,
    },
  ];

  const rows = list?.map((area) => {
    return {
      id: area.installationAreaId,
      cells: [
        {
          key: 'name',
          value: area.name,
          type: CELL_TYPE.FILED,
          width: '554px',
          edit: area.installationAreaId === inEdit,
        },
        {
          key: 'gateways',
          value: area.gateways || 'n/a',
          width: '554px',
          type: CELL_TYPE.FILED,
        },
        {
          key: 'actions',
          type: CELL_TYPE.ACTION,
          actions: actions(area),
          width: '340px',
        },
      ],
    };
  });

  const headCells: IHeadCell[] = [
    {
      id: 'installationArea',
      numeric: false,
      disablePadding: false,
      label: 'Installation Area',
      sortable: true,
      sortKey: 'name',
    },
    {
      id: 'gateways',
      numeric: false,
      disablePadding: false,
      label: 'Gateways',
      sortable: true,
      sortKey: 'gateways',
    },
    {
      id: 'actions',
      numeric: false,
      disablePadding: false,
      label: 'Actions',
      sortable: false,
    },
  ];

  return (
    <>
      <ListContainer>
        <EnhancedTable rows={rows || []} headRow={headCells} />
      </ListContainer>
      <DeleteDialog
        open={!!itemToDelete}
        onConfirm={handleDeleteArea}
        onClose={() => setItemToDelete(undefined)}
        dialogTitle={t('companies:deleteInstallationArea:title')}
        dialogText={
          <Trans
            i18nKey='companies:deleteInstallationArea:text'
            values={{
              lineBreak: '<br />',
              areaName: `<strong>${list?.find((item) => item.installationAreaId === itemToDelete)?.name}</strong>`,
            }}
          />
        }
      />
    </>
  );
};

export default AreasList;
