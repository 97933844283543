import { createSelector } from 'reselect';
import { RootState } from '..';

const gateways = (state: RootState) => state.gateways;

export const selectGatewaysList = createSelector(gateways, (gateways) => gateways.list);
export const selectGatewaysPagination = createSelector(gateways, (gateways) => gateways.pagination);
export const selectGatewaysSorting = createSelector(gateways, (gateways) => gateways.sorting);
export const selectGatewaysFilter = createSelector(gateways, (gateways) => ({
  filters: gateways.filterGroups,
  isFiltered: gateways.isFiltered,
}));
export const selectGatewayCompaniesList = createSelector(gateways, (gateways) => gateways.gatewayCompaniesList);
export const selectGatewayFlags = createSelector(gateways, (gateways) => gateways.flags);
export const selectGatewayDetails = createSelector(gateways, (gateways) => gateways.gatewayDetails);
export const selectNetworkConfiguration = createSelector(gateways, (gateways) => gateways.networkConfiguration);
export const selectMachineConfiguration = createSelector(gateways, (gateways) => gateways.machineConfiguration);
export const selectGatewayMachineList = createSelector(gateways, (gateways) => gateways.machineList);
export const selectIsSerialNumberValid = createSelector(gateways, (gateways) => gateways.isSerialNumberValid);
export const selectSoftwarePackageList = createSelector(gateways, (gateways) => gateways.packageList);
