import Axios from './apiClient.config';

const apiClient = {
  get(url: string, params?: object): Promise<object> {
    return Axios.get(url, { params }).then((response) => {
      if (response?.status >= 200 && response.status < 300) {
        return response.data;
      }
      return response;
    });
  },

  post(url: string, data?: object): Promise<object> {
    return Axios.post(url, data).then((response) => {
      if (response?.status >= 200 && response.status < 300) {
        return response.data;
      }
      return response;
    });
  },

  put(url: string, data?: object): Promise<object> {
    return Axios.put(url, data).then((response) => {
      if (response?.status >= 200 && response.status < 300) {
        return response.data;
      }
      return response;
    });
  },

  delete(url: string, id?: string): Promise<object> {
    return Axios.delete(id ? `${url}/${id}` : url).then((response) => {
      if (response?.status >= 200 && response.status < 300) {
        return response.data;
      }
      return response;
    });
  },
};

export default apiClient;
