import React, { useEffect, useState } from 'react';
import { Box, Button, styled } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';
import CustomBreadcrumbs from '../../../common/CustomBreadcrumbs';
import { accessKeys, routes } from '../../../constants';
import { useDispatch, useSelector } from '../../../hooks/redux';
import { selectGateway } from '../../../store/selectors/companies';
import { useNavigate } from 'react-router-dom';
import {
  fetchMachineConfigurationPending,
  resetGatewayFlags,
  updateMachineConfigurationPending,
} from '../../../store/slices/gateways';
import { selectGatewayFlags, selectMachineConfiguration } from '../../../store/selectors/gateways';
import Machines from './Machines';
import { ArrayElement, MACHINE_INTERNAL_STATE, TMachineConfiguration } from '../../../types';
import { getStringWithIndex, isValidIPString } from '../../../helpers';
import CustomSnackbar from '../../../common/Snackbar';
import DeleteDialog from '../../../common/DeleteDialog';
import useAccessControl from '../../../hooks/useAccessControl';

export const TEMP_ID = 'tempId';

/* ------- Styles ------- */
const MachineContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  padding: '48px 48px 0 48px',
  backgroundColor: '#FBFBFB',
  width: '100%',
});

/* ------- Components ------- */
const MachineConfiguration = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { accessControlRedirect } = useAccessControl();

  const gateway = useSelector(selectGateway);
  const machineConfig = useSelector(selectMachineConfiguration);
  const { machine_configuration_updated } = useSelector(selectGatewayFlags);

  const [machineToDelete, setMachineToDelete] = useState<string | undefined>(undefined);
  const [expanded, setExpanded] = useState<string[]>([]);
  const [configForm, setConfigForm] = useState<{ [key: string]: ArrayElement<TMachineConfiguration> } | null>(null);
  const [formIsValid, setFormIsValid] = useState<boolean>(true);
  const [invalidFields, setInvalidFields] = useState<string[]>([]);

  useEffect(() => {
    if (gateway) {
      dispatch(fetchMachineConfigurationPending({ gatewayId: gateway.id }));
    }
  }, [dispatch, gateway]);

  useEffect(() => {
    if (machineConfig) {
      setConfigForm(
        Object.assign(
          {},
          ...machineConfig.map((machine) => ({
            [machine.machineId]: {
              ...machine,
              credentialsChanged: false,
              internalState: machine.overrideAuthentication
                ? MACHINE_INTERNAL_STATE.EXISTING_AUTH
                : MACHINE_INTERNAL_STATE.EXISTING_NO_AUTH,
            },
          })),
        ),
      );
    }
  }, [machineConfig]);

  useEffect(() => {
    if (!invalidFields.length) {
      setFormIsValid(true);
    } else {
      setFormIsValid(false);
    }
  }, [invalidFields]);

  const handleNotificationClose = () => {
    dispatch(resetGatewayFlags());
  };

  const handleFieldValidation = (machineId: string) => (key: string) => {
    if (configForm?.[machineId][key]?.toString()?.length) {
      setInvalidFields((previousInputs) => [...previousInputs.filter((field) => field !== `${machineId}-${key}`)]);
    } else {
      if (!invalidFields.includes(`${machineId}-${key}`)) {
        setInvalidFields((previousInputs) => [...previousInputs, `${machineId}-${key}`]);
      }
    }
  };

  const handleUpdateForm = (machineId) => (key) => (value) => {
    if (configForm) {
      setConfigForm((previousInputs) => ({
        ...previousInputs,
        [machineId]: {
          ...previousInputs?.[machineId],
          [key]: value,
        },
      }));

      switch (key) {
        case 'overrideAuthentication':
          if (!value) {
            setInvalidFields([
              ...invalidFields.filter(
                (field) => field !== `${machineId}-userName` && field !== `${machineId}-password`,
              ),
            ]);
          }
          return;

        case 'ipAddress':
          if (isValidIPString(value)) {
            setInvalidFields([...invalidFields.filter((field) => field !== `${machineId}-${key}`)]);
          } else {
            if (!invalidFields.includes(`${machineId}-${key}`)) {
              setInvalidFields([...invalidFields, `${machineId}-${key}`]);
            }
          }
          return;
        case 'credentialsChanged':
          if (!value) {
            setInvalidFields([
              ...invalidFields.filter(
                (field) => field !== `${machineId}-userName` && field !== `${machineId}-password`,
              ),
            ]);
          }
          return;

        case 'schema':
          return;

        default:
          if (value?.length) {
            setInvalidFields([...invalidFields.filter((field) => field !== `${machineId}-${key}`)]);
          } else {
            if (!invalidFields.includes(`${machineId}-${key}`)) {
              setInvalidFields([...invalidFields, `${machineId}-${key}`]);
            }
          }
          return;
      }
    }
  };

  const handleAddNew = () => {
    if (configForm && !configForm[TEMP_ID]) {
      const newConfigName = getStringWithIndex(
        Object.values(configForm)?.map((machine) => machine.name),
        'New Machine',
      );

      setConfigForm({
        [TEMP_ID]: {
          machineId: TEMP_ID,
          // @ts-expect-error: need to set null for default
          machineTypeId: null,
          name: newConfigName,
          serialNumber: '',
          ipAddress: '',
          // @ts-expect-error: need to set empty row for default
          port: '',
          schema: null,
          overrideAuthentication: false,
          userName: '',
          password: '',
          credentialsChanged: false,
          internalState: MACHINE_INTERNAL_STATE.NEW,
        },
        ...configForm,
      });
      setInvalidFields([
        ...invalidFields,
        `${TEMP_ID}-machineTypeId`,
        `${TEMP_ID}-ipAddress`,
        `${TEMP_ID}-port`,
        `${TEMP_ID}-serialNumber`,
      ]);
    }
  };

  const handleDelete = () => {
    const machines = configForm;

    if (machines && machineToDelete) {
      delete machines[machineToDelete];

      setConfigForm(machines);
      setInvalidFields([...invalidFields.filter((field) => !field.startsWith(machineToDelete))]);
      setMachineToDelete(undefined);
    }
  };

  const handleSubmit = () => {
    if (!invalidFields.length && configForm && gateway) {
      dispatch(updateMachineConfigurationPending({ gatewayId: gateway.id, machines: [...Object.values(configForm)] }));
    } else {
      setFormIsValid(false);
    }
    setExpanded([]);
  };

  accessControlRedirect([accessKeys.CREATE_GATEWAY_ALLOWED]);

  return (
    <MachineContainer>
      <CustomBreadcrumbs segments={[{ text: 'Gateways', href: routes.GATEWAYS }, { text: 'Machine Configuration' }]} />
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          paddingTop: '48px',
        }}
      >
        <h3 style={{ fontSize: '24px', margin: 0, alignSelf: 'flex-start' }}>
          {`${gateway?.serialNumber || 'n/a'} - ${t('companies:machineConfiguration:title')}`}
        </h3>

        <span>
          <Button
            variant='outlined'
            sx={{ fontSize: '15px', height: '42px', minWidth: '163px' }}
            onClick={() => navigate(-1)}
          >
            {t('companies:machineConfiguration:cancel')}
          </Button>
          <Button
            variant='outlined'
            sx={{ fontSize: '15px', height: '42px', minWidth: '163px', marginLeft: '20px' }}
            onClick={handleAddNew}
          >
            {t('companies:machineConfiguration:addMachine')}
          </Button>
          <Button
            variant='contained'
            sx={{ fontSize: '15px', height: '42px', minWidth: '192px', marginLeft: '20px' }}
            disabled={!gateway || !formIsValid}
            onClick={handleSubmit}
          >
            {t('companies:machineConfiguration:saveConfig')}
          </Button>
        </span>
      </div>
      <Machines
        machines={configForm ? Object.values(configForm) : []}
        onChange={handleUpdateForm}
        onFieldValidation={handleFieldValidation}
        invalidFields={invalidFields}
        expanded={expanded}
        setExpanded={setExpanded}
        onDelete={(id) => setMachineToDelete(id)}
      />
      <DeleteDialog
        open={!!machineToDelete}
        onConfirm={handleDelete}
        onClose={() => setMachineToDelete(undefined)}
        dialogTitle={t('gateways:deleteMachine:title')}
        dialogText={
          <Trans
            i18nKey='gateways:deleteMachine:text'
            values={{
              machineName: `<strong>${configForm?.[machineToDelete || '']?.name || ''}</strong>`,
              lineBreak: '<br />',
            }}
          />
        }
      />
      <CustomSnackbar
        severity='success'
        open={machine_configuration_updated}
        onClose={handleNotificationClose}
        message={`The configuration for ${gateway?.serialNumber} was successfully saved`}
      />
    </MachineContainer>
  );
};

export default MachineConfiguration;
