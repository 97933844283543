import React, { useEffect, useState } from 'react';
import {
  FormControlLabel,
  FormGroup,
  Radio,
  RadioGroup,
  TextField,
  Box,
  Button,
  styled,
  Switch,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import CustomBreadcrumbs from '../../../common/CustomBreadcrumbs';
import { accessKeys, routes } from '../../../constants';
import { useDispatch, useSelector } from '../../../hooks/redux';
import { selectGateway } from '../../../store/selectors/companies';
import { useNavigate } from 'react-router-dom';
import { fetchNetworkConfigurationPending, updateNetworkConfigurationPending } from '../../../store/slices/gateways';
import { TNetworkConfigurationRequest, TNetworkManualConfig } from '../../../types';
import { selectNetworkConfiguration } from '../../../store/selectors/gateways';
import IpMaskInput from '../../../common/IpMaskInput';
import { isValidIPString } from '../../../helpers';
import useAccessControl from '../../../hooks/useAccessControl';

/* ------- Styles ------- */
const NetworkContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  padding: '48px 48px 0 48px',
  backgroundColor: '#FBFBFB',
  width: '100%',
});

const ConfigurationContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  marginTop: '24px',
  gap: '24px',
});

const StyledTextField = styled(TextField)({
  width: '512px',
});

const StyledFormGroup = styled(FormGroup)({
  display: 'flex',
  gap: '24px',
  padding: '16px 0',
});

/* ------- Components ------- */
const NetworkConfiguration = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { accessControlRedirect } = useAccessControl();

  const gateway = useSelector(selectGateway);
  const netConfig = useSelector(selectNetworkConfiguration);

  const [withLocalNetwork, setWithLocalNetwork] = useState<boolean>(!!netConfig?.localNetworkConfiguration);
  const [isInternetAutoConfig, setIsInternetAutoConfig] = useState<boolean>(
    !!netConfig?.internetNetworkConfiguration?.isDhcpConfiguration,
  );
  const [isLocalNetworkAutoConfig, setIsLocalNetworkAutoConfig] = useState<boolean>(
    netConfig?.localNetworkConfiguration ? netConfig.localNetworkConfiguration.isDhcpConfiguration : true,
  );
  const [internetManualConfig, setInternetManualConfig] = useState<TNetworkManualConfig>({
    address: netConfig?.internetNetworkConfiguration.ipAddress || null,
    netmask: netConfig?.internetNetworkConfiguration.netmask || null,
    gateway: netConfig?.internetNetworkConfiguration.gatewayAddress || null,
    dns: netConfig?.internetNetworkConfiguration.dns || null,
  });
  const [localManualConfig, setLocalManualConfig] = useState<TNetworkManualConfig>({
    address: netConfig?.localNetworkConfiguration?.ipAddress || null,
    netmask: netConfig?.localNetworkConfiguration?.netmask || null,
    gateway: netConfig?.localNetworkConfiguration?.gatewayAddress || null,
    dns: netConfig?.localNetworkConfiguration?.dns || null,
  });

  const [formIsValid, setFormIsValid] = useState<boolean>(true);
  const [invalidFields, setInvalidFields] = useState<string[]>([]);

  useEffect(() => {
    if (gateway) {
      dispatch(fetchNetworkConfigurationPending({ gatewayId: gateway.id }));
    }
  }, [dispatch, gateway]);

  useEffect(() => {
    if (netConfig) {
      setWithLocalNetwork(!!netConfig.localNetworkConfiguration);
      setIsInternetAutoConfig(netConfig.internetNetworkConfiguration.isDhcpConfiguration);

      setInternetManualConfig({
        address: netConfig.internetNetworkConfiguration.ipAddress,
        netmask: netConfig.internetNetworkConfiguration.netmask,
        gateway: netConfig.internetNetworkConfiguration.gatewayAddress,
        dns: netConfig.internetNetworkConfiguration.dns,
      });
      if (netConfig.localNetworkConfiguration) {
        setIsLocalNetworkAutoConfig(netConfig.localNetworkConfiguration.isDhcpConfiguration);
        setLocalManualConfig({
          address: netConfig.localNetworkConfiguration.ipAddress,
          netmask: netConfig.localNetworkConfiguration.netmask,
          gateway: netConfig.localNetworkConfiguration.gatewayAddress,
          dns: netConfig.localNetworkConfiguration.dns,
        });
      }
    }
  }, [netConfig]);

  useEffect(() => {
    const invalidInternetFields = !!invalidFields.filter((field) => field.startsWith('internet_')).length;
    const invalidLocalFields = !!invalidFields.filter((field) => field.startsWith('local_')).length;

    if ((!isInternetAutoConfig && invalidInternetFields) || (!isLocalNetworkAutoConfig && invalidLocalFields)) {
      setFormIsValid(false);
    } else {
      setFormIsValid(true);
    }
  }, [isLocalNetworkAutoConfig, isInternetAutoConfig, invalidFields]);

  const updateInternetManualData =
    (key: string) =>
    (value, required = true) => {
      setInternetManualConfig({
        ...internetManualConfig,
        [key]: value,
      });

      if (isValidIPString(value, required)) {
        setInvalidFields([...invalidFields.filter((field) => field !== `internet_${key}`)]);
      }
    };

  const updateLocalManualData =
    (key: string) =>
    (value, required = true) => {
      setLocalManualConfig({
        ...localManualConfig,
        [key]: value,
      });
      if (isValidIPString(value, required)) {
        setInvalidFields([...invalidFields.filter((field) => field !== `local_${key}`)]);
      }
    };

  const handleInternetFieldsValidation = (key: string, required = true) => {
    if (!isInternetAutoConfig && isValidIPString(internetManualConfig?.[key], required)) {
      setInvalidFields([...invalidFields.filter((field) => field !== `internet_${key}`)]);
    } else {
      if (!invalidFields.includes(`internet_${key}`)) {
        setInvalidFields([...invalidFields, `internet_${key}`]);
      }
    }
  };

  const handleLocalFieldsValidation = (key: string, required = true) => {
    if (!isLocalNetworkAutoConfig && isValidIPString(localManualConfig?.[key], required)) {
      setInvalidFields([...invalidFields.filter((field) => field !== `local_${key}`)]);
    } else {
      if (!invalidFields.includes(`local_${key}`)) {
        setInvalidFields([...invalidFields, `local_${key}`]);
      }
    }
  };

  const handleSubmit = () => {
    const formData: TNetworkConfigurationRequest = {
      gatewayId: gateway?.id || '',
      internetConfiguration: {
        isDhcpConfiguration: isInternetAutoConfig,
        manualConfiguration: !isInternetAutoConfig
          ? {
              address: internetManualConfig.address,
              netmask: internetManualConfig.netmask,
              gateway: internetManualConfig.gateway || null,
              dns: internetManualConfig.dns || null,
            }
          : null,
      },
      localNetworkConfiguration: withLocalNetwork
        ? {
            isDhcpConfiguration: isLocalNetworkAutoConfig,
            manualConfiguration: !isLocalNetworkAutoConfig
              ? {
                  address: localManualConfig.address,
                  netmask: localManualConfig.netmask,
                  gateway: localManualConfig.gateway,
                  dns: localManualConfig.dns,
                }
              : null,
          }
        : null,
    };
    dispatch(updateNetworkConfigurationPending({ ...formData }));
    navigate(-1);
  };

  accessControlRedirect([accessKeys.CREATE_GATEWAY_ALLOWED]);

  return (
    <NetworkContainer>
      <CustomBreadcrumbs segments={[{ text: 'Gateways', href: routes.GATEWAYS }, { text: 'Network Configuration' }]} />
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          paddingTop: '48px',
        }}
      >
        <h3 style={{ fontSize: '24px', margin: 0, alignSelf: 'flex-start' }}>
          {`${gateway?.serialNumber || 'n/a'} - ${t('companies:networkConfiguration:title')}`}
        </h3>

        <span>
          <Button
            variant='outlined'
            sx={{ fontSize: '15px', height: '42px', minWidth: '163px' }}
            onClick={() => navigate(-1)}
          >
            {t('companies:networkConfiguration:cancel')}
          </Button>
          <Button
            variant='contained'
            sx={{ fontSize: '15px', height: '42px', minWidth: '192px', marginLeft: '20px' }}
            disabled={!gateway || !formIsValid}
            onClick={handleSubmit}
          >
            {t('companies:networkConfiguration:saveConfig')}
          </Button>
        </span>
      </div>
      <ConfigurationContainer>
        <Typography sx={{ fontSize: '20px', fontWeight: 700 }}>
          {t('companies:networkConfiguration:labels:setInternet')}
        </Typography>
        <RadioGroup
          value={String(isInternetAutoConfig)}
          onChange={(event) => setIsInternetAutoConfig(event.target.value === 'true')}
        >
          <FormControlLabel value={true} control={<Radio />} label={t('companies:networkConfiguration:labels:auto')} />
          <FormControlLabel
            value={false}
            control={<Radio />}
            label={t('companies:networkConfiguration:labels:manual')}
          />
        </RadioGroup>
        {!isInternetAutoConfig ? (
          <StyledFormGroup>
            <StyledTextField
              id='ipAddress'
              type='text'
              size='small'
              focused
              label={t('companies:networkConfiguration:labels:ipAddress')}
              value={internetManualConfig.address || ''}
              onChange={(nextValue) => updateInternetManualData('address')(nextValue)}
              required
              InputProps={{
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                inputComponent: IpMaskInput as any,
              }}
              onBlurCapture={() => handleInternetFieldsValidation('address')}
              error={invalidFields.includes('internet_address')}
              helperText={invalidFields.includes('internet_address') && t('companies:networkConfiguration:required')}
            />
            <StyledTextField
              id='netmask'
              type='text'
              size='small'
              focused
              label={t('companies:networkConfiguration:labels:netmask')}
              value={internetManualConfig.netmask || ''}
              onChange={(nextValue) => updateInternetManualData('netmask')(nextValue)}
              required
              InputProps={{
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                inputComponent: IpMaskInput as any,
              }}
              onBlurCapture={() => handleInternetFieldsValidation('netmask')}
              error={invalidFields.includes('internet_netmask')}
              helperText={invalidFields.includes('internet_netmask') && t('companies:networkConfiguration:required')}
            />
            <StyledTextField
              id='gateway'
              type='text'
              size='small'
              focused
              label={t('companies:networkConfiguration:labels:gateway')}
              value={internetManualConfig.gateway || ''}
              onChange={(nextValue) => updateInternetManualData('gateway')(nextValue, false)}
              InputProps={{
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                inputComponent: IpMaskInput as any,
              }}
              onBlurCapture={() => handleInternetFieldsValidation('gateway', false)}
              error={invalidFields.includes('internet_gateway')}
              helperText={invalidFields.includes('internet_gateway') && t('companies:networkConfiguration:required')}
            />
            <StyledTextField
              id='dns'
              type='text'
              size='small'
              focused
              label={t('companies:networkConfiguration:labels:dns')}
              value={internetManualConfig.dns || ''}
              onChange={(nextValue) => updateInternetManualData('dns')(nextValue, false)}
              InputProps={{
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                inputComponent: IpMaskInput as any,
              }}
              onBlurCapture={() => handleInternetFieldsValidation('dns', false)}
              error={invalidFields.includes('internet_dns')}
              helperText={invalidFields.includes('internet_dns') && t('companies:networkConfiguration:required')}
            />
          </StyledFormGroup>
        ) : null}

        <FormControlLabel
          componentsProps={{ typography: { fontSize: '20px', fontWeight: 700 } }}
          control={
            <Switch
              checked={withLocalNetwork}
              onChange={() => setWithLocalNetwork(!withLocalNetwork)}
              name='setLocal'
            />
          }
          label={t('companies:networkConfiguration:labels:setLocal')}
        />
        {withLocalNetwork ? (
          <>
            <RadioGroup
              value={String(isLocalNetworkAutoConfig)}
              onChange={(event) => setIsLocalNetworkAutoConfig(event.target.value === 'true')}
            >
              <FormControlLabel
                value={true}
                control={<Radio />}
                label={t('companies:networkConfiguration:labels:auto')}
              />
              <FormControlLabel
                value={false}
                control={<Radio />}
                label={t('companies:networkConfiguration:labels:manual')}
              />
            </RadioGroup>
            {!isLocalNetworkAutoConfig ? (
              <StyledFormGroup>
                <StyledTextField
                  id='ipAddress'
                  type='text'
                  size='small'
                  focused
                  label={t('companies:networkConfiguration:labels:ipAddress')}
                  value={localManualConfig.address || ''}
                  onChange={(nextValue) => updateLocalManualData('address')(nextValue)}
                  required
                  InputProps={{
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    inputComponent: IpMaskInput as any,
                  }}
                  onBlurCapture={() => handleLocalFieldsValidation('address')}
                  error={invalidFields.includes('local_address')}
                  helperText={invalidFields.includes('local_address') && t('companies:networkConfiguration:required')}
                />
                <StyledTextField
                  id='netmask'
                  type='text'
                  size='small'
                  focused
                  label={t('companies:networkConfiguration:labels:netmask')}
                  value={localManualConfig.netmask || ''}
                  onChange={(nextValue) => updateLocalManualData('netmask')(nextValue)}
                  required
                  InputProps={{
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    inputComponent: IpMaskInput as any,
                  }}
                  onBlurCapture={() => handleLocalFieldsValidation('netmask')}
                  error={invalidFields.includes('local_netmask')}
                  helperText={invalidFields.includes('local_netmask') && t('companies:networkConfiguration:required')}
                />
                <StyledTextField
                  id='gateway'
                  type='text'
                  size='small'
                  focused
                  label={t('companies:networkConfiguration:labels:gateway')}
                  value={localManualConfig.gateway || ''}
                  onChange={(nextValue) => updateLocalManualData('gateway')(nextValue, false)}
                  InputProps={{
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    inputComponent: IpMaskInput as any,
                  }}
                  onBlurCapture={() => handleLocalFieldsValidation('gateway', false)}
                  error={invalidFields.includes('local_gateway')}
                  helperText={invalidFields.includes('local_gateway') && t('companies:networkConfiguration:required')}
                />
                <StyledTextField
                  id='dns'
                  type='text'
                  size='small'
                  focused
                  label={t('companies:networkConfiguration:labels:dns')}
                  value={localManualConfig.dns || ''}
                  onChange={(nextValue) => updateLocalManualData('dns')(nextValue, false)}
                  InputProps={{
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    inputComponent: IpMaskInput as any,
                  }}
                  onBlurCapture={() => handleLocalFieldsValidation('dns', false)}
                  error={invalidFields.includes('local_dns')}
                  helperText={invalidFields.includes('local_dns') && t('companies:networkConfiguration:required')}
                />
              </StyledFormGroup>
            ) : null}
          </>
        ) : null}
      </ConfigurationContainer>
    </NetworkContainer>
  );
};

export default NetworkConfiguration;
