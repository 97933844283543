import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  FormGroup,
  Typography,
  Autocomplete,
  Button,
  Radio,
  RadioGroup,
  FormControlLabel,
} from '@mui/material';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { styled } from '@mui/material/styles';
import ExtraWidthButton from '../../../common/Buttons/ExtraWidthButton';
import CloseButton from '../../../common/Buttons/CloseButton';
import { GATEWAY_APPLICATION_CATEGORY, TGatewayDetails } from '../../../types';
import { useDispatch, useSelector } from '../../../hooks/redux';
import { selectAllCompaniesList, selectCompanyInstallationAreas } from '../../../store/selectors/companies';
import { selectUsers } from '../../../store/selectors/user';
import { fetchAllCompaniesPending, fetchInstallationAreasPending } from '../../../store/slices/companies';
import { removeBlankAttributes, stringArrayEquals } from '../../../helpers';
import {
  checkSerialNumberPending,
  checkSerialNumberSuccess,
  createGatewayPending,
  fetchGatewayDetailsPending,
  fetchGatewayDetailsSuccess,
  fetchPackageListPending,
  updateGatewayPending,
} from '../../../store/slices/gateways';
import {
  selectGatewayDetails,
  selectIsSerialNumberValid,
  selectSoftwarePackageList,
} from '../../../store/selectors/gateways';

/* ------- Styles ------- */
const StyledFormGroup = styled(FormGroup)({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '16px 0',
});

const StyledTextField = styled(TextField)({
  minWidth: '340px',
});

const StyledSubtitle = styled(Typography)({
  fontWeight: 500,
  fontSize: '20px',
  marginTop: '20px',
});

const CloseButtonContainer = styled('div')({
  position: 'absolute',
  right: '19px',
  top: '14px',
});

/* ------- Types ------- */
interface IGatewayDetailsProps {
  companyId?: string | undefined;
  gatewayId: string | undefined;
  open: boolean;
  onClose: () => void;
}

interface IMappedGatewayDetails {
  companyId: TGatewayDetails['companyId'];
  serviceTechnicianId: TGatewayDetails['serviceTechnicianId'];
  installationAreaId: TGatewayDetails['installationAreaId'];
  serialNumber: TGatewayDetails['serialNumber'];
  applicationCategory: TGatewayDetails['applicationCategory'];
  softwarePackageId: TGatewayDetails['softwarePackageId'] | null;
}

/* ------- Components ------- */
const GatewayDetails: React.FC<IGatewayDetailsProps> = ({ companyId, gatewayId, open, onClose }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const usersList = useSelector(selectUsers) || [];
  const companiesList = useSelector(selectAllCompaniesList);
  const installationAreasList = useSelector(selectCompanyInstallationAreas);
  const softwarePackageList = useSelector(selectSoftwarePackageList);
  const serialValidityCheck = useSelector(selectIsSerialNumberValid);
  const gatewayDetails = useSelector(selectGatewayDetails);

  const initDetails: IMappedGatewayDetails = {
    companyId: '',
    serviceTechnicianId: '',
    installationAreaId: '',
    serialNumber: '',
    applicationCategory: GATEWAY_APPLICATION_CATEGORY.ONLINE,
    softwarePackageId: null,
  };

  const [gatewayData, setGatewayData] = useState<IMappedGatewayDetails>(initDetails);

  const editMode = !!gatewayDetails && !!gatewayId;

  const updateGatewayData = (key: string) => (value: unknown) => {
    setGatewayData({
      ...gatewayData,
      [key]: value,
      ...(key === 'companyId' &&
        !value && {
          installationAreaId: '',
        }),
    });
    if (value?.length) {
      setInvalidFields([...invalidFields.filter((field) => field !== key)]);
    }
  };

  const [formIsValid, setFormIsValid] = useState<boolean>(false);
  const [formIsEdited, setFormIsEdited] = useState<boolean>(false);
  const [invalidFields, setInvalidFields] = useState<string[]>([]);
  const [detailsBeforeEdit, setDetailsBeforeEdit] = useState(initDetails);

  const cleanForm = () => {
    setGatewayData(initDetails);
    setInvalidFields([]);
    setFormIsEdited(false);
  };

  const handleSubmit = () => {
    const formData = removeBlankAttributes(gatewayData);
    editMode
      ? dispatch(updateGatewayPending({ ...(formData as TGatewayDetails), gatewayId }))
      : dispatch(createGatewayPending(formData as TGatewayDetails));

    handleClose();
  };

  const handleClose = () => {
    dispatch(fetchGatewayDetailsSuccess(null));
    dispatch(checkSerialNumberSuccess({ canBeRegistered: undefined }));
    cleanForm();
    onClose();
  };

  const handleFieldValidation = (key) => {
    if (!gatewayData[key]?.length) {
      setInvalidFields([...invalidFields, key]);
    } else {
      setInvalidFields([...invalidFields.filter((field) => field !== key)]);
    }
  };

  const handleCheckSerialNumber = (serialNo) => {
    dispatch(checkSerialNumberPending({ serialNumber: serialNo }));
  };

  const handlePackageSelection = (packageId: string) => {
    setGatewayData({
      ...gatewayData,
      softwarePackageId: packageId,
    });
  };

  useEffect(() => {
    if (gatewayData.companyId?.length) {
      dispatch(fetchInstallationAreasPending({ companyId: gatewayData.companyId }));
    }
  }, [dispatch, gatewayData.companyId]);

  useEffect(() => {
    if (installationAreasList && gatewayData.companyId?.length && !editMode) {
      updateGatewayData('installationAreaId')(
        installationAreasList?.find((area) => area.name === 'Unassigned')?.installationAreaId,
      );
    }
  }, [installationAreasList, editMode]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (open && companyId) {
      updateGatewayData('companyId')(companyId);
    }
  }, [dispatch, open, companyId]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (open && !companiesList.length) {
      dispatch(fetchAllCompaniesPending());
    }
  }, [dispatch, open, companiesList]);

  useEffect(() => {
    if (gatewayId) {
      dispatch(fetchGatewayDetailsPending({ gatewayId }));
    }
  }, [dispatch, gatewayId]);

  useEffect(() => {
    if (serialValidityCheck !== undefined) {
      dispatch(checkSerialNumberSuccess({ canBeRegistered: undefined }));
    }
  }, [dispatch, gatewayData.serialNumber]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (editMode) {
      const existingDetails = {
        companyId: companiesList.find((company) => company.name === gatewayDetails.companyName)?.id || '',
        serviceTechnicianId:
          usersList.find((user) => user.displayName === gatewayDetails.serviceTechnicianName)?.id || '',
        installationAreaId:
          installationAreasList?.find((ia) => ia.name === gatewayDetails.installationArea)?.installationAreaId || '',
        serialNumber: gatewayDetails.serialNumber || '',
        applicationCategory: gatewayDetails.applicationCategory || '',
        softwarePackageId: gatewayDetails.softwarePackageId || null,
      };
      setGatewayData(existingDetails);
      setDetailsBeforeEdit(existingDetails);
    } else {
      setDetailsBeforeEdit(initDetails);
    }
  }, [dispatch, editMode, installationAreasList, companiesList]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (
      gatewayData.companyId?.length &&
      gatewayData.serviceTechnicianId?.length &&
      gatewayData.installationAreaId?.length &&
      gatewayData.serialNumber?.length &&
      gatewayData.applicationCategory?.length
    ) {
      setFormIsValid(true);
    } else {
      setFormIsValid(false);
    }

    setFormIsEdited(!stringArrayEquals(Object.values(gatewayData), Object.values(detailsBeforeEdit)));
  }, [gatewayData, detailsBeforeEdit]);

  useEffect(() => {
    if (!editMode && open) {
      dispatch(fetchPackageListPending());
    }
  }, [dispatch, editMode, open]);

  useEffect(() => {
    if (softwarePackageList.length) {
      const defaultPackId = softwarePackageList.find((pack) => pack.isDefault)?.id || softwarePackageList?.[0].id;

      handlePackageSelection(defaultPackId);
    }
  }, [softwarePackageList]); // eslint-disable-line react-hooks/exhaustive-deps

  const getSerialNoInvalidMessage = (serialNo) => {
    return `The gateway with serial number ${serialNo} cannot be commissioned as either the serial number is invalid or the gateway is already commissioned. Please check the serial number or try a different one.`;
  };

  return (
    <Dialog open={open} onClose={handleClose} PaperProps={{ sx: { minWidth: '768px', p: '32px' } }}>
      <CloseButtonContainer>
        <CloseButton onClick={handleClose} />
      </CloseButtonContainer>
      <DialogTitle sx={{ p: 0, fontWeight: 700, fontSize: '24px' }}>
        {editMode ? t('gateways:dialog:edit:title') : t('gateways:dialog:add:title')}
      </DialogTitle>
      <DialogContent sx={{ p: 0, marginTop: '20px' }}>
        <StyledFormGroup>
          <Autocomplete
            value={gatewayData.companyId?.length ? gatewayData.companyId : null}
            onChange={(_event, value) => updateGatewayData('companyId')(value)}
            size='small'
            disabled={!!companyId || !!gatewayId || serialValidityCheck === false}
            options={companiesList.map((company) => company.id)}
            getOptionLabel={(option) => companiesList.find((company) => company.id === option)?.name || 'n/a'}
            sx={{ minWidth: '340px' }}
            renderInput={(params) => {
              return (
                <StyledTextField
                  {...params}
                  id='companyId'
                  type='text'
                  size='small'
                  label={t('gateways:dialog:labels:company')}
                  required
                  onBlur={() => handleFieldValidation('companyId')}
                  error={invalidFields.includes('companyId')}
                  helperText={invalidFields.includes('companyId') && t('gateways:dialog:required')}
                />
              );
            }}
          />
          <Autocomplete
            value={gatewayData.serviceTechnicianId?.length ? gatewayData.serviceTechnicianId : null}
            onChange={(_event, value) => updateGatewayData('serviceTechnicianId')(value)}
            size='small'
            disabled={serialValidityCheck === false}
            options={usersList.map((user) => user.id)}
            getOptionLabel={(option) => usersList.find((user) => user.id === option)?.displayName || 'n/a'}
            sx={{ minWidth: '340px' }}
            renderInput={(params) => {
              return (
                <StyledTextField
                  {...params}
                  id='serviceTechnicianId'
                  type='text'
                  size='small'
                  label={t('gateways:dialog:labels:serviceTechnician')}
                  required
                  onBlur={() => handleFieldValidation('serviceTechnicianId')}
                  error={invalidFields.includes('serviceTechnicianId')}
                  helperText={invalidFields.includes('serviceTechnicianId') && t('gateways:dialog:required')}
                />
              );
            }}
          />
        </StyledFormGroup>

        <StyledFormGroup>
          <Autocomplete
            value={gatewayData.installationAreaId?.length ? gatewayData.installationAreaId : null}
            onChange={(_event, value) => updateGatewayData('installationAreaId')(value)}
            size='small'
            options={installationAreasList?.map((area) => area.installationAreaId) || []}
            getOptionLabel={(option) => {
              return installationAreasList?.find((area) => area.installationAreaId === option)?.name || 'n/a';
            }}
            disabled={serialValidityCheck === false}
            sx={{ minWidth: '340px' }}
            renderInput={(params) => {
              return (
                <StyledTextField
                  {...params}
                  id='installationAreaId'
                  type='text'
                  size='small'
                  label={t('gateways:dialog:labels:installationArea')}
                  required
                  onBlur={() => handleFieldValidation('installationAreaId')}
                  error={invalidFields.includes('installationAreaId')}
                  helperText={invalidFields.includes('installationAreaId') && t('gateways:dialog:required')}
                />
              );
            }}
          />
          {editMode ? (
            <StyledTextField
              id='cloudId'
              type='text'
              size='small'
              sx={{ width: '340px' }}
              label={t('gateways:dialog:labels:cloudId')}
              value={gatewayDetails.cloudId || 'n/a'}
              onChange={() => {}}
              required
              disabled
            />
          ) : null}
        </StyledFormGroup>

        <StyledSubtitle variant='h6'>{t('gateways:dialog:subtitle1')}</StyledSubtitle>

        <StyledFormGroup sx={editMode ? { justifyContent: 'normal', gap: '32px' } : {}}>
          <StyledTextField
            id='serialNumber'
            type='text'
            size='small'
            sx={{ width: editMode ? '340px' : '553px' }}
            label={t('gateways:dialog:labels:serialNumber')}
            value={gatewayData.serialNumber}
            onChange={(event) => updateGatewayData('serialNumber')(event.target.value)}
            required
            disabled={editMode}
            onBlur={() => handleFieldValidation('serialNumber')}
            error={invalidFields.includes('serialNumber') || serialValidityCheck === false}
            helperText={
              (invalidFields.includes('serialNumber') && t('companies:dialog:required')) ||
              (serialValidityCheck === false && getSerialNoInvalidMessage(gatewayData.serialNumber))
            }
          />
          {!editMode ? (
            <>
              <Button
                sx={{ width: '95px' }}
                onClick={() => handleCheckSerialNumber(gatewayData.serialNumber)}
                variant='contained'
                disabled={!gatewayData.serialNumber.length}
              >
                {t('gateways:dialog:add:check')}
              </Button>
              {serialValidityCheck ? (
                <CheckCircleIcon
                  sx={{ visibility: serialValidityCheck === undefined ? 'hidden' : 'visible' }}
                  color='primary'
                />
              ) : (
                <RemoveCircleIcon
                  sx={{ visibility: serialValidityCheck === undefined ? 'hidden' : 'visible' }}
                  color='error'
                />
              )}
            </>
          ) : (
            <RadioGroup
              row
              value={gatewayData.applicationCategory}
              onChange={(event) => updateGatewayData('applicationCategory')(event.target.value)}
            >
              <FormControlLabel
                sx={{ marginRight: '32px' }}
                value={GATEWAY_APPLICATION_CATEGORY.ONLINE}
                control={<Radio />}
                label='Online'
                disabled
              />
              <FormControlLabel
                value={GATEWAY_APPLICATION_CATEGORY.ON_PREMISE}
                control={<Radio />}
                label='On Premise'
                disabled
              />
            </RadioGroup>
          )}
        </StyledFormGroup>

        {!editMode ? (
          <StyledFormGroup sx={{ padding: '0 0 16px 0', paddingTop: serialValidityCheck === false ? '50px' : '' }}>
            <RadioGroup
              row
              value={gatewayData.applicationCategory}
              onChange={(event) => updateGatewayData('applicationCategory')(event.target.value)}
            >
              <FormControlLabel
                sx={{ marginRight: '32px' }}
                value={GATEWAY_APPLICATION_CATEGORY.ONLINE}
                control={<Radio />}
                disabled={serialValidityCheck === false}
                label='Online'
              />
              <FormControlLabel
                value={GATEWAY_APPLICATION_CATEGORY.ON_PREMISE}
                control={<Radio />}
                label='On Premise'
                disabled={serialValidityCheck === false}
              />
            </RadioGroup>
          </StyledFormGroup>
        ) : null}

        <StyledSubtitle variant='h6'>{t('gateways:dialog:subtitle2')}</StyledSubtitle>

        <FormGroup sx={{ paddingLeft: '12px', paddingTop: '8px' }}>
          <RadioGroup value={gatewayData.softwarePackageId} onChange={(_e, value) => handlePackageSelection(value)}>
            {softwarePackageList.map((pack) => (
              <FormControlLabel
                key={pack.id}
                disabled={editMode}
                value={pack.id}
                control={<Radio />}
                label={pack.name}
              />
            ))}
          </RadioGroup>
        </FormGroup>
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'space-between', p: 0, pt: '32px' }}>
        <ExtraWidthButton onClick={handleClose} variant='outlined' width='48%'>
          {t(`gateways:dialog:${editMode ? 'edit' : 'add'}:cancel`)}
        </ExtraWidthButton>
        <ExtraWidthButton
          onClick={handleSubmit}
          variant='contained'
          width='48%'
          disabled={!formIsEdited || !formIsValid || (!editMode && serialValidityCheck !== true)}
        >
          {t(`gateways:dialog:${editMode ? 'edit' : 'add'}:submit`)}
        </ExtraWidthButton>
      </DialogActions>
    </Dialog>
  );
};

export default GatewayDetails;
