import React, { useEffect } from 'react';
import { Box, Button, styled } from '@mui/material';
import { useTranslation } from 'react-i18next';
import AreasList from './AreasList';
import CustomBreadcrumbs from '../../../common/CustomBreadcrumbs';
import { accessKeys, NEW_INSTALLATION_AREA_NAME, routes } from '../../../constants';
import { useDispatch, useSelector } from '../../../hooks/redux';
import { selectCompany, selectCompanyInstallationAreas } from '../../../store/selectors/companies';
import { createInstallationAreaPending, fetchInstallationAreasPending } from '../../../store/slices/companies';
import { getStringWithIndex } from '../../../helpers';
import useAccessControl from '../../../hooks/useAccessControl';

/* ------- Styles ------- */
const AreasContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  padding: '40px 48px 0 48px',
  backgroundColor: '#FBFBFB',
  width: '100%',
});

/* ------- Components ------- */
const InstallationAreas = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const company = useSelector(selectCompany);
  const areas = useSelector(selectCompanyInstallationAreas);
  const { userAccess, accessControlRedirect } = useAccessControl();

  const handleAddNew = () => {
    const newAreaName = getStringWithIndex(
      areas?.map((area) => area.name),
      NEW_INSTALLATION_AREA_NAME,
    );
    dispatch(createInstallationAreaPending({ name: newAreaName }));
  };

  useEffect(() => {
    if (company) {
      dispatch(fetchInstallationAreasPending({ companyId: company.id }));
    }
  }, [dispatch, company]);

  accessControlRedirect([accessKeys.VIEW_COMPANIES_ALLOWED]);

  return (
    <AreasContainer>
      <CustomBreadcrumbs segments={[{ text: 'Companies', href: routes.COMPANIES }, { text: 'Installation Areas' }]} />
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          paddingTop: '48px',
          height: '180px',
        }}
      >
        <h3 style={{ fontSize: '24px', margin: 0, alignSelf: 'flex-start' }}>
          {`${company?.name || 'n/a'} ${t('companies:installationArea:title')}`}
        </h3>

        {userAccess.create_installation_area_allowed ? (
          <Button
            variant='contained'
            sx={{ color: '#fff', fontSize: '15px', maxHeight: '42px', minWidth: '192px', alignSelf: 'flex-end' }}
            onClick={handleAddNew}
          >
            {t('companies:installationArea:addNew')}
          </Button>
        ) : null}
      </div>

      <AreasList list={areas} />
    </AreasContainer>
  );
};

export default InstallationAreas;
