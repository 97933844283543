import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  FormGroup,
  Typography,
  Autocomplete,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import ExtraWidthButton from '../../../common/Buttons/ExtraWidthButton';
import CloseButton from '../../../common/Buttons/CloseButton';
import { TCompanyDetails } from '../../../types';
import { useDispatch, useSelector } from '../../../hooks/redux';
import { selectCompanyDetails } from '../../../store/selectors/companies';
import { selectUsers } from '../../../store/selectors/user';
import {
  createCompanyPending,
  fetchCompanyDetailsPending,
  fetchCompanyDetailsSuccess,
  updateCompanyPending,
} from '../../../store/slices/companies';
import { removeBlankAttributes, stringArrayEquals } from '../../../helpers';
import { selectCountryList } from '../../../store/selectors/dictionaries';

/* ------- Styles ------- */
const StyledFormGroup = styled(FormGroup)({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '16px 0',
});

const StyledTextField = styled(TextField)({
  minWidth: '340px',
});

const StyledSubtitle = styled(Typography)({
  fontWeight: 500,
  fontSize: '20px',
  marginTop: '20px',
});

const CloseButtonContainer = styled('div')({
  position: 'absolute',
  right: '19px',
  top: '14px',
});

/* ------- Types ------- */
interface ICompanyDetailsProps {
  companyId: string | undefined;
  open: boolean;
  onClose: () => void;
}

interface IMappedCompanyDetails {
  name: TCompanyDetails['name'];
  city: TCompanyDetails['city'];
  country: TCompanyDetails['country'];
  email: TCompanyDetails['email'];
  address1: TCompanyDetails['address1'];
  postalCode: TCompanyDetails['postalCode'];
  companyAdminId: TCompanyDetails['companyAdminId'];
  customerContactFirstName: TCompanyDetails['customerContactFirstName'];
  customerContactLastName: TCompanyDetails['customerContactLastName'];
  customerContactEmail: TCompanyDetails['customerContactEmail'];
  customerContactPhone: TCompanyDetails['customerContactPhone'];
}

/* ------- Components ------- */
const CompanyDetails: React.FC<ICompanyDetailsProps> = ({ companyId, open, onClose }) => {
  const initDetails: IMappedCompanyDetails = {
    name: '',
    city: '',
    country: '',
    email: '',
    address1: '',
    postalCode: '',
    companyAdminId: '',
    customerContactFirstName: '',
    customerContactLastName: '',
    customerContactEmail: '',
    customerContactPhone: '',
  };

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const countryList = useSelector(selectCountryList);
  const usersList = useSelector(selectUsers) || [];
  const companyDetails = useSelector(selectCompanyDetails);
  const editMode = !!companyDetails && !!companyId;
  const [companyData, setCompanyData] = useState<IMappedCompanyDetails>(initDetails);

  const updateCompanyData = (key) => (value) => {
    setCompanyData({
      ...companyData,
      [key]: value,
    });
    if (value?.length) {
      setInvalidFields([...invalidFields.filter((field) => field !== key)]);
    }
  };

  const [formIsValid, setFormIsValid] = useState<boolean>(false);
  const [formIsEdited, setFormIsEdited] = useState<boolean>(false);
  const [invalidFields, setInvalidFields] = useState<string[]>([]);
  const [detailsBeforeEdit, setDetailsBeforeEdit] = useState(initDetails);

  const cleanForm = () => {
    setCompanyData(initDetails);
    setInvalidFields([]);
    setFormIsEdited(false);
  };

  const handleSubmit = () => {
    const formData = removeBlankAttributes(companyData);
    editMode
      ? dispatch(updateCompanyPending({ ...(formData as TCompanyDetails), companyId }))
      : dispatch(createCompanyPending(formData as TCompanyDetails));

    cleanForm();
    onClose();
  };

  const handleClose = () => {
    dispatch(fetchCompanyDetailsSuccess(null));
    cleanForm();
    onClose();
  };

  const handleFieldValidation = (key) => {
    if (!companyData[key]?.length) {
      setInvalidFields([...invalidFields, key]);
    } else {
      setInvalidFields([...invalidFields.filter((field) => field !== key)]);
    }
  };

  useEffect(() => {
    if (companyId) {
      dispatch(fetchCompanyDetailsPending({ companyId }));
    }
  }, [dispatch, companyId]);

  useEffect(() => {
    if (editMode) {
      const existingDetails = {
        name: companyDetails.name || '',
        city: companyDetails.city || '',
        country: companyDetails.country || '',
        email: companyDetails.email || '',
        address1: companyDetails.address1 || '',
        postalCode: companyDetails.postalCode || '',
        companyAdminId: companyDetails.companyAdminId || '',
        customerContactFirstName: companyDetails.customerContactFirstName || '',
        customerContactLastName: companyDetails.customerContactLastName || '',
        customerContactEmail: companyDetails.customerContactEmail || '',
        customerContactPhone: companyDetails.customerContactPhone || '',
      };
      setCompanyData(existingDetails);
      setDetailsBeforeEdit(existingDetails);
    } else {
      setDetailsBeforeEdit(initDetails);
    }
  }, [dispatch, editMode]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (
      companyData.name?.length &&
      companyData.companyAdminId?.length &&
      companyData.address1?.length &&
      companyData.country?.length &&
      companyData.city?.length &&
      companyData.postalCode?.length
    ) {
      setFormIsValid(true);
    } else {
      setFormIsValid(false);
    }

    setFormIsEdited(!stringArrayEquals(Object.values(companyData), Object.values(detailsBeforeEdit)));
  }, [companyData, detailsBeforeEdit]);

  return (
    <Dialog open={open} onClose={handleClose} PaperProps={{ sx: { minWidth: '768px', p: '32px' } }}>
      <CloseButtonContainer>
        <CloseButton onClick={handleClose} />
      </CloseButtonContainer>
      <DialogTitle sx={{ p: 0, fontWeight: 700, fontSize: '24px' }}>
        {editMode ? `${companyDetails.name} ${t('companies:dialog:edit:title')}` : t('companies:dialog:add:title')}
      </DialogTitle>
      <DialogContent sx={{ p: 0, marginTop: '20px' }}>
        <StyledFormGroup>
          <StyledTextField
            id='name'
            type='text'
            size='small'
            label={t('companies:dialog:labels:company')}
            value={companyData.name}
            onChange={(event) => updateCompanyData('name')(event.target.value)}
            required
            onBlur={() => handleFieldValidation('name')}
            error={invalidFields.includes('name')}
            helperText={invalidFields.includes('name') && t('companies:dialog:required')}
          />
          <Autocomplete
            value={companyData.companyAdminId?.length ? companyData.companyAdminId : null}
            onChange={(_event, value) => updateCompanyData('companyAdminId')(value)}
            size='small'
            options={usersList.map((user) => user.id)}
            getOptionLabel={(option) => usersList.find((user) => user.id === option)?.displayName || 'n/a'}
            sx={{ minWidth: '340px' }}
            renderInput={(params) => {
              return (
                <StyledTextField
                  {...params}
                  id='companyAdminId'
                  type='text'
                  size='small'
                  label={t('companies:dialog:labels:companyAdmin')}
                  required
                  onBlur={() => handleFieldValidation('companyAdminId')}
                  error={invalidFields.includes('companyAdminId')}
                  helperText={invalidFields.includes('companyAdminId') && t('companies:dialog:required')}
                />
              );
            }}
          />
        </StyledFormGroup>

        <StyledFormGroup>
          <Autocomplete
            value={companyData.country?.length ? companyData.country : null}
            onChange={(_event, value) => updateCompanyData('country')(value)}
            size='small'
            options={countryList.map((country) => country.name).sort()}
            sx={{ minWidth: '340px' }}
            renderInput={(params) => {
              return (
                <StyledTextField
                  {...params}
                  id='country'
                  type='text'
                  size='small'
                  label={t('companies:dialog:labels:country')}
                  required
                  onBlur={() => handleFieldValidation('country')}
                  error={invalidFields.includes('country')}
                  helperText={invalidFields.includes('country') && t('companies:dialog:required')}
                />
              );
            }}
          />
          <StyledTextField
            id='city'
            type='text'
            size='small'
            label={t('companies:dialog:labels:city')}
            value={companyData.city}
            onChange={(event) => updateCompanyData('city')(event.target.value)}
            required
            onBlur={() => handleFieldValidation('city')}
            error={invalidFields.includes('city')}
            helperText={invalidFields.includes('city') && t('companies:dialog:required')}
          />
        </StyledFormGroup>

        <StyledSubtitle variant='h6'>{t('companies:dialog:subtitle1')}</StyledSubtitle>

        <StyledFormGroup>
          <StyledTextField
            id='address1'
            type='text'
            size='small'
            fullWidth
            label={t('companies:dialog:labels:address')}
            value={companyData.address1}
            onChange={(event) => updateCompanyData('address1')(event.target.value)}
            required
            onBlur={() => handleFieldValidation('address1')}
            error={invalidFields.includes('address1')}
            helperText={invalidFields.includes('address1') && t('companies:dialog:required')}
          />
        </StyledFormGroup>

        <StyledFormGroup>
          <StyledTextField
            id='postalCode'
            type='text'
            size='small'
            label={t('companies:dialog:labels:postalCode')}
            value={companyData.postalCode}
            onChange={(event) => updateCompanyData('postalCode')(event.target.value)}
            required
            onBlur={() => handleFieldValidation('postalCode')}
            error={invalidFields.includes('postalCode')}
            helperText={invalidFields.includes('postalCode') && t('companies:dialog:required')}
          />
          <StyledTextField
            id='email'
            type='email'
            size='small'
            label={t('companies:dialog:labels:email')}
            value={companyData.email}
            onChange={(event) => updateCompanyData('email')(event.target.value)}
          />
        </StyledFormGroup>

        <StyledSubtitle variant='h6'>{t('companies:dialog:subtitle2')}</StyledSubtitle>

        <StyledFormGroup>
          <StyledTextField
            id='customerContactFirstName'
            type='text'
            size='small'
            label={t('companies:dialog:labels:firstName')}
            value={companyData.customerContactFirstName}
            onChange={(event) => updateCompanyData('customerContactFirstName')(event.target.value)}
          />
          <StyledTextField
            id='customerContactLastName'
            type='text'
            size='small'
            label={t('companies:dialog:labels:lastName')}
            value={companyData.customerContactLastName}
            onChange={(event) => updateCompanyData('customerContactLastName')(event.target.value)}
          />
        </StyledFormGroup>

        <StyledFormGroup>
          <StyledTextField
            id='customerContactEmail'
            type='email'
            size='small'
            label={t('companies:dialog:labels:email')}
            value={companyData.customerContactEmail}
            onChange={(event) => updateCompanyData('customerContactEmail')(event.target.value)}
          />
          <StyledTextField
            id='customerContactPhone'
            type='text'
            size='small'
            label={t('companies:dialog:labels:phone')}
            value={companyData.customerContactPhone}
            onChange={(event) => updateCompanyData('customerContactPhone')(event.target.value)}
          />
        </StyledFormGroup>
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'space-between', p: 0, pt: '32px' }}>
        <ExtraWidthButton onClick={handleClose} variant='outlined' width='48%'>
          {t(`companies:dialog:${editMode ? 'edit' : 'add'}:cancel`)}
        </ExtraWidthButton>
        <ExtraWidthButton
          onClick={handleSubmit}
          variant='contained'
          width='48%'
          disabled={!formIsEdited || !formIsValid}
        >
          {t(`companies:dialog:${editMode ? 'edit' : 'add'}:submit`)}
        </ExtraWidthButton>
      </DialogActions>
    </Dialog>
  );
};

export default CompanyDetails;
