import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material';
import EnhancedTable, { CELL_TYPE, IHeadCell, IRowData } from '../../../common/EnhancedTable';
import { useDispatch, useSelector } from '../../../hooks/redux';
import {
  deleteCompanyPending,
  fetchCompaniesListPending,
  setCompaniesListFilterPending,
  setCompaniesListPaginationPending,
  setCompaniesListSortingPending,
  setCompany,
} from '../../../store/slices/companies';
import {
  selectCompaniesFilter,
  selectCompaniesList,
  selectCompaniesPagination,
  selectCompaniesSorting,
} from '../../../store/selectors/companies';
import { Trans, useTranslation } from 'react-i18next';
import { COMPANIES_SORT_KEYS, FILTER_OPERATOR, TCompaniesSortKey, TCompaniesSortOrder, TCompany } from '../../../types';
import FilterAutocomplete, { IAutocompleteFilter } from '../../../common/Autocomplete';
import { useNavigate } from 'react-router-dom';
import { routes } from '../../../constants';
import { fetchCountryListPending } from '../../../store/slices/dictionaries';
import { selectCountryList } from '../../../store/selectors/dictionaries';
import DeleteDialog from '../../../common/DeleteDialog';
import useAccessControl from '../../../hooks/useAccessControl';

/* ------- Styles ------- */
const ListContainer = styled('div')({
  marginTop: '24px',
});

/* ------- Types ------- */
interface ICompaniesListProps {
  onOpenDetails: (id: string) => void;
  onAddNewGateway: (id: string) => void;
}

/* ------- Components ------- */
const CompaniesList: React.FC<ICompaniesListProps> = ({ onOpenDetails, onAddNewGateway }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { userAccess } = useAccessControl();

  const [itemToDelete, setItemToDelete] = useState<string | undefined>(undefined);

  const { pageSize, pageNumber, totalCount } = useSelector(selectCompaniesPagination);
  const companiesSorting = useSelector(selectCompaniesSorting);
  const { filter, isFiltered } = useSelector(selectCompaniesFilter);
  const companies = useSelector(selectCompaniesList);
  const countryList = useSelector(selectCountryList);
  const locationFilters = filter.filters.map((filter) => ({ title: filter.value, id: filter.value }));

  useEffect(() => {
    dispatch(fetchCompaniesListPending());
    dispatch(fetchCountryListPending());
  }, [dispatch]);

  const handleChangePage = (_event: unknown, newPage: number) => {
    dispatch(setCompaniesListPaginationPending({ pageSize, totalCount, pageNumber: newPage }));
  };

  const handleSort = (sortKey: string, order: string) => {
    dispatch(
      setCompaniesListSortingPending({
        columnName: sortKey as TCompaniesSortKey,
        direction: order as TCompaniesSortOrder,
      }),
    );
  };

  const handleFilter = (values: IAutocompleteFilter[]) => {
    const nextFilterValues = values.map((val) => ({ operator: FILTER_OPERATOR.EQUALS, value: val.id }));
    dispatch(setCompaniesListFilterPending({ filters: nextFilterValues, isFiltered: !!nextFilterValues.length }));
  };

  const onInstallationAreaOpen = (company) => {
    dispatch(setCompany({ id: company.organizationId, name: company.name }));
    navigate(routes.INSTALLATION_AREAS);
  };

  const onCompanyGatewaysOpen = (company) => {
    dispatch(setCompany({ id: company.organizationId, name: company.name }));
    navigate(routes.COMPANY_GATEWAYS);
  };

  const onCompanyDelete = (company) => {
    dispatch(setCompany({ id: company.organizationId, name: company.name }));
    setItemToDelete(company.organizationId);
  };

  const handleDeleteArea = () => {
    if (itemToDelete) {
      dispatch(deleteCompanyPending({ companyId: itemToDelete }));
      setItemToDelete(undefined);
    }
  };

  const contextActions = (company: TCompany) => [
    {
      name: t('companies:contextMenu:addNewGateway'),
      callback: () => onAddNewGateway(company.organizationId),
      permitted: userAccess.create_gateway_allowed,
    },
    {
      name: t('companies:contextMenu:gateways'),
      callback: () => onCompanyGatewaysOpen(company),
      permitted: userAccess.view_gateways_allowed,
    },
    {
      name: t('companies:contextMenu:companyDetails'),
      callback: () => onOpenDetails(company.organizationId),
      permitted: userAccess.create_company_allowed,
    },
    {
      name: t('companies:contextMenu:installationArea'),
      callback: () => onInstallationAreaOpen(company),
      permitted: userAccess.view_companies_allowed,
    },
    {
      name: t('companies:contextMenu:deleteCompany'),
      callback: () => onCompanyDelete(company),
      permitted: userAccess.create_company_allowed,
    },
  ];

  const rows: IRowData[] = companies.map((company) => {
    return {
      id: company.organizationId,
      cells: [
        {
          key: 'name',
          value: company.name,
          type: CELL_TYPE.FILED,
          width: '400px',
        },
        {
          key: 'location',
          value: `${company.country} / ${company.city}`,
          type: CELL_TYPE.FILED,
          width: '327px',
        },
        {
          key: 'companyAdmin',
          value: `${company.companyAdminIdFirstName} ${company.companyAdminIdLastName}`,
          type: CELL_TYPE.FILED,
        },
        {
          key: 'gateways',
          value: company.gateways || 'n/a',
          type: CELL_TYPE.FILED,
          width: '190px',
        },
        {
          key: 'actions',
          type: CELL_TYPE.MENU,
          actions: contextActions(company),
          width: '260px',
        },
      ],
    };
  });

  const headCells: IHeadCell[] = [
    {
      id: 'company',
      numeric: false,
      disablePadding: false,
      label: 'Company',
      sortable: true,
      sortKey: COMPANIES_SORT_KEYS.COMPANY_MANE,
    },
    {
      id: 'location',
      numeric: false,
      disablePadding: false,
      label: 'Location',
      sortable: true,
      sortKey: COMPANIES_SORT_KEYS.COUNTRY,
    },
    {
      id: 'admin',
      numeric: false,
      disablePadding: false,
      label: 'Customer Admin',
      sortable: true,
      sortKey: COMPANIES_SORT_KEYS.COMPANY_ADMIN,
    },
    {
      id: 'gateways',
      numeric: false,
      disablePadding: false,
      label: 'Gateways',
      sortable: false,
    },
    {
      id: 'actions',
      numeric: false,
      disablePadding: false,
      label: 'Actions',
      sortable: false,
    },
  ];

  return (
    <>
      <ListContainer>
        <EnhancedTable
          rows={rows}
          headRow={headCells}
          rowsPerPage={pageSize}
          total={totalCount}
          page={pageNumber}
          setPage={handleChangePage}
          sortOrder={companiesSorting.direction}
          orderBy={companiesSorting.columnName}
          onSort={handleSort}
          filter={
            <FilterAutocomplete
              label={t('companies:filter:label')}
              value={isFiltered ? locationFilters : []}
              onValueChange={handleFilter}
              options={countryList.map((country) => ({ title: country.name, id: country.name }))}
            />
          }
        />
      </ListContainer>
      <DeleteDialog
        open={!!itemToDelete}
        onConfirm={handleDeleteArea}
        onClose={() => setItemToDelete(undefined)}
        dialogTitle={t('companies:deleteCompany:title')}
        dialogText={
          <Trans
            i18nKey='companies:deleteCompany:text'
            values={{
              companyName: `<strong>${companies?.find((item) => item.organizationId === itemToDelete)?.name}</strong>`,
            }}
          />
        }
      />
    </>
  );
};

export default CompaniesList;
