import * as React from 'react';
import { NumericFormat, NumericFormatProps } from 'react-number-format';

interface CustomProps {
  onChange: (event: { target: { value: string } }) => void;
}
const CustomPortInput = React.forwardRef<NumericFormatProps, CustomProps>(function NumericFormatCustom(props, ref) {
  const { onChange, ...other } = props;
  return (
    <NumericFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            value: values.value,
          },
        });
      }}
      valueIsNumericString
      allowNegative={false}
      allowLeadingZeros
      allowedDecimalSeparators={[]}
      isAllowed={(values) => {
        const { floatValue, formattedValue } = values;

        if (formattedValue === '') return true;
        if (!floatValue) return false;

        return floatValue < 65536 && formattedValue.length < 6;
      }}
    />
  );
});

export default {
  PortInput: CustomPortInput,
};
