import { createSelector } from 'reselect';
import { RootState } from '..';

const user = (state: RootState) => state.user;
const authUser = (state: RootState) => state.user.authUser;

export const selectUser = createSelector(user, (user) => user.user);
export const selectUsers = createSelector(user, (user) => user.users);
export const selectAuthUser = createSelector(user, (user) => user.authUser);
export const selectUserId = createSelector(authUser, (authUser) => authUser?.localAccountId);
export const selectAvailableTenants = createSelector(user, (user) => user.tenants);
export const selectActiveTenantId = createSelector(user, (user) => user.activeTenantId);
