import React from 'react';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';

const CustomPagination = ({ total = 91, perPage = 10, page, onPageChange }) => {
  const pageCount = Math.ceil(total / perPage);
  return (
    <Stack sx={{ width: '100%', minHeight: '50px', alignItems: 'center' }}>
      <Pagination count={pageCount} page={page} onChange={onPageChange} color='primary' />
    </Stack>
  );
};

export default CustomPagination;
