import React from 'react';
import { Box, FormGroup, Typography, styled } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useSelector } from '../../hooks/redux';
import { selectAuthUser } from '../../store/selectors/user';

/* ------- Styles ------- */
const StyledFormLabel = styled('div')({
  display: 'flex',
  margin: '6px 0',
  flexDirection: 'row',
  alignItems: 'center',
});

const Label = styled(Typography)({
  color: 'black',
  fontWeight: '700',
  fontSize: '16px',
});

const Text = styled(Typography)({
  fontWeight: '400',
  fontSize: '14px',
});

/* ------- Components ------- */
const Profile = () => {
  const { t } = useTranslation();
  const profile = useSelector(selectAuthUser);

  return (
    <div style={{ padding: '86px 75px' }}>
      <h3 style={{ fontSize: '24px', marginTop: 0, marginBottom: '34px' }}>{t('user:profile:title')}</h3>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        {profile ? (
          <FormGroup>
            <StyledFormLabel>
              <Label>{t('user:profile:name')}:</Label>
              &nbsp;
              <Text>{profile.name}</Text>
            </StyledFormLabel>

            <StyledFormLabel>
              <Label>{t('user:profile:email')}:</Label>
              &nbsp;
              <Text>{profile.email}</Text>
            </StyledFormLabel>
          </FormGroup>
        ) : null}
        <br />
      </Box>
    </div>
  );
};

export default Profile;
