import React from 'react';
import { styled } from '@mui/material/styles';
import ReactJson from 'react-json-view';
import { Link, Typography } from '@mui/material';
import FileUpload from '../FileUpload';
import { useTranslation } from 'react-i18next';

/* ------- Styles ------- */
const JsonViewContainer = styled('div')({
  width: '100%',
  marginTop: '20px',
  display: 'flex',
  flexDirection: 'column',
  gap: '10px',
});

/* ------- Types ------- */
interface IJsonViewProps {
  label: string;
  json: string | null;
  onJsonChange: (data: object | null) => void;
}

/* ------- Components ------- */
const JsonView: React.FC<IJsonViewProps> = ({ label, json, onJsonChange }) => {
  const { t } = useTranslation();

  return (
    <JsonViewContainer>
      <div style={{ display: 'flex', alignItems: 'baseline', justifyContent: 'space-between', width: '100%' }}>
        <Typography sx={{ fontSize: '16px', fontWeight: 700 }}>
          {t('companies:machineConfiguration:jsonTitle')}
        </Typography>

        <FileUpload label={label} onUpload={onJsonChange} />
      </div>
      {json ? (
        <>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography sx={(theme) => ({ fontSize: '14px', fontWeight: 500, color: theme.palette.black[60] })}>
              {t('companies:machineConfiguration:jsonPreview')}
            </Typography>

            <Link
              component='button'
              variant='body1'
              underline='hover'
              onClick={() => onJsonChange(null)}
              sx={{ fontSize: '14px', fontWeight: 500 }}
            >
              {t('companies:machineConfiguration:jsonReset')}
            </Link>
          </div>
          <ReactJson
            style={{ maxHeight: '612px', overflow: 'auto' }}
            name={false}
            src={JSON.parse(json)}
            theme={'codeschool'}
            displayDataTypes={false}
            displayObjectSize={false}
            enableClipboard={false}
          />
        </>
      ) : null}
    </JsonViewContainer>
  );
};

export default JsonView;
