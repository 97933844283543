import React, { useRef } from 'react';
import { isValidIPItemValue } from '../../helpers';

/* ------- Types ------- */
interface IIpMaskInputProps {
  value: string;
  onChange: (ip: string) => void;
}

/* ------- Helpers ------- */
const getRange = (el) => {
  let begin, end, result;

  if (el.setSelectionRange) {
    begin = el.selectionStart;
    end = el.selectionEnd;
    result = el.value.substring(begin, end);
  }
  el.focus();

  return { begin, end, result };
};

/* ------- Components ------- */
// eslint-disable-next-line react/display-name
const IpMaskInputWrapper = React.forwardRef((props: IIpMaskInputProps, _ref) => {
  return <IpMaskInput {...props} />;
});

const IpMaskInput: React.FC<IIpMaskInputProps> = ({ onChange, value }) => {
  const emptyIpMask = '...';
  const parsedValues = value.length ? value.split('.') : emptyIpMask.split('.');
  const inputRefs = {
    'input-0': useRef<HTMLInputElement | null>(null),
    'input-1': useRef<HTMLInputElement | null>(null),
    'input-2': useRef<HTMLInputElement | null>(null),
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>, index: number) => {
    let val = parseInt(event.target.value);
    // @ts-expect-error: need to check empty string isNaN
    if (isNaN(event.target.value)) {
      return event.preventDefault();
    }

    if (event.target.value !== '' && !isValidIPItemValue(val)) {
      val = 255;
    }

    const values = parsedValues;
    values[index] = String(val);

    if (!isNaN(val) && String(val).length === 3 && index < 3) {
      inputRefs[`input-${index + 1}`].focus();
    }

    handleUpdateValues(values);
  };

  const handleUpdateValues = (values: string[]) => {
    const ip = values.map((value) => (isNaN(parseInt(value)) ? '' : value)).join('.');
    onChange(ip !== emptyIpMask ? ip : '');
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>, index: number) => {
    let domId = index;

    if ((event.key === 'ArrowLeft' || event.key === 'Backspace') && getRange(event.target).end === 0 && index > 0) {
      domId = index - 1;
    }

    if (event.key === 'ArrowRight' && getRange(event.target).end === event.currentTarget.value.length && index < 3) {
      domId = index + 1;
    }

    if (event.key === '.') {
      event.preventDefault();
      if (index < 3) {
        domId = index + 1;
      }
    }

    inputRefs[`input-${domId}`].focus();
  };

  const handlePaste = (event: React.ClipboardEvent<HTMLInputElement>, index: number) => {
    if (!event.clipboardData || !event.clipboardData.getData) {
      return;
    }
    const pasteData = event.clipboardData.getData('text/plain');
    if (!pasteData) {
      return;
    }
    const value = pasteData.split('.').map((v) => parseInt(v));
    if (value.length !== 4 - index) {
      return;
    }
    if (!value.every(isValidIPItemValue)) {
      return;
    }

    const updatedValues = ['', '', ''];
    value.forEach((val, j) => {
      updatedValues[index + j] = String(val);
    });
    handleUpdateValues(updatedValues);

    return event.preventDefault();
  };

  return (
    <div style={{ display: 'inline-block', border: 'none', padding: '8px 8px' }}>
      {parsedValues.map((value, i) => (
        <div style={{ display: 'inline-block' }} key={i}>
          <input
            style={{
              width: '30px',
              border: 'none',
              outline: 'none',
              backgroundColor: 'transparent',
              textAlign: 'center',
              fontSize: '14px',
            }}
            ref={(el) => (inputRefs[`input-${i}`] = el)}
            type='text'
            autoComplete='off'
            value={isNaN(parseInt(value)) ? '' : value}
            onChange={(e) => handleChange(e, i)}
            onKeyDown={(e) => handleKeyDown(e, i)}
            onPaste={(e) => handlePaste(e, i)}
          />
          {i !== 3 ? <i>.</i> : false}
        </div>
      ))}
    </div>
  );
};

export default IpMaskInputWrapper;
