import { fork, all } from 'redux-saga/effects';

import user from './user.saga';
import companies from './companies.saga';
import gateways from './gateways.saga';
import dictionaries from './dictionaries.saga';

export default function* rootSaga() {
  yield all([fork(user), fork(companies), fork(gateways), fork(dictionaries)]);
}
