import React, { useEffect, useState } from 'react';
import { Autocomplete, Box, Button, TextField, styled } from '@mui/material';
import { useTranslation } from 'react-i18next';
import CustomBreadcrumbs from '../../../common/CustomBreadcrumbs';
import { accessKeys, routes } from '../../../constants';
import { useDispatch, useSelector } from '../../../hooks/redux';
import { selectGateway } from '../../../store/selectors/companies';
import { useNavigate } from 'react-router-dom';
import { fetchMachineListPending } from '../../../store/slices/gateways';
import EnhancedTable, { CELL_TYPE, IHeadCell } from '../../../common/EnhancedTable';
import { selectGatewayMachineList } from '../../../store/selectors/gateways';
import useAccessControl from '../../../hooks/useAccessControl';

/* ------- Styles ------- */
const MachineListContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  padding: '48px 48px 0 48px',
  backgroundColor: '#FBFBFB',
  width: '100%',
});

const ListContainer = styled('div')({
  marginTop: '24px',
});

/* ------- Components ------- */
const MachineList = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { accessControlRedirect } = useAccessControl();

  const gateway = useSelector(selectGateway);
  const machines = useSelector(selectGatewayMachineList);

  const [machineStatusFilter, setMachineStatusFilter] = useState<string | null>(null);
  const [machineStatusInputValue, setMachineStatusInputValue] = useState('');

  useEffect(() => {
    if (gateway) {
      dispatch(fetchMachineListPending({ gatewayId: gateway.id }));
    }
  }, [dispatch, gateway]);

  const temporaryMachineStatus = Object.freeze({
    CONNECTED: 'Connected',
    NOT_CONNECTED: 'NotConnected',
  });

  const getList = (() => {
    let filteredMachines = machines;

    if (machineStatusFilter && filteredMachines) {
      filteredMachines = filteredMachines.filter((machine) => machine.status === machineStatusFilter);
    }

    return filteredMachines;
  })();

  const rows = getList?.map((machine) => {
    return {
      id: machine.id,
      cells: [
        {
          key: 'name',
          value: machine.name,
          type: CELL_TYPE.FILED,
          width: '203px',
        },
        {
          key: 'type',
          value: machine.type,
          type: CELL_TYPE.FILED,
          width: '203px',
        },
        {
          key: 'status',
          value: machine.status,
          type: CELL_TYPE.FILED,
          width: '203px',
        },
        {
          key: 'ipAddress',
          value: machine.ipAddress,
          type: CELL_TYPE.FILED,
          width: '203px',
        },
        {
          key: 'port',
          value: machine.port,
          type: CELL_TYPE.FILED,
          width: '203px',
        },
        {
          key: 'opcUaScheme',
          value: machine.opcUaSchema,
          width: '203px',
          type: CELL_TYPE.FILED,
        },
      ],
    };
  });

  const headCells: IHeadCell[] = [
    {
      id: 'name',
      numeric: false,
      disablePadding: false,
      label: 'Name',
      sortable: true,
      sortKey: 'name',
    },
    {
      id: 'type',
      numeric: false,
      disablePadding: false,
      label: 'Type',
      sortable: true,
      sortKey: 'type',
    },
    {
      id: 'status',
      numeric: false,
      disablePadding: false,
      label: 'Status',
      sortable: true,
      sortKey: 'status',
    },
    {
      id: 'ipAddress',
      numeric: false,
      disablePadding: false,
      label: 'IP Address',
      sortable: true,
      sortKey: 'ipAddress',
    },
    {
      id: 'port',
      numeric: false,
      disablePadding: false,
      label: 'Port',
      sortable: true,
      sortKey: 'port',
    },
    {
      id: 'opcUaScheme',
      numeric: false,
      disablePadding: false,
      label: 'OPC UA Scheme',
      sortable: true,
      sortKey: 'opcUaScheme',
    },
  ];

  accessControlRedirect([accessKeys.VIEW_GATEWAYS_ALLOWED]);

  return (
    <MachineListContainer>
      <CustomBreadcrumbs segments={[{ text: 'Gateways', href: routes.GATEWAYS }, { text: 'Machine List' }]} />
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          paddingTop: '48px',
        }}
      >
        <h3 style={{ fontSize: '24px', margin: 0, alignSelf: 'flex-start' }}>
          {`${gateway?.serialNumber || 'n/a'} - ${t('gateways:machineList:title')}`}
        </h3>

        <Button
          variant='contained'
          sx={{ fontSize: '15px', height: '42px', minWidth: '192px', marginLeft: '20px' }}
          disabled={!gateway}
          onClick={() => navigate(routes.MACHINE_CONFIGURATION)}
        >
          {t('gateways:machineList:goToConfig')}
        </Button>
      </div>
      <ListContainer>
        <EnhancedTable
          rows={rows || []}
          headRow={headCells}
          filter={
            <Autocomplete
              value={machineStatusFilter}
              size='small'
              onChange={(_e, newValue: string | null) => {
                setMachineStatusFilter(newValue);
              }}
              inputValue={machineStatusInputValue}
              onInputChange={(_e, newInputValue) => {
                setMachineStatusInputValue(newInputValue);
              }}
              options={Object.values(temporaryMachineStatus)}
              sx={{ width: 220 }}
              renderInput={(params) => <TextField {...params} label={t('gateways:machineList:filter')} />}
            />
          }
        />
      </ListContainer>
    </MachineListContainer>
  );
};

export default MachineList;
