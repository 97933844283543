import { takeLatest, call, put, select } from 'redux-saga/effects';
import apiClient from '../services/apiClient';
import {
  createCompanyFailed,
  createCompanyPending,
  createCompanySuccess,
  createInstallationAreaPending,
  deleteCompanyFailed,
  deleteCompanyPending,
  deleteCompanySuccess,
  deleteInstallationAreaPending,
  fetchAllCompaniesPending,
  fetchAllCompaniesSuccess,
  fetchCompaniesListPending,
  fetchCompaniesListSuccess,
  fetchCompanyDetailsPending,
  fetchCompanyDetailsSuccess,
  fetchCompanyGatewaysPending,
  fetchCompanyGatewaysSuccess,
  fetchFailed,
  fetchInstallationAreasPending,
  fetchInstallationAreasSuccess,
  setCompaniesListFilterPending,
  setCompaniesListFilterSuccess,
  setCompaniesListPaginationPending,
  setCompaniesListPaginationSuccess,
  setCompaniesListSortingPending,
  setCompaniesListSortingSuccess,
  updateCompanyFailed,
  updateCompanyPending,
  updateCompanySuccess,
  updateInstallationAreaPending,
} from '../store/slices/companies';
import {
  selectCompaniesFilter,
  selectCompaniesPagination,
  selectCompaniesSorting,
  selectCompany,
} from '../store/selectors/companies';

function* fetchCompaniesListSaga() {
  try {
    const pagination = yield select(selectCompaniesPagination);
    const sorting = yield select(selectCompaniesSorting);
    const { filter, isFiltered } = yield select(selectCompaniesFilter);
    const response = yield call(apiClient.post, `/Companies/get`, {
      page: pagination,
      orderBy: sorting,
      ...(isFiltered && { filterGroups: [filter] }),
    });

    yield put(
      fetchCompaniesListSuccess({
        list: response.data,
        pagination: {
          pageNumber: response.pageNumber,
          pageSize: response.pageSize,
          totalCount: response.totalCount,
        },
      }),
    );
  } catch (error) {
    yield put(
      fetchFailed({
        error,
      }),
    );
  }
}

function* setCompaniesListPaginationSaga({ payload }) {
  try {
    yield put(
      setCompaniesListPaginationSuccess({
        pageNumber: payload.pageNumber,
        pageSize: payload.pageSize,
        totalCount: payload.totalCount,
      }),
    );
    yield put(fetchCompaniesListPending());
  } catch (error) {
    yield put(
      fetchFailed({
        error,
      }),
    );
  }
}

function* setCompaniesListSortingSaga({ payload }) {
  try {
    yield put(setCompaniesListSortingSuccess(payload));
    yield put(fetchCompaniesListPending());
  } catch (error) {
    yield put(
      fetchFailed({
        error,
      }),
    );
  }
}

function* setCompaniesListFilterSaga({ payload }) {
  try {
    yield put(setCompaniesListFilterSuccess(payload));
    yield put(fetchCompaniesListPending());
  } catch (error) {
    yield put(
      fetchFailed({
        error,
      }),
    );
  }
}

function* fetchCompanyDetailsSaga({ payload }) {
  try {
    const response = yield call(apiClient.get, `/Companies/${payload.companyId}`);

    if (response) {
      yield put(fetchCompanyDetailsSuccess(response));
    }
  } catch (error) {
    yield put(
      fetchFailed({
        error,
      }),
    );
  }
}

function* fetchInstallationAreasSaga({ payload }) {
  try {
    const response = yield call(apiClient.get, `/company/${payload.companyId}/InstallationAreas`);

    if (response) {
      yield put(fetchInstallationAreasSuccess(response.installationAreas));
    }
  } catch (error) {
    yield put(
      fetchFailed({
        error,
      }),
    );
  }
}

function* createCompanySaga({ payload }) {
  try {
    const response = yield call(apiClient.post, '/Companies', payload);

    if (response) {
      yield put(fetchCompanyDetailsSuccess(response));
      yield put(createCompanySuccess());
      yield put(fetchCompaniesListPending());
    }
  } catch (error) {
    yield put(
      createCompanyFailed({
        error,
      }),
    );
  }
}

function* updateCompanySaga({ payload }) {
  try {
    const response = yield call(apiClient.put, '/Companies', payload);

    if (response) {
      yield put(updateCompanySuccess());
      yield put(fetchCompanyDetailsSuccess(response));
      yield put(fetchCompaniesListPending());
    }
  } catch (error) {
    yield put(
      updateCompanyFailed({
        error,
      }),
    );
  }
}

function* deleteCompanySaga({ payload }) {
  try {
    const response = yield call(apiClient.delete, '/Companies', payload.companyId);

    if (response) {
      yield put(deleteCompanySuccess());
      yield put(fetchCompaniesListPending());
    }
  } catch (error) {
    yield put(
      deleteCompanyFailed({
        error,
      }),
    );
  }
}

function* createInstallationAreaSaga({ payload }) {
  try {
    const { id } = yield select(selectCompany);
    const response = yield call(apiClient.post, `/company/${id}/InstallationAreas`, payload);

    if (response) {
      yield put(fetchInstallationAreasPending({ companyId: id }));
    }
  } catch (error) {
    yield put(
      fetchFailed({
        error,
      }),
    );
  }
}

function* updateInstallationAreaSaga({ payload }) {
  try {
    const { id } = yield select(selectCompany);
    const response = yield call(apiClient.put, `/company/${id}/InstallationAreas/${payload.installationAreaId}`, {
      name: payload.name,
    });

    if (response) {
      yield put(fetchInstallationAreasPending({ companyId: id }));
    }
  } catch (error) {
    yield put(
      fetchFailed({
        error,
      }),
    );
  }
}

function* deleteInstallationAreaSaga({ payload }) {
  try {
    const { id } = yield select(selectCompany);
    const response = yield call(apiClient.delete, `/company/${id}/InstallationAreas`, payload.installationAreaId);

    if (response) {
      yield put(fetchInstallationAreasPending({ companyId: id }));
    }
  } catch (error) {
    yield put(
      fetchFailed({
        error,
      }),
    );
  }
}

function* fetchAllCompaniesListSaga() {
  try {
    const sorting = yield select(selectCompaniesSorting);
    const response = yield call(apiClient.post, `/Companies/get`, {
      page: {
        pageNumber: 1,
        pageSize: 1000,
        totalCount: 0,
      },
      orderBy: sorting,
    });

    yield put(
      fetchAllCompaniesSuccess(
        response.data.map((company) => ({ id: company.organizationId, name: company.name, country: company.country })),
      ),
    );
  } catch (error) {
    yield put(
      fetchFailed({
        error,
      }),
    );
  }
}

function* fetchCompanyGatewaysSaga({ payload }) {
  try {
    const response = yield call(apiClient.get, `/Companies/${payload.companyId}/gateways`);

    if (response) {
      yield put(fetchCompanyGatewaysSuccess(response.gateways));
    }
  } catch (error) {
    yield put(
      fetchFailed({
        error,
      }),
    );
  }
}

export default function* watch() {
  yield takeLatest(fetchCompaniesListPending, fetchCompaniesListSaga);
  yield takeLatest(setCompaniesListPaginationPending, setCompaniesListPaginationSaga);
  yield takeLatest(setCompaniesListSortingPending, setCompaniesListSortingSaga);
  yield takeLatest(setCompaniesListFilterPending, setCompaniesListFilterSaga);
  yield takeLatest(fetchCompanyDetailsPending, fetchCompanyDetailsSaga);
  yield takeLatest(fetchInstallationAreasPending, fetchInstallationAreasSaga);
  yield takeLatest(createCompanyPending, createCompanySaga);
  yield takeLatest(updateCompanyPending, updateCompanySaga);
  yield takeLatest(deleteCompanyPending, deleteCompanySaga);
  yield takeLatest(createInstallationAreaPending, createInstallationAreaSaga);
  yield takeLatest(updateInstallationAreaPending, updateInstallationAreaSaga);
  yield takeLatest(deleteInstallationAreaPending, deleteInstallationAreaSaga);
  yield takeLatest(fetchAllCompaniesPending, fetchAllCompaniesListSaga);
  yield takeLatest(fetchCompanyGatewaysPending, fetchCompanyGatewaysSaga);
}
