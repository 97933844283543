import React, { useContext, useEffect } from 'react';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button, Card, CardContent } from '@mui/material';
import { styled } from '@mui/material/styles';
import { loginRequest } from '../../services/auth/auth.config';
import { PreselectedRouteContext } from '../../common/Layout/PreselectedRouteContext';

/* ------- Styles ------- */
const Wrapper = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100vh',
  width: '100%',
  backgroundImage: 'url("/images/login-bg.png")',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
});

/* ------- Components ------- */
const Login = () => {
  const { instance } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const preselectedRoute = useContext(PreselectedRouteContext);

  useEffect(() => {
    if (isAuthenticated) {
      navigate(preselectedRoute);
    }
  }, [isAuthenticated, preselectedRoute, navigate]);

  const handleLogin = async () => {
    await instance.loginRedirect(loginRequest);
  };

  return (
    <Wrapper>
      <Card sx={{ minWidth: '400px', padding: '40px 60px' }}>
        <CardContent sx={{ display: 'flex', flexDirection: 'column', gap: '48px' }}>
          <img
            src='/schunk-logo.svg'
            width='236'
            height='128'
            alt={t('common:logo:alt')}
            style={{ alignSelf: 'center' }}
          />
          <Button variant='contained' sx={{ color: '#fff', fontSize: '16px' }} onClick={handleLogin}>
            {t('user:login:submit')}
          </Button>
        </CardContent>
      </Card>
    </Wrapper>
  );
};

export default Login;
