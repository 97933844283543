import React, { forwardRef } from 'react';
import { styled } from '@mui/material/styles';
import { Button, Menu, MenuItem } from '@mui/material';

const TextButton = styled(Button)`
  min-width: 0;
  padding: 0;
  color: #6c6c6c;
  text-transform: none;

  &:hover {
    background-color: transparent;
  }
`;

interface IContextButton {
  menuOpen: boolean;
  handleOpenMenu: () => void;
  children: JSX.Element | string;
  testId?: string;
}

const ContextButton = forwardRef<HTMLButtonElement, IContextButton>(
  ({ menuOpen, handleOpenMenu, children, testId }, ref) => (
    <TextButton
      ref={ref}
      data-testid={testId}
      aria-haspopup='true'
      aria-expanded={menuOpen ? 'true' : undefined}
      onClick={(e) => {
        e.stopPropagation();
        handleOpenMenu();
      }}
      role='button'
    >
      {children}
    </TextButton>
  ),
);

export enum ACTION_TYPE {
  EDIT = 'edit',
  SAVE = 'save',
  CANCEL = 'cancel',
  DELETE = 'delete',
}

export interface IContextMenu {
  menuOpen: boolean;
  anchorEl: HTMLElement | HTMLButtonElement | null;
  handleCloseMenu: () => void;
  contextActions: {
    name: string;
    href?: string;
    type?: ACTION_TYPE.EDIT | ACTION_TYPE.SAVE | ACTION_TYPE.CANCEL | ACTION_TYPE.DELETE;
    callback?: (...args: unknown[]) => void;
    disabled?: boolean;
    permitted: boolean;
  }[];
}

const ContextMenu: React.FC<IContextMenu> = ({ menuOpen, anchorEl, handleCloseMenu, contextActions }) => {
  const handleItemClick = (callback?: () => void) => {
    if (callback) {
      callback();
    }
    handleCloseMenu();
  };

  return (
    <Menu
      anchorEl={anchorEl}
      open={menuOpen}
      onClose={handleCloseMenu}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      MenuListProps={{
        'aria-labelledby': 'contextMenu-button',
        sx: {
          '& .MuiMenuItem-root': {
            '& a': {
              width: '100%',
              color: '#000000',
              textDecoration: 'none',
            },
            '&:hover': { backgroundColor: (theme) => theme.palette.green[20] },
          },
        },
      }}
    >
      {contextActions
        .filter((act) => act.permitted)
        .map((action) => (
          <MenuItem key={action.name} onClick={() => handleItemClick(action.callback)} disabled={action.disabled}>
            {action.href ? <a href={action.href}>{action.name}</a> : action.name}
          </MenuItem>
        ))}
    </Menu>
  );
};

ContextButton.displayName = 'ContextButton';
export { ContextButton };
export default ContextMenu;
