import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

interface IDeleteDialogProps {
  open: boolean;
  onConfirm: () => void;
  onClose: () => void;
  dialogTitle: string | React.ReactNode;
  dialogText: string | React.ReactNode;
  confirmationText?: string | undefined;
}

const DeleteDialog: React.FC<IDeleteDialogProps> = ({
  open,
  onConfirm,
  onClose,
  dialogTitle,
  dialogText,
  confirmationText,
}) => {
  const { t } = useTranslation();

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          fontSize: '24px',
          fontWeight: '700',
          color: (theme) => theme.palette.secondary.main,
        }}
      >
        {dialogTitle}
        <IconButton
          size='small'
          aria-label='close'
          color='inherit'
          onClick={onClose}
          sx={{ '&:hover': { background: 'none' } }}
        >
          <CloseIcon fontSize='small' />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <DialogContentText sx={{ color: (theme) => theme.palette.black[100] }}>{dialogText}</DialogContentText>
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'center', gap: '24px', padding: '8px 24px 24px' }}>
        <Button variant='outlined' onClick={onClose} fullWidth>
          {t('common:cancel')}
        </Button>
        <Button variant='contained' onClick={onConfirm} fullWidth>
          {confirmationText || t('common:delete')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteDialog;
