import { createSlice } from '@reduxjs/toolkit';
import {
  COMPANIES_SORT_KEYS,
  FILTER_OPERATOR,
  SORT_ORDER,
  TCompaniesSortKey,
  TCompaniesSortOrder,
  TCompany,
  TCompanyDetails,
  TGateway,
  TInstallationArea,
} from '../../types';
import { listPageSize } from '../../constants';
import { ApiError } from '../../services/apiClient/apiError';

/*-----------------TYPES-----------------*/
type TPayloadError = {
  error: ApiError;
};

type TCompaniesPagination = {
  pageNumber: number;
  pageSize: number;
  totalCount: number;
};

type TCompaniesSorting = {
  columnName: TCompaniesSortKey;
  direction: TCompaniesSortOrder;
};

type TCompaniesFilter = {
  isFiltered: boolean;
  filters: TFilter[];
};

type TFetchCompaniesList = {
  list: TCompany[];
  pagination: TCompaniesPagination;
};

type TFilter = {
  operator: FILTER_OPERATOR;
  value: string;
};

export interface ICompanies {
  company: {
    id: string;
    name: string;
  } | null;
  gateway: {
    id: string;
    serialNumber: string;
  } | null;
  list: TCompany[];
  companyDetails: TCompanyDetails | null;
  companyInstallationAreas: TInstallationArea[] | null;
  companyGateways: TGateway[] | null;
  pagination: TCompaniesPagination;
  sorting: {
    columnName: TCompaniesSortKey;
    direction: TCompaniesSortOrder;
  };
  filter: {
    columnName: string;
    filters: TFilter[];
  };
  isFiltered: boolean;
  allCompaniesList: { id: string; name: string; country: string }[];
  flags: {
    created: boolean;
    updated: boolean;
    deleted: boolean;
    isError: boolean;
  };
  error: ApiError | null;
}

/*-----------------INITIAL STATE-----------------*/
export const initialState: ICompanies = {
  company: null,
  gateway: null,
  list: [],
  companyDetails: null,
  companyInstallationAreas: null,
  companyGateways: null,
  pagination: {
    pageNumber: 1,
    pageSize: listPageSize,
    totalCount: 0,
  },
  sorting: {
    columnName: COMPANIES_SORT_KEYS.COMPANY_MANE,
    direction: SORT_ORDER.ASCENDING,
  },
  filter: {
    columnName: 'Country',
    filters: [
      {
        operator: FILTER_OPERATOR.EQUALS,
        value: '',
      },
    ],
  },
  isFiltered: false,
  allCompaniesList: [],
  flags: {
    created: false,
    updated: false,
    deleted: false,
    isError: false,
  },
  error: null,
};

/*-----------------SLICE-----------------*/
const companiesSlice = createSlice({
  name: 'companies',
  initialState,
  reducers: {
    /*-----------------Pending Actions/Reducers-----------------*/
    fetchCompaniesListPending: (state) => state,
    setCompaniesListPaginationPending: (
      state,
      _action: {
        payload: TFetchCompaniesList['pagination'];
      },
    ) => state,
    setCompaniesListSortingPending: (
      state,
      _action: {
        payload: TCompaniesSorting;
      },
    ) => state,
    setCompaniesListFilterPending: (
      state,
      _action: {
        payload: TCompaniesFilter;
      },
    ) => state,
    createCompanyPending: (
      state,
      _action: {
        payload: TCompanyDetails;
      },
    ) => state,
    updateCompanyPending: (
      state,
      _action: {
        payload: TCompanyDetails;
      },
    ) => state,
    deleteCompanyPending: (
      state,
      _action: {
        payload: { companyId: string };
      },
    ) => state,
    fetchCompanyDetailsPending: (
      state,
      _action: {
        payload: { companyId: string };
      },
    ) => state,
    fetchInstallationAreasPending: (
      state,
      _action: {
        payload: { companyId: string };
      },
    ) => state,
    createInstallationAreaPending: (
      state,
      _action: {
        payload: { name: TInstallationArea['name'] };
      },
    ) => state,
    updateInstallationAreaPending: (
      state,
      _action: {
        payload: TInstallationArea;
      },
    ) => state,
    deleteInstallationAreaPending: (
      state,
      _action: {
        payload: { installationAreaId: TInstallationArea['installationAreaId'] };
      },
    ) => state,
    fetchAllCompaniesPending: (state) => state,
    fetchCompanyGatewaysPending: (
      state,
      _action: {
        payload: { companyId: string };
      },
    ) => state,
    /*-----------------Success Actions/Reducers-----------------*/
    fetchCompaniesListSuccess: (state, { payload }: { payload: TFetchCompaniesList }) => {
      return {
        ...state,
        list: payload.list,
        pagination: payload.pagination,
      };
    },
    setCompaniesListPaginationSuccess: (state, { payload }: { payload: TFetchCompaniesList['pagination'] }) => {
      return {
        ...state,
        pagination: payload,
      };
    },
    setCompaniesListSortingSuccess: (state, { payload }: { payload: TCompaniesSorting }) => {
      return {
        ...state,
        sorting: payload,
      };
    },
    setCompaniesListFilterSuccess: (state, { payload }: { payload: TCompaniesFilter }) => {
      return {
        ...state,
        pagination: {
          ...state.pagination,
          pageNumber: 1,
        },
        filter: {
          ...state.filter,
          filters: payload.filters,
        },
        isFiltered: payload.isFiltered,
      };
    },
    createCompanySuccess: (state) => {
      return {
        ...state,
        flags: {
          ...state.flags,
          created: true,
        },
      };
    },
    updateCompanySuccess: (state) => {
      return {
        ...state,
        flags: {
          ...state.flags,
          updated: true,
        },
      };
    },
    deleteCompanySuccess: (state) => {
      return {
        ...state,
        flags: {
          ...state.flags,
          deleted: true,
        },
      };
    },
    fetchCompanyDetailsSuccess: (state, { payload }: { payload: TCompanyDetails | null }) => {
      return {
        ...state,
        companyDetails: payload,
      };
    },
    fetchInstallationAreasSuccess: (state, { payload }: { payload: TInstallationArea[] }) => {
      return {
        ...state,
        companyInstallationAreas: payload,
      };
    },
    resetCompanyFlags: (state) => {
      return {
        ...state,
        flags: initialState.flags,
      };
    },
    resetCompanyError: (state) => {
      return {
        ...state,
        error: initialState.error,
      };
    },
    setCompany: (state, { payload }: { payload: { id: string; name: string } }) => {
      return {
        ...state,
        company: payload,
      };
    },
    setGateway: (state, { payload }: { payload: { id: string; serialNumber: string } }) => {
      return {
        ...state,
        gateway: payload,
      };
    },
    fetchAllCompaniesSuccess: (state, { payload }: { payload: { id: string; name: string; country: string }[] }) => {
      return {
        ...state,
        allCompaniesList: payload,
      };
    },
    fetchCompanyGatewaysSuccess: (state, { payload }: { payload: TGateway[] }) => {
      return {
        ...state,
        companyGateways: payload,
      };
    },

    resetCompanyReducer: (_state) => {
      return {
        ...initialState,
      };
    },

    /*-----------------Failed Actions/Reducers-----------------*/
    fetchFailed: (state, { payload }: { payload: TPayloadError }) => {
      return {
        ...state,
        error: payload.error,
      };
    },
    createCompanyFailed: (state, { payload }: { payload: TPayloadError }) => {
      return {
        ...state,
        error: payload.error,
        flags: {
          ...state.flags,
          created: false,
        },
      };
    },
    updateCompanyFailed: (state, { payload }: { payload: TPayloadError }) => {
      return {
        ...state,
        error: payload.error,
        flags: {
          ...state.flags,
          updated: false,
        },
      };
    },
    deleteCompanyFailed: (state, { payload }: { payload: TPayloadError }) => {
      return {
        ...state,
        error: payload.error,
        flags: {
          ...state.flags,
          isError: true,
          deleted: false,
        },
      };
    },
  },
});

export const {
  fetchCompaniesListPending,
  setCompaniesListPaginationPending,
  setCompaniesListSortingPending,
  setCompaniesListFilterPending,
  createCompanyPending,
  updateCompanyPending,
  deleteCompanyPending,
  fetchCompanyDetailsPending,
  fetchInstallationAreasPending,
  createInstallationAreaPending,
  updateInstallationAreaPending,
  deleteInstallationAreaPending,
  fetchAllCompaniesPending,
  fetchCompanyGatewaysPending,
  fetchCompaniesListSuccess,
  setCompaniesListPaginationSuccess,
  setCompaniesListSortingSuccess,
  setCompaniesListFilterSuccess,
  createCompanySuccess,
  updateCompanySuccess,
  deleteCompanySuccess,
  fetchCompanyDetailsSuccess,
  fetchInstallationAreasSuccess,
  fetchAllCompaniesSuccess,
  fetchCompanyGatewaysSuccess,
  resetCompanyFlags,
  resetCompanyError,
  setCompany,
  setGateway,
  resetCompanyReducer,
  fetchFailed,
  createCompanyFailed,
  updateCompanyFailed,
  deleteCompanyFailed,
} = companiesSlice.actions;

export default companiesSlice.reducer;
