import React, { useEffect, useState } from 'react';
import { AuthenticatedTemplate, UnauthenticatedTemplate, useAccount } from '@azure/msal-react';
import { useDispatch, useSelector } from '../../hooks/redux';
import { fetchAvailableTenantsPending, setAuthUser } from '../../store/slices/user';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import useAccessControl from '../../hooks/useAccessControl';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import Sidebar from '../Sidebar';
import { routes } from '../../constants';
import Login from '../../scenes/Login';
import { selectActiveTenantId } from '../../store/selectors/user';
import { PreselectedRouteContext } from './PreselectedRouteContext';

/* ------- Types ------- */
interface ILayout {
  children: React.ReactNode;
  noSidebarRoutes: string[];
}

/* ------- Components ------- */
const Layout: React.FC<ILayout> = ({ children, noSidebarRoutes }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { userAccess } = useAccessControl();
  const { pathname } = useLocation();
  const account = useAccount();
  const activeTenantId = useSelector(selectActiveTenantId);
  const [activeSidebarItem, setActiveSidebarItem] = useState<string | undefined>(
    Object.values(routes).find((route) => pathname === route),
  );

  useEffect(() => {
    if (account) {
      dispatch(
        setAuthUser({
          tenantId: account.tenantId,
          organizationId: (account?.idTokenClaims?.extension_organizationId as string) || account.tenantId,
          organizationPermissions: (account?.idTokenClaims?.extension_permissions as string)?.split(' ') || '',
          localAccountId: account.localAccountId,
          name: (account?.idTokenClaims?.name as string) || 'John Doe',
          email: (account?.idTokenClaims?.email as string) || '',
        }),
      );
    }
  }, [account, dispatch]);

  useEffect(() => {
    Object.values(routes).forEach((route) => {
      if (pathname === route) {
        setActiveSidebarItem(route);
      }
    });
  }, [pathname]);

  useEffect(() => {
    dispatch(fetchAvailableTenantsPending());
  }, [dispatch]);

  const sidebarItems = [
    [
      {
        id: routes.GATEWAYS,
        name: t('common:sidebar:views:gateways'),
        icon: <img src='/icons/gateways.svg' alt={t('common:sidebar:views:gateways')} />,
        onClick: () => navigate(routes.GATEWAYS),
        permitted: userAccess.view_gateways_allowed,
        routes: [routes.GATEWAYS, routes.MACHINE_LIST, routes.NETWORK_CONFIGURATION, routes.MACHINE_CONFIGURATION],
      },
      {
        id: routes.COMPANIES,
        name: t('common:sidebar:views:companies'),
        icon: <img src='/icons/companies.svg' alt={t('common:sidebar:views:companies')} />,
        onClick: () => navigate(routes.COMPANIES),
        permitted: userAccess.view_companies_allowed || userAccess.view_gateways_allowed,
        routes: [routes.COMPANIES, routes.COMPANY_GATEWAYS, routes.INSTALLATION_AREAS],
      },
      {
        id: routes.NOTIFICATIONS,
        name: t('common:sidebar:views:notifications'),
        icon: <img src='/icons/notifications.svg' alt={t('common:sidebar:views:notifications')} />,
        onClick: () => navigate(routes.NOTIFICATIONS),
        permitted: true,
        disabled: true,
      },
      {
        id: routes.PROFILE,
        name: t('common:sidebar:views:profile'),
        icon: <img src='/icons/profile.svg' alt={t('common:sidebar:views:profile')} />,
        onClick: () => navigate(routes.PROFILE),
        permitted: true,
      },
      {
        id: routes.SETTINGS,
        name: t('common:sidebar:views:settings'),
        icon: <img src='/icons/settings.svg' alt={t('common:sidebar:views:settings')} />,
        onClick: () => navigate(routes.SETTINGS),
        permitted: userAccess.user_view_allowed,
        disabled: true,
      },
    ],
    [],
  ];

  const preselectedRoute = sidebarItems[0].filter((item) => item.permitted && !item.disabled).map((i) => i.id)[0];

  return (
    <>
      <AuthenticatedTemplate>
        <Grid container>
          <Grid xs={12} sx={{ display: 'flex', flexWrap: 'nowrap' }}>
            {!noSidebarRoutes.includes(pathname) && (
              <Sidebar activeItemId={activeSidebarItem} groupedItems={sidebarItems} />
            )}
            <PreselectedRouteContext.Provider value={preselectedRoute}>
              {activeTenantId ? children : null}
            </PreselectedRouteContext.Provider>
          </Grid>
        </Grid>
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <Login />
      </UnauthenticatedTemplate>
    </>
  );
};

export default Layout;
