import React, { useEffect, useState } from 'react';
import { Autocomplete, Box, Button, TextField, styled } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';
import CustomBreadcrumbs from '../../../common/CustomBreadcrumbs';
import { accessKeys, internetStatus, machineStatus, routes } from '../../../constants';
import CompanyGatewaysList from './CompanyGatewaysList';
import { useDispatch, useSelector } from '../../../hooks/redux';
import { selectCompany, selectCompanyGateways } from '../../../store/selectors/companies';
import { fetchCompanyGatewaysPending, setGateway } from '../../../store/slices/companies';
import CompanyGatewayDetails from '../CompanyGatewayDetails';
import { useNavigate } from 'react-router-dom';
import { GATEWAY_APPLICATION_CATEGORY, TGateway } from '../../../types';
import DeleteDialog from '../../../common/DeleteDialog';
import { decommissionGatewayPending } from '../../../store/slices/gateways';
import useAccessControl from '../../../hooks/useAccessControl';

/* ------- Styles ------- */
const GatewaysContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  padding: '48px',
  backgroundColor: '#FBFBFB',
  width: '100%',
});

const GatewaysTitle = styled('h3')({
  fontSize: '28px',
  margin: '48px 0',
});

const StyledButton = styled(Button)({
  color: '#fff',
  fontSize: '15px',
  maxHeight: '42px',
  minWidth: '192px',
});

/* ------- Components ------- */
const CompanyGateways = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const company = useSelector(selectCompany);
  const gateways = useSelector(selectCompanyGateways);
  const { accessControlRedirect, userAccess } = useAccessControl();

  useEffect(() => {
    if (company) {
      dispatch(fetchCompanyGatewaysPending({ companyId: company.id }));
    }
  }, [dispatch, company]);

  const [gatewayId, setGatewayId] = useState<string | undefined>(undefined);
  const [showGatewayDetails, setShowGatewayDetails] = useState<boolean>(false);
  const [machineStatusFilter, setMachineStatusFilter] = useState<string | null>(null);
  const [internetStatusFilter, setInternetStatusFilter] = useState<string | null>(null);
  const [machineStatusInputValue, setMachineStatusInputValue] = useState('');
  const [internetStatusInputValue, setInternetStatusInputValue] = useState('');
  const [itemForDecommissioning, setItemForDecommissioning] = useState<string | undefined>(undefined);
  const [decommissionMessage, setDecommissionMessage] = useState<string | undefined>(undefined);

  const getList = () => {
    let filteredGateways = gateways;
    if (internetStatusFilter && filteredGateways) {
      filteredGateways = filteredGateways.filter((gateway) => gateway.internetStatus === internetStatusFilter);
    }
    if (machineStatusFilter && filteredGateways) {
      filteredGateways = filteredGateways.filter((gateway) => gateway.machineStatus === machineStatusFilter);
    }

    return filteredGateways;
  };

  const handleOpenDetails = (id: string) => {
    setGatewayId(id);
    setShowGatewayDetails(true);
  };

  const handleCloseDetails = () => {
    setGatewayId(undefined);
    setShowGatewayDetails(false);
  };

  const handleOpenAddNew = () => {
    setGatewayId(undefined);
    setShowGatewayDetails(true);
  };

  const handleNetworkConfigurationOpen = (gateway: TGateway) => {
    dispatch(setGateway({ id: gateway.id, serialNumber: gateway.serialNumber }));
    navigate(routes.NETWORK_CONFIGURATION);
  };

  const handleMachineConfigurationOpen = (gateway: TGateway) => {
    dispatch(setGateway({ id: gateway.id, serialNumber: gateway.serialNumber }));
    navigate(routes.MACHINE_CONFIGURATION);
  };

  const handleMachineListOpen = (gateway: TGateway) => {
    dispatch(setGateway({ id: gateway.id, serialNumber: gateway.serialNumber }));
    navigate(routes.MACHINE_LIST);
  };

  const handleDecommissionOpen = (gateway: TGateway) => {
    setItemForDecommissioning(gateway.id);
    if (gateway.applicationCategory === GATEWAY_APPLICATION_CATEGORY.ONLINE) setDecommissionMessage('Online');
    else setDecommissionMessage('Offline');
  };

  const handleDecommission = () => {
    setItemForDecommissioning(undefined);
    setDecommissionMessage(undefined);
    if (itemForDecommissioning) {
      dispatch(decommissionGatewayPending({ gatewayId: itemForDecommissioning }));
    }
  };

  accessControlRedirect([accessKeys.VIEW_GATEWAYS_ALLOWED]);

  return (
    <GatewaysContainer>
      <CustomBreadcrumbs segments={[{ text: 'Companies', href: routes.COMPANIES }, { text: 'Gateways' }]} />
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <GatewaysTitle>
          {company ? company.name : 'n/a'} - {t('gateways:title')}
        </GatewaysTitle>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div style={{ display: 'flex', gap: '20px' }}>
            <Autocomplete
              value={machineStatusFilter}
              size='small'
              onChange={(_e, newValue: string | null) => {
                setMachineStatusFilter(newValue);
              }}
              inputValue={machineStatusInputValue}
              onInputChange={(_e, newInputValue) => {
                setMachineStatusInputValue(newInputValue);
              }}
              options={Object.values(machineStatus)}
              sx={{ width: 220 }}
              renderInput={(params) => <TextField {...params} label={t('gateways:filter:machineStatus')} />}
            />
            <Autocomplete
              value={internetStatusFilter}
              size='small'
              onChange={(_e, newValue: string | null) => {
                setInternetStatusFilter(newValue);
              }}
              inputValue={internetStatusInputValue}
              onInputChange={(_e, newInputValue) => {
                setInternetStatusInputValue(newInputValue);
              }}
              options={Object.values(internetStatus)}
              sx={{ width: 220 }}
              renderInput={(params) => <TextField {...params} label={t('gateways:filter:internetStatus')} />}
            />
          </div>
          {userAccess.create_gateway_allowed ? (
            <StyledButton variant='contained' onClick={handleOpenAddNew}>
              {t('gateways:addNew')}
            </StyledButton>
          ) : null}
        </div>
      </div>

      <CompanyGatewaysList
        list={getList()}
        onOpenDetails={handleOpenDetails}
        onNetworkConfigurationOpen={handleNetworkConfigurationOpen}
        onMachineConfigurationOpen={handleMachineConfigurationOpen}
        onMachineListOpen={handleMachineListOpen}
        onDecommissionOpen={handleDecommissionOpen}
      />

      <CompanyGatewayDetails
        gatewayId={gatewayId}
        companyId={company?.id}
        show={showGatewayDetails}
        onClose={handleCloseDetails}
      />

      <DeleteDialog
        open={!!itemForDecommissioning}
        onConfirm={handleDecommission}
        onClose={() => setItemForDecommissioning(undefined)}
        dialogTitle={t('gateways:decommission:title')}
        confirmationText={t('gateways:decommission:confirmation')}
        dialogText={
          <Trans
            i18nKey={`gateways:decommission:text${decommissionMessage}`}
            values={{
              lineBreak: '<br />',
            }}
          />
        }
      />
    </GatewaysContainer>
  );
};

export default CompanyGateways;
