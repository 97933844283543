import React, { useEffect } from 'react';
import { Autocomplete, TextField, styled } from '@mui/material';
import EnhancedTable, { CELL_TYPE, IHeadCell, IRowData } from '../../../common/EnhancedTable';
import { useDispatch, useSelector } from '../../../hooks/redux';
import { useTranslation } from 'react-i18next';
import {
  FILTER_OPERATOR,
  GATEWAYS_FILTER_KEYS,
  GATEWAYS_SORT_KEYS,
  TGateway,
  TGatewaysSortKey,
  TGatewaysSortOrder,
} from '../../../types';
import FilterAutocomplete, { IAutocompleteFilter } from '../../../common/Autocomplete';
import {
  fetchGatewayCompaniesListPending,
  fetchGatewaysListPending,
  setGatewaysListFilterPending,
  setGatewaysListPaginationPending,
  setGatewaysListSortingPending,
} from '../../../store/slices/gateways';
import {
  selectGatewayCompaniesList,
  selectGatewaysFilter,
  selectGatewaysList,
  selectGatewaysPagination,
  selectGatewaysSorting,
} from '../../../store/selectors/gateways';
import { internetStatus } from '../../../constants';
import useAccessControl from '../../../hooks/useAccessControl';

/* ------- Styles ------- */
const ListContainer = styled('div')({
  marginTop: '24px',
});

/* ------- Types ------- */
interface IGatewaysListProps {
  onOpenDetails: (id: string) => void;
  onNetworkConfigurationOpen: (gateway: TGateway) => void;
  onMachineConfigurationOpen: (gateway: TGateway) => void;
  onMachineListOpen: (gateway: TGateway) => void;
  onDecommissionOpen: (gateway: TGateway) => void;
}

/* ------- Components ------- */
const GatewaysList: React.FC<IGatewaysListProps> = ({
  onOpenDetails,
  onNetworkConfigurationOpen,
  onMachineConfigurationOpen,
  onMachineListOpen,
  onDecommissionOpen,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { userAccess } = useAccessControl();

  const { pageSize, pageNumber, totalCount } = useSelector(selectGatewaysPagination);
  const companiesSorting = useSelector(selectGatewaysSorting);
  const { filters } = useSelector(selectGatewaysFilter);
  const gateways = useSelector(selectGatewaysList);
  const gatewayCompaniesList = useSelector(selectGatewayCompaniesList);

  const companyFilters = filters?.[GATEWAYS_FILTER_KEYS.COMPANY_MANE].filters
    .filter((f) => !!f.value)
    .map((filter) => ({
      title: filter.value,
      id: filter.value,
    }));
  const internetStatusFilter = filters?.[GATEWAYS_FILTER_KEYS.INTERNET_STATUS].filters.map((filter) => filter.value)[0];

  useEffect(() => {
    dispatch(fetchGatewaysListPending());
    dispatch(fetchGatewayCompaniesListPending());
  }, [dispatch]);

  const handleChangePage = (_event: unknown, newPage: number) => {
    dispatch(setGatewaysListPaginationPending({ pageSize, totalCount, pageNumber: newPage }));
  };

  const handleSort = (sortKey: string, order: string) => {
    dispatch(
      setGatewaysListSortingPending({
        columnName: sortKey as TGatewaysSortKey,
        direction: order as TGatewaysSortOrder,
      }),
    );
  };

  const handleFilters = (filterKey: string) => (values: IAutocompleteFilter[]) => {
    const nextFilterValues = values.map((val) => ({ operator: FILTER_OPERATOR.EQUALS, value: val.id }));
    dispatch(setGatewaysListFilterPending({ filterKey, filterValue: nextFilterValues, isFiltered: true }));
  };

  const handleSingleFilter = (filterKey: string) => (value: string | null) => {
    const nextFilterValues = value ? [{ operator: FILTER_OPERATOR.EQUALS, value }] : [];
    dispatch(setGatewaysListFilterPending({ filterKey, filterValue: nextFilterValues, isFiltered: true }));
  };

  const contextActions = (gateway: TGateway) => [
    {
      name: t('gateways:contextMenu:update'),
      callback: () => console.log('update'),
      disabled: true,
      permitted: userAccess.create_gateway_allowed,
    },
    {
      name: t('gateways:contextMenu:editGateway'),
      callback: () => onOpenDetails(gateway.id),
      permitted: userAccess.create_gateway_allowed,
    },
    {
      name: t('gateways:contextMenu:networkConf'),
      callback: () => onNetworkConfigurationOpen(gateway),
      permitted: userAccess.create_gateway_allowed,
    },
    {
      name: t('gateways:contextMenu:machineConf'),
      callback: () => onMachineConfigurationOpen(gateway),
      permitted: userAccess.create_gateway_allowed,
    },
    {
      name: t('gateways:contextMenu:machineList'),
      callback: () => onMachineListOpen(gateway),
      permitted: userAccess.view_gateways_allowed,
    },
    {
      name: t('gateways:contextMenu:decommission'),
      callback: () => onDecommissionOpen(gateway),
      permitted: userAccess.reset_gateway_allowed,
    },
    {
      name: t('gateways:contextMenu:factoryReset'),
      callback: () => console.log('factoryReset'),
      disabled: true,
      permitted: userAccess.reset_gateway_allowed,
    },
  ];

  const rows: IRowData[] = gateways.map((gateway) => {
    return {
      id: gateway.id,
      cells: [
        {
          key: 'serialNo',
          value: gateway.serialNumber,
          type: CELL_TYPE.FILED,
          width: '190px',
        },
        {
          key: 'companyName',
          value: gateway.companyName,
          type: CELL_TYPE.FILED,
          width: '260px',
        },
        {
          key: 'installationArea',
          value: gateway.installationArea,
          type: CELL_TYPE.FILED,
          width: '200px',
        },
        {
          key: 'appCategory',
          value: gateway.applicationCategory,
          type: CELL_TYPE.FILED,
          width: '200px',
        },
        {
          key: 'date',
          value: gateway.dateCreated || 'n/a',
          type: CELL_TYPE.FILED,
          width: '172px',
        },
        {
          key: 'internetStatus',
          value: gateway.internetStatus || 'n/a',
          type: CELL_TYPE.FILED,
          width: '196px',
        },
        {
          key: 'machineStatus',
          value: gateway.machineStatus || 'n/a',
          type: CELL_TYPE.FILED,
          width: '196px',
        },
        {
          key: 'actions',
          type: CELL_TYPE.MENU,
          actions: contextActions(gateway),
        },
      ],
    };
  });

  const headCells: IHeadCell[] = [
    {
      id: 'serialNo',
      numeric: false,
      disablePadding: false,
      label: 'Serial no.',
      sortable: true,
      sortKey: GATEWAYS_SORT_KEYS.SERIAL_NO,
    },
    {
      id: 'company',
      numeric: false,
      disablePadding: false,
      label: 'Company',
      sortable: true,
      sortKey: GATEWAYS_SORT_KEYS.COMPANY_MANE,
    },
    {
      id: 'installationArea',
      numeric: false,
      disablePadding: false,
      label: 'Installation Area',
      sortable: true,
      sortKey: GATEWAYS_SORT_KEYS.INSTALLATION_AREA,
    },
    {
      id: 'applicationCategory',
      numeric: false,
      disablePadding: false,
      label: 'Application Category',
      sortable: true,
      sortKey: GATEWAYS_SORT_KEYS.APPLICATION_CATEGORY,
    },
    {
      id: 'date',
      numeric: false,
      disablePadding: false,
      label: 'Date created',
      sortable: false,
    },
    {
      id: 'internetStatus',
      numeric: false,
      disablePadding: false,
      label: 'Internet Status',
      sortable: false,
    },
    {
      id: 'machineStatus',
      numeric: false,
      disablePadding: false,
      label: 'Machine Status',
      sortable: false,
    },
    {
      id: 'actions',
      numeric: false,
      disablePadding: false,
      label: 'Actions',
      sortable: false,
    },
  ];

  return (
    <ListContainer>
      <EnhancedTable
        rows={rows}
        headRow={headCells}
        rowsPerPage={pageSize}
        total={totalCount}
        page={pageNumber}
        setPage={handleChangePage}
        sortOrder={companiesSorting.direction}
        orderBy={companiesSorting.columnName}
        onSort={handleSort}
        filter={
          <div style={{ display: 'flex', gap: '16px' }}>
            <FilterAutocomplete
              label={t('gateways:filter:company')}
              value={companyFilters}
              onValueChange={handleFilters(GATEWAYS_FILTER_KEYS.COMPANY_MANE)}
              options={gatewayCompaniesList.map((company) => ({ title: company, id: company }))}
            />
            <Autocomplete
              value={internetStatusFilter?.length ? internetStatusFilter : null}
              size='small'
              onChange={(_e, newValue: string | null) => {
                handleSingleFilter(GATEWAYS_FILTER_KEYS.INTERNET_STATUS)(newValue);
              }}
              options={Object.values(internetStatus)}
              sx={{ width: 220 }}
              renderInput={(params) => <TextField {...params} label={t('gateways:filter:internetStatus')} />}
            />
            <FilterAutocomplete
              label={t('gateways:filter:machineStatus')}
              value={[]}
              onValueChange={handleFilters(GATEWAYS_FILTER_KEYS.MACHINE_STATUS)}
              options={[]}
              disabled
            />
          </div>
        }
      />
    </ListContainer>
  );
};

export default GatewaysList;
