import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import {
  Accordion as MuiAccordion,
  AccordionDetails,
  AccordionSummary as MuiAccordionSummary,
  Typography,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DeleteIcon from '@mui/icons-material/Delete';

/* ------- Styles ------- */
const Accordion = styled(MuiAccordion)(() => ({
  borderRadius: '4px',
  '&.Mui-expanded': {
    margin: 0,
  },
  '&::before': {
    display: 'none',
  },
}));

const AccordionSummary = styled(MuiAccordionSummary)(({ theme }) => ({
  height: '60px',
  borderRadius: '4px',
  flexDirection: 'row',

  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(180deg)',
  },
  '& .MuiAccordionSummary-content': {
    justifyContent: 'space-between',
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(2),
    '&.Mui-expanded': {
      margin: '12px 16px 12px 8px',
    },
  },
  '&.Mui-expanded': {
    minHeight: 'auto',
    borderBottom: `1px solid ${theme.palette.grey[20]}`,
  },
}));

/* ------- Types ------- */
interface ICustomAccordionProps {
  title: string;
  id: string | undefined;
  expanded: boolean;
  onExpand: (event: React.SyntheticEvent<Element, Event>, expanded: boolean) => void;
  onDelete?: (id: string) => void;
  children: React.ReactNode;
}

/* ------- Components ------- */
const CustomAccordion: React.FC<ICustomAccordionProps> = ({ id, title, expanded, onExpand, children, onDelete }) => {
  const [showDeleteIcon, setShowDeleteIcon] = useState<boolean>(false);

  const handleDeleteAction = (event) => {
    event.stopPropagation();
    if (onDelete && id) {
      onDelete(id);
    }
  };

  return (
    <Accordion key={id} expanded={expanded} onChange={onExpand}>
      <AccordionSummary
        onMouseEnter={() => setShowDeleteIcon(true)}
        onMouseLeave={() => setShowDeleteIcon(false)}
        expandIcon={<ExpandMoreIcon />}
        aria-controls={`${id}-content`}
        id={`${id}-header`}
      >
        <Typography sx={{ width: '33%', flexShrink: 0, fontWeight: 500 }}>{title}</Typography>
        {onDelete ? (
          <DeleteIcon
            color='primary'
            sx={{ visibility: showDeleteIcon ? 'visible' : 'hidden' }}
            onClick={handleDeleteAction}
          />
        ) : null}
      </AccordionSummary>
      <AccordionDetails sx={{ display: 'flex', gap: '32px' }}>{children}</AccordionDetails>
    </Accordion>
  );
};

export default CustomAccordion;
