import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider as ReduxProvider } from 'react-redux';
import { MsalProvider } from '@azure/msal-react';
import { msalInstance, msalInstanceInitCallback } from './services/auth';
import { ThemeProvider } from '@mui/material/styles';
import theme from './theme';
import Scenes from './scenes';
import store from './store';
import { injectStore } from './services/apiClient/apiClient.config';
import './index.css';
import './utils/i18n/config';

injectStore(store);

const render = (authInstance) => {
  const rootElement = document.getElementById('root') as HTMLElement;
  if (!rootElement) throw new Error('Failed to find the root element');
  const root = ReactDOM.createRoot(rootElement);

  root.render(
    <React.StrictMode>
      <MsalProvider instance={authInstance}>
        <ReduxProvider store={store}>
          <ThemeProvider theme={theme}>
            <Scenes />
          </ThemeProvider>
        </ReduxProvider>
      </MsalProvider>
    </React.StrictMode>,
  );
};

msalInstance.initialize().then(() => msalInstanceInitCallback(msalInstance, render));
