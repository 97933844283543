import { createSelector } from 'reselect';
import { RootState } from '..';

const companies = (state: RootState) => state.companies;

export const selectCompaniesList = createSelector(companies, (companies) => companies.list);
export const selectCompaniesPagination = createSelector(companies, (companies) => companies.pagination);
export const selectCompaniesSorting = createSelector(companies, (companies) => companies.sorting);
export const selectCompaniesFilter = createSelector(companies, (companies) => ({
  filter: companies.filter,
  isFiltered: companies.isFiltered,
}));
export const selectCompanyFlags = createSelector(companies, (companies) => companies.flags);
export const selectCompanyDetails = createSelector(companies, (companies) => companies.companyDetails);
export const selectCompanyInstallationAreas = createSelector(
  companies,
  (companies) => companies.companyInstallationAreas,
);
export const selectCompany = createSelector(companies, (companies) => companies.company);
export const selectGateway = createSelector(companies, (companies) => companies.gateway);
export const selectAllCompaniesList = createSelector(companies, (companies) => companies.allCompaniesList);
export const selectCompanyGateways = createSelector(companies, (companies) => companies.companyGateways);
export const selectCompanyError = createSelector(companies, (companies) => companies.error?.data?.errors);
