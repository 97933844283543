import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Autocomplete,
  Box,
  Button,
  FormControlLabel,
  FormGroup,
  IconButton,
  InputAdornment,
  Switch,
  TextField,
  styled,
} from '@mui/material';
import CustomAccordion from '../../../../common/CustomAccordion';
import IpMaskInput from '../../../../common/IpMaskInput';
import { MACHINE_INTERNAL_STATE, TMachineConfiguration } from '../../../../types';
import { useDispatch, useSelector } from '../../../../hooks/redux';
import { fetchDictionariesPending } from '../../../../store/slices/dictionaries';
import { selectMachineTypes } from '../../../../store/selectors/dictionaries';
import CustomNumericInputs from '../../../../common/CustomNumericInputs';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import ConnectionStatus from '../ConnectionStatus';
import { TEMP_ID } from '..';
import JsonView from '../JsonView';

/* ------- Styles ------- */
const StyledTextField = styled(TextField)({
  width: '472px',
});

const StyledFormGroup = styled(FormGroup)({
  width: '100%',
  display: 'flex',
  gap: '24px',
  padding: '16px 0',
});

/* ------- Types ------- */
interface IMachinesProps {
  machines: TMachineConfiguration | null;
  onChange: (machineId: string) => (key: string) => (value: unknown) => void;
  onFieldValidation: (machineId: string) => (key: string) => void;
  invalidFields: string[];
  expanded: string[];
  setExpanded: React.Dispatch<React.SetStateAction<string[]>>;
  onDelete?: (id: string) => void;
}

/* ------- Components ------- */
const Machines: React.FC<IMachinesProps> = ({
  machines,
  onChange,
  invalidFields,
  onFieldValidation,
  expanded,
  setExpanded,
  onDelete,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const machineTypes = useSelector(selectMachineTypes);
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const showConnectionStatus = false; // TODO: remove this flag when feature is ready

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  useEffect(() => {
    dispatch(fetchDictionariesPending());
  }, [dispatch]);

  useEffect(() => {
    if (machines?.some((machine) => machine.machineId === TEMP_ID)) {
      setExpanded((prevExpanded) => [...prevExpanded, TEMP_ID]);
    }
  }, [machines?.length]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleExpand = (panelId: string) => (_event: React.SyntheticEvent, _isExpanded: boolean) => {
    if (expanded.includes(panelId)) {
      setExpanded(expanded.filter((id) => id !== panelId));
    } else {
      setExpanded((prevExpanded) => [...prevExpanded, panelId]);
    }
  };

  const handleAuthenticationSwitch = (machine: TMachineConfiguration[0], nextValue: boolean) => {
    onChange(machine.machineId)('overrideAuthentication')(nextValue);
    switch (machine.internalState) {
      case MACHINE_INTERNAL_STATE.EXISTING_NO_AUTH:
        handleSetCredentials(machine.machineId, !machine.credentialsChanged);
        return;
      case MACHINE_INTERNAL_STATE.EXISTING_AUTH:
        return;
      default:
        if (nextValue) revalidateCreds(machine.machineId);
        return;
    }
  };

  const handleSetCredentials = (machineId: string, nextValue: boolean, shouldRevalidate = true) => {
    onChange(machineId)('credentialsChanged')(nextValue);
    if (nextValue && shouldRevalidate) {
      revalidateCreds(machineId);
    }
  };

  const revalidateCreds = (machineId: string) => {
    onFieldValidation(machineId)('userName');
    onFieldValidation(machineId)('password');
  };

  const getVersions = (typeId: string) => {
    const selectedType = machineTypes.find((type) => type.id === typeId);
    return machineTypes.filter((type) => type.displayName === selectedType?.name);
  };

  const getTypeVersion = (typeId: string) =>
    (typeId && machineTypes.find((type) => type.id === typeId)?.version) || null;

  return (
    <Box
      sx={{ overflow: 'auto', padding: '2px', display: 'flex', flexDirection: 'column', gap: '20px', margin: '26px 0' }}
    >
      {machines?.map((machine) => (
        <CustomAccordion
          key={machine.machineId}
          id={machine.machineId}
          title={machine.name}
          expanded={expanded.includes(machine.machineId) || !machine.machineId}
          onExpand={handleExpand(machine.machineId)}
          onDelete={onDelete}
        >
          <Box>
            <StyledFormGroup row>
              <Autocomplete
                value={machine.machineTypeId}
                onChange={(_event, value) => {
                  onChange(machine.machineId)('machineTypeId')(value);
                }}
                size='small'
                options={machineTypes.map((type) => type.id)}
                getOptionLabel={(option) => machineTypes.find((type) => type.id === option)?.displayName || 'n/a'}
                sx={{ minWidth: '472px' }}
                renderInput={(params) => {
                  return (
                    <StyledTextField
                      {...params}
                      type='text'
                      size='small'
                      label={t('companies:machineConfiguration:labels:type')}
                      required
                      focused
                      onBlur={() => onFieldValidation(machine.machineId)('machineTypeId')}
                      error={invalidFields.includes(`${machine.machineId}-machineTypeId`)}
                      helperText={
                        invalidFields.includes(`${machine.machineId}-machineTypeId`) && t('gateways:dialog:required')
                      }
                    />
                  );
                }}
              />
              <Autocomplete
                value={getTypeVersion(machine.machineTypeId)}
                onChange={(_event, value) => {
                  const nextVersionId = getVersions(machine.machineTypeId).find((v) => v.version === value)?.id;
                  onChange(machine.machineId)('machineTypeId')(nextVersionId);
                }}
                size='small'
                options={getVersions(machine.machineTypeId)
                  .map((v) => v.version)
                  .sort()}
                getOptionLabel={(option) => machineTypes.find((type) => type.version === option)?.version || 'n/a'}
                sx={{ minWidth: '472px' }}
                renderInput={(params) => {
                  return (
                    <StyledTextField
                      {...params}
                      type='text'
                      size='small'
                      label={t('companies:machineConfiguration:labels:version')}
                      required
                      focused
                      onBlur={() => onFieldValidation(machine.machineId)('machineTypeId')}
                      error={invalidFields.includes(`${machine.machineId}-machineTypeId`)}
                      helperText={
                        invalidFields.includes(`${machine.machineId}-machineTypeId`) &&
                        t('companies:machineConfiguration:required')
                      }
                    />
                  );
                }}
              />
              <StyledTextField
                type='text'
                size='small'
                label={t('companies:machineConfiguration:labels:name')}
                value={machine.name}
                required
                focused
                onChange={(event) => onChange(machine.machineId)('name')(event.target.value || '')}
                onBlur={() => onFieldValidation(machine.machineId)('name')}
                error={invalidFields.includes(`${machine.machineId}-name`)}
                helperText={
                  invalidFields.includes(`${machine.machineId}-name`) && t('companies:machineConfiguration:required')
                }
              />
            </StyledFormGroup>

            <StyledFormGroup row>
              <StyledTextField
                type='text'
                size='small'
                label={t('companies:machineConfiguration:labels:ipAddress')}
                value={machine.ipAddress}
                onChange={(nextValue) => onChange(machine.machineId)('ipAddress')(nextValue)}
                required
                focused
                InputProps={{
                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                  inputComponent: IpMaskInput as any,
                }}
                onBlur={() => onFieldValidation(machine.machineId)('ipAddress')}
                error={invalidFields.includes(`${machine.machineId}-ipAddress`)}
                helperText={
                  invalidFields.includes(`${machine.machineId}-ipAddress`) &&
                  `${t('companies:machineConfiguration:required')} to be a valid IP Address`
                }
              />
              <StyledTextField
                type='text'
                size='small'
                label={t('companies:machineConfiguration:labels:port')}
                value={machine.port}
                onChange={(event) => onChange(machine.machineId)('port')(event.target.value)}
                required
                focused
                InputProps={{
                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                  inputComponent: CustomNumericInputs.PortInput as any,
                }}
                onBlur={() => onFieldValidation(machine.machineId)('port')}
                error={invalidFields.includes(`${machine.machineId}-port`)}
                helperText={
                  invalidFields.includes(`${machine.machineId}-port`) && t('companies:machineConfiguration:required')
                }
              />
              <StyledTextField
                type='text'
                size='small'
                label={t('companies:machineConfiguration:labels:serialNo')}
                value={machine.serialNumber}
                required
                focused
                disabled={machine.internalState !== MACHINE_INTERNAL_STATE.NEW}
                onChange={(event) => onChange(machine.machineId)('serialNumber')(event.target.value || '')}
                onBlur={() => onFieldValidation(machine.machineId)('serialNumber')}
                error={invalidFields.includes(`${machine.machineId}-serialNumber`)}
                helperText={
                  invalidFields.includes(`${machine.machineId}-serialNumber`) &&
                  t('companies:machineConfiguration:required')
                }
              />
            </StyledFormGroup>

            <FormControlLabel
              sx={{ margin: '10px 0 0 -2px' }}
              componentsProps={{ typography: { fontSize: '16px', fontWeight: 700 } }}
              control={
                <Switch
                  checked={machine.overrideAuthentication}
                  onChange={(_e, nextValue) => handleAuthenticationSwitch(machine, nextValue)}
                  name='setLocal'
                  disabled={
                    machine.credentialsChanged && machine.internalState === MACHINE_INTERNAL_STATE.EXISTING_AUTH
                  }
                />
              }
              label={t('companies:machineConfiguration:labels:authentication')}
            />
            {machine.overrideAuthentication ? (
              <div style={{ display: 'flex' }}>
                <div>
                  <StyledFormGroup>
                    <TextField
                      sx={{ width: '410px', marginBottom: '8px' }}
                      type='text'
                      size='small'
                      focused
                      disabled={
                        !machine.credentialsChanged && machine.internalState === MACHINE_INTERNAL_STATE.EXISTING_AUTH
                      }
                      label={t('companies:machineConfiguration:labels:userName')}
                      value={machine.userName || ''}
                      onChange={(event) => onChange(machine.machineId)('userName')(event.target.value || '')}
                      required={machine.overrideAuthentication}
                      onBlur={() => machine.overrideAuthentication && onFieldValidation(machine.machineId)('userName')}
                      error={machine.overrideAuthentication && invalidFields.includes(`${machine.machineId}-userName`)}
                      helperText={
                        machine.overrideAuthentication &&
                        invalidFields.includes(`${machine.machineId}-userName`) &&
                        t('companies:machineConfiguration:required')
                      }
                    />
                    <TextField
                      sx={{ width: '410px' }}
                      type={showPassword ? 'text' : 'password'}
                      size='small'
                      focused
                      disabled={
                        !machine.credentialsChanged && machine.internalState === MACHINE_INTERNAL_STATE.EXISTING_AUTH
                      }
                      label={t('companies:machineConfiguration:labels:password')}
                      value={
                        machine.internalState === MACHINE_INTERNAL_STATE.EXISTING_AUTH && !machine.credentialsChanged
                          ? '************'
                          : machine.password || ''
                      }
                      onChange={(event) => onChange(machine.machineId)('password')(event.target.value || '')}
                      required={machine.overrideAuthentication}
                      onBlur={() => machine.overrideAuthentication && onFieldValidation(machine.machineId)('password')}
                      error={machine.overrideAuthentication && invalidFields.includes(`${machine.machineId}-password`)}
                      helperText={
                        machine.overrideAuthentication &&
                        invalidFields.includes(`${machine.machineId}-password`) &&
                        t('companies:machineConfiguration:required')
                      }
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position='end'>
                            <IconButton
                              aria-label='toggle password visibility'
                              onClick={handleClickShowPassword}
                              onMouseDown={handleMouseDownPassword}
                              disabled={
                                !machine.credentialsChanged &&
                                machine.internalState === MACHINE_INTERNAL_STATE.EXISTING_AUTH
                              }
                            >
                              {showPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </StyledFormGroup>
                </div>
                {machine.internalState === MACHINE_INTERNAL_STATE.EXISTING_AUTH ? (
                  <div style={{ paddingLeft: '40px', paddingTop: '45px' }}>
                    {machine.credentialsChanged ? (
                      <Button
                        variant='outlined'
                        sx={{
                          fontSize: '15px',
                          height: '42px',
                          minWidth: '192px',
                        }}
                        onClick={() => handleSetCredentials(machine.machineId, !machine.credentialsChanged)}
                      >
                        {t('companies:machineConfiguration:cancel')}
                      </Button>
                    ) : (
                      <Button
                        variant='contained'
                        sx={{
                          fontSize: '15px',
                          height: '42px',
                          minWidth: '192px',
                        }}
                        onClick={() => handleSetCredentials(machine.machineId, !machine.credentialsChanged)}
                      >
                        {t('companies:machineConfiguration:setCreds')}
                      </Button>
                    )}
                  </div>
                ) : null}
              </div>
            ) : null}

            {showConnectionStatus ? <ConnectionStatus /> : null}

            <JsonView
              label={t('companies:machineConfiguration:addJson')}
              json={machine.schema}
              onJsonChange={(data) => onChange(machine.machineId)('schema')(data ? JSON.stringify(data) : data)}
            />
          </Box>
        </CustomAccordion>
      ))}
    </Box>
  );
};

export default Machines;
