import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { routes } from '../constants';
import Profile from './Profile';
import Login from './Login';
import Layout from '../common/Layout';
import Companies from './Companies';
import InstallationAreas from './Companies/InstallationAreas';
import Gateways from './Gateways';
import CompanyGateways from './Companies/CompanyGateways';
import NetworkConfiguration from './Gateways/NetworkConfiguration';
import MachineList from './Gateways/MachineList';
import MachineConfiguration from './Gateways/MachineConfiguration';
import UnauthorizedAccess from '../common/UnauthorizedAccess';

const Scenes = () => {
  return (
    <BrowserRouter>
      <Layout noSidebarRoutes={[routes.LANDING, routes.UNAUTHORIZED]}>
        <Routes>
          <Route path={routes.LANDING} element={<Login />} />

          <Route path={routes.GATEWAYS} element={<Gateways />} />
          <Route path={routes.COMPANIES} element={<Companies />} />
          <Route path={routes.INSTALLATION_AREAS} element={<InstallationAreas />} />
          <Route path={routes.COMPANY_GATEWAYS} element={<CompanyGateways />} />
          <Route path={routes.NETWORK_CONFIGURATION} element={<NetworkConfiguration />} />
          <Route path={routes.MACHINE_CONFIGURATION} element={<MachineConfiguration />} />
          <Route path={routes.MACHINE_LIST} element={<MachineList />} />
          <Route path={routes.SETTINGS} element={<div>Settings</div>} />
          <Route path={routes.PROFILE} element={<Profile />} />

          <Route path={routes.UNAUTHORIZED} element={<UnauthorizedAccess />} />
        </Routes>
      </Layout>
    </BrowserRouter>
  );
};

export default Scenes;
